import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import { PhyhubDevice } from '../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDevicesTableMassActions, {
  PhyhubDevicesMassAction,
} from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-mass-actions/phyhub-devices-table-mass-actions.component';
import {
  ClickableDeviceNameButton,
  MassActionsContainer,
  MassActionsWrapper,
} from '../phyhub-devices-common.components';
import PhyhubDeviceStatus from '../phyhub-device-status/phyhub-device-status';
import PhyhubDevicesTableTypeCell from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-type-cell/phyhub-devices-table-type-cell.component';
import { Icon } from '../../schema-form/common';
import PhyhubDevicesSettingsCell from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-settings-cell/phyhub-devices-table-settings-cell.component';
import { PhyhubDevicesSettingsAction } from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-settings-cell/phyhub-devices-table-settings-actions/phyhub-devices-table-settings-actions.component';
import PhyhubDevicesTableInstallationCellContainer from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-installation-cell/phyhub-devices-table-installation-cell.container';
import PhyhubDevicesTableEnvironmentCellContainer from '../phyhub-devices-list/phyhub-devices-table/phyhub-devices-table-environment-cell/phyhub-devices-table-environment-cell.container';

interface MassActionsParams {
  deviceMassActionItems: PhyhubDevicesMassAction[];
  selectedDevices: PhyhubDevice[];
  onSetSelectedDevices: (devices: PhyhubDevice[]) => void;
}

interface UsePhyhubDevicesDefaultTableColumnsParams {
  deviceActionItems: PhyhubDevicesSettingsAction[];
  massActions?: MassActionsParams;
  onDeviceClick?: (deviceItem: PhyhubDevice) => void;
}

interface UsePhyhubDevicesDefaultTableColumnsReturn {
  defaultTableColumns: ColumnProps<PhyhubDevice>[];
  getTableRowSelection: (
    deviceItems: PhyhubDevice[],
  ) => TableRowSelection<PhyhubDevice> | undefined;
}

const usePhyhubDevicesDefaultTableColumns = (
  params: UsePhyhubDevicesDefaultTableColumnsParams,
): UsePhyhubDevicesDefaultTableColumnsReturn => {
  const { deviceActionItems, massActions, onDeviceClick } = params;

  const { t } = useTranslation();

  const tableColumns = useMemo<ColumnProps<PhyhubDevice>[]>(
    (): ColumnProps<PhyhubDevice>[] => [
      {
        key: 'displayName',
        title:
          massActions && massActions.selectedDevices.length ? (
            <MassActionsContainer>
              <MassActionsWrapper>
                <PhyhubDevicesTableMassActions
                  selectedDevices={massActions.selectedDevices}
                  devicesMassActionItems={massActions.deviceMassActionItems}
                />
              </MassActionsWrapper>
            </MassActionsContainer>
          ) : (
            t('deviceName')
          ),
        render: (_, deviceItem) =>
          onDeviceClick ? (
            <ClickableDeviceNameButton
              type="link"
              onClick={() => onDeviceClick(deviceItem)}
            >
              {deviceItem.displayName || t('phyhubDevices.label.unnamedDevice')}
            </ClickableDeviceNameButton>
          ) : (
            <Link
              to={`/organisations/${deviceItem.tenantId}/operations/devices/phyhub-devices/${deviceItem.id}/screen`}
            >
              {deviceItem.displayName || t('phyhubDevices.label.unnamedDevice')}
            </Link>
          ),
      },
      {
        key: 'status',
        title: massActions && massActions.selectedDevices.length ? '' : t('status'),
        render: (_, deviceItem) => <PhyhubDeviceStatus status={deviceItem.status} />,
      },
      {
        key: 'type',
        title: massActions && massActions.selectedDevices.length ? '' : t('type'),
        render: (_, deviceItem) => (
          <PhyhubDevicesTableTypeCell status={deviceItem.status} os={deviceItem.os} />
        ),
      },
      {
        key: 'serialNumber',
        title: massActions && massActions.selectedDevices.length ? '' : t('serialNumber'),
        dataIndex: 'deviceSerial',
      },
      {
        key: 'env',
        title: massActions && massActions.selectedDevices.length ? '' : t('environment'),
        render: (_, deviceItem) => (
          <PhyhubDevicesTableEnvironmentCellContainer
            environmentName={deviceItem.env}
            tenantId={deviceItem.tenantId}
          />
        ),
      },
      {
        title: massActions && massActions.selectedDevices.length ? '' : t('installation'),
        key: 'installation',
        render: (_, deviceItem) =>
          deviceItem.installationIds.length ? (
            <PhyhubDevicesTableInstallationCellContainer
              tenantId={deviceItem.tenantId}
              deviceInstallationIds={deviceItem.installationIds}
            />
          ) : (
            t('notAvailable')
          ),
      },
      {
        title:
          massActions && massActions.selectedDevices.length ? (
            ''
          ) : (
            <Icon type="setting" />
          ),
        key: 'settings',
        fixed: 'right',
        align: 'center',
        width: 100,
        render: (_, deviceItem) => (
          <PhyhubDevicesSettingsCell
            deviceScreenshotUrl={deviceItem.screenshotUrl}
            actionItems={deviceActionItems}
            deviceItem={deviceItem}
          />
        ),
      },
    ],
    [deviceActionItems, massActions, t, onDeviceClick],
  );

  const getSetSelectedDeviceIdsHandler = useCallback(
    (massActionsParams: MassActionsParams, deviceItems: PhyhubDevice[]) => (
      deviceIds: string[],
    ) => {
      // We preserve already selected devices to cover edge cases related to pagination
      massActionsParams.onSetSelectedDevices(
        deviceIds.map((deviceId) => {
          const selectedDevice = massActionsParams.selectedDevices
            ? massActionsParams.selectedDevices.find((device) => device.id === deviceId)
            : null;

          if (selectedDevice) {
            return selectedDevice;
          }

          const newDevice = deviceItems
            ? deviceItems.find((device) => device.id === deviceId)
            : null;

          if (newDevice) {
            return newDevice;
          }

          throw new Error(`Device with id ${deviceId} cannot be selected.`);
        }),
      );
    },
    [],
  );

  const getRowSelectionChangeHandler = useCallback(
    (onSetDeviceIds: (deviceIds: string[]) => void) => (
      selectedRowKeys: string[] | number[],
    ) => {
      const mappedRowKeys: string[] = [];

      selectedRowKeys.forEach((key: string | number) => {
        mappedRowKeys.push(key.toString());
      });

      onSetDeviceIds(mappedRowKeys);
    },
    [],
  );

  const getRowSelection = useCallback(
    (deviceItems: PhyhubDevice[]): TableRowSelection<PhyhubDevice> | undefined => {
      if (massActions) {
        const selectedDeviceIds = massActions.selectedDevices
          ? massActions.selectedDevices.map((device) => device.id)
          : [];

        return {
          type: 'checkbox',
          selectedRowKeys: selectedDeviceIds,
          onChange: getRowSelectionChangeHandler(
            getSetSelectedDeviceIdsHandler(massActions, deviceItems),
          ),
        };
      }
    },
    [getRowSelectionChangeHandler, getSetSelectedDeviceIdsHandler, massActions],
  );

  return {
    defaultTableColumns: tableColumns,
    getTableRowSelection: getRowSelection,
  };
};

export default usePhyhubDevicesDefaultTableColumns;

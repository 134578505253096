import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from '@emotion/styled';
import { Button, Icon, message, Popconfirm, Popover, Tabs, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../../common/app-layout/header/header.component';
import Spinner from '../../../../common/spinner/spinner.component';
import Overlay from '../../../../common/overlay/overlay.component';
import DeviceStatus from '../../../../common/device-status/device-status.component';
import TabBar from '../../../../common/tab-bar/tab-bar.component';
import ProtectedRouteContainer from '../../../../common/protected-route/protected-route.container';
import { getPermissionPath, permissionKeys } from '../../../../../utils/auth/permissions';
import usePermissions from '../../../../../utils/auth/use-permissions';
import UniversalDevice from '../../../../../store/types/universal-device';
import UniversalDeviceType from '../../../../../store/types/universal-device-type.enum';
import UniversalDeviceDetailsSettingsContainer from './settings/universal-device-details-settings.container';
import UniversalDeviceDetailsScreenContainer from './screen/universal-device-details-screen.container';
import UniversalDeviceDetailsModulesContainer from './modules/universal-device-details-modules.container';
import useDeleteModal from '../../../../common/use-delete-modal';
import useLeaseModal from '../../../../common/use-lease-modal';
import DeviceReport from './overview/device-report';
import moment from 'moment';
import { DeviceStatusHistory, Status } from '../../../../grid/device-monitoring/history';
import { deleteLease } from '../../../../../utils/api';
import { useAnalyticsParams } from '../../../../common/use-analytics-params';

const DeviceDetailsHeader = styled(Header)`
  padding-bottom: 0;
`;

const DeviceDetailsHeaderContent = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;

const DeviceDetailsHeaderNavigation = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const DeviceDetailsHeaderNavigationTabs = styled.div`
  flex: 1 0 auto;
`;

const ActionButtonsContainer = styled.div`
  margin-bottom: 6px;
  margin-top: 6px;
` as any;

const ActionButton = styled(Button)`
  margin-left: 12px;
` as any;

const StatusUpdateTime = styled.span`
  margin-left: 0.5em;
  color: #ccc;
`;

interface DeviceDetailsProps
  extends RouteComponentProps<{ deviceUuid: string; organisationId: string }> {
  device: UniversalDevice | null;
  lastUpdated: Date;
  fetchDevice: (params: { deviceUuid: string; silent?: boolean }) => void;
  restartApp: (params: { deviceUuid: string }) => Promise<void>;
  rebootDevice: (params: { deviceUuid: string }) => Promise<void>;
  deleteDevice: (params: { deviceUuid: string }) => Promise<void>;
  loaded: boolean;
  canManageDeviceSettings: boolean;
  isSysAdmin: boolean;
  disabled: boolean;
}

const getHasParentIothubDevice = (device: UniversalDevice) =>
  device.parentDevice &&
  device.parentDevice.type &&
  device.parentDevice.type === UniversalDeviceType.IOTHUB;

const getHasParentAndroidDevice = (device: UniversalDevice) =>
  device.parentDevice != null && device.parentDevice.type === UniversalDeviceType.ANDROID;

const DeviceDetails = (props: DeviceDetailsProps) => {
  const {
    device,
    fetchDevice,
    deleteDevice,
    restartApp,
    rebootDevice,
    loaded,
    lastUpdated,
    match: {
      params: { deviceUuid, organisationId },
    },
    match,
    location,
    history,
  } = props;
  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(organisationId);
  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  const { analyticsQueryParams } = useAnalyticsParams();

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDevice({ deviceUuid, silent: true });
    }, 20 * 1000);

    fetchDevice({ deviceUuid });

    return () => {
      clearInterval(interval);
    };
  }, [deviceUuid, fetchDevice]);

  const handleRestart = useCallback(async () => {
    try {
      await restartApp({ deviceUuid });
      message.success(t('appRestartRequested'));
    } catch {
      message.error(t('appRestartRequestFailed'));
    }
  }, [restartApp, deviceUuid, t]);

  const handleReboot = useCallback(async () => {
    try {
      await rebootDevice({ deviceUuid });
      message.success(t('deviceRebootRequested'));
    } catch {
      message.error(t('deviceRebootRequestFailed'));
    }
  }, [deviceUuid, rebootDevice, t]);

  const [showDeleteModal] = useDeleteModal();
  const [leaseModal, showLeaseModal] = useLeaseModal(organisationId);

  const handleDelete = useCallback(() => {
    if (device) {
      showDeleteModal(
        t('confirmDeleteDevice'),
        t('areYouSureYouWantToDeleteDevice', { deviceName: device.deviceName }),
        device.deviceName,
        async () => {
          try {
            await deleteDevice({ deviceUuid });
            message.success(`${t('successfullyDeletedDevice')}`);
            history.replace(`/organisations/${organisationId}/devices`);
          } catch {
            message.error(t('failedToDeleteDevice'));
          }
        },
      );
    }
  }, [device, showDeleteModal, t, deleteDevice, deviceUuid, history, organisationId]);

  const handleEndLease = useCallback(() => {
    if (!device) return;
    showDeleteModal(
      t('lease.delete.title'),
      t('lease.delete.description', { deviceName: device.deviceName }),
      device.deviceName,
      async () => {
        try {
          await deleteLease(device.id);
          message.success(t('lease.delete.success'));
          history.replace(`/organisations/${organisationId}/devices`);
        } catch {
          message.error(t('lease.delete.failure'));
        }
      },
    );
  }, [device, showDeleteModal, t, history, organisationId]);

  const handleCreateLease = useCallback(async () => {
    if (!device) return;
    await new Promise((resolve) =>
      showLeaseModal({ deviceId: device.id, callback: resolve }),
    );
    message.success(t('lease.create.success'));
    history.replace(`/organisations/${organisationId}/devices`);
    // TODO: redirect to a new device page
  }, [showLeaseModal, device, history, organisationId, t]);

  const {
    hasParentIothubDevice,
    canReboot,
    canRestart,
    canDelete,
    canLease,
    canEndLease,
  } = useMemo(() => {
    const hasParentAndroidDevice = device && getHasParentAndroidDevice(device);
    const hasParentIothubDevice = device && getHasParentIothubDevice(device);

    return {
      hasParentIothubDevice,
      hasParentAndroidDevice,
      canReboot:
        (hasParentIothubDevice || hasParentAndroidDevice) &&
        isAllowed(permissionKeys.devices.reboot),
      canRestart: hasParentIothubDevice && isAllowed(permissionKeys.devices.restart),
      canDelete:
        isAllowed(permissionKeys.devices.manageDeviceSettings) && device && !device.lease,
      canLease: device && !device.lease && isAllowed(permissionKeys.devices.createLease),
      canEndLease:
        device && device.lease && isAllowed(permissionKeys.devices.deleteLease),
    };
  }, [device, isAllowed]);

  if (!loaded) {
    return (
      <Overlay>
        <Spinner />
      </Overlay>
    );
  }

  if (!device) {
    return null;
  }

  if (device.parentDevice && device.parentDevice.type === UniversalDeviceType.PHYHUB) {
    return (
      <Redirect
        to={`/organisations/${match.params.organisationId}/operations/devices/phyhub-devices/${match.params.deviceUuid}`}
      />
    );
  }

  return (
    <>
      {leaseModal}
      <DeviceDetailsHeader title={device ? device.deviceName : t('unknownDeviceName')}>
        <DeviceDetailsHeaderContent>
          {device.edgeStatusLog ? (
            <>
              <DeviceStatusHistory
                history={device.edgeStatusLog}
                updateTime={device.edgeStatusUpdateTime}
                small
              />
              <Status status={device.edgeStatus} />
              <StatusUpdateTime>
                {moment(device.edgeStatusUpdateTime).fromNow()}
              </StatusUpdateTime>
            </>
          ) : (
            <DeviceStatus lastUpdated={lastUpdated} size="large" device={device} />
          )}
          {device.lease && (
            <Popover
              title={t('lease.title')}
              content={[t('lease.description1'), t('lease.description2')].map((a) => (
                <p key={a}>{a}</p>
              ))}
            >
              <Tag style={{ marginLeft: '1em' }}>
                {t('lease.expires', { expiry: moment(device.lease.expiry).fromNow() })}
              </Tag>
            </Popover>
          )}
        </DeviceDetailsHeaderContent>
        <DeviceDetailsHeaderNavigation>
          <DeviceDetailsHeaderNavigationTabs>
            <TabBar
              onChange={handleTabChange}
              activeKey={`${location.pathname}${location.search}`}
              lang={i18n.language}
            >
              <Tabs.TabPane
                tab={t('overview')}
                key={`${match.url}/overview${analyticsQueryParams}`}
              />

              <Tabs.TabPane tab={t('settings')} key={`${match.url}/screen`} />
              {/* {hasParentIothubDevice && (
                <Tabs.TabPane tab={t('settings')} key={`${match.url}/settings`} />
              )} */}
              {hasParentIothubDevice &&
                isAllowed(permissionKeys.devices.manageModules) && (
                  <Tabs.TabPane tab={t('modules')} key={`${match.url}/modules`} />
                )}
            </TabBar>
          </DeviceDetailsHeaderNavigationTabs>
          <ActionButtonsContainer>
            {canLease && (
              <ActionButton size="large" onClick={handleCreateLease} icon="diff">
                {t('lease.create.action')}
              </ActionButton>
            )}
            {canRestart && (
              <Popconfirm
                title={t('areYouSure')}
                onConfirm={handleRestart}
                icon={<Icon type="question-circle-o" />}
              >
                <ActionButton size="large" icon="reload">
                  {t('restartDevice')}
                </ActionButton>
              </Popconfirm>
            )}
            {canReboot && (
              <Popconfirm
                title={t('areYouSure')}
                onConfirm={handleReboot}
                icon={<Icon type="question-circle-o" />}
              >
                <ActionButton size="large" icon="poweroff">
                  {t('rebootDevice')}
                </ActionButton>
              </Popconfirm>
            )}
            {canDelete && (
              <ActionButton
                type="danger"
                size="large"
                icon="delete"
                onClick={handleDelete}
                data-testid="universal-device-details-delete-device-button"
              >
                {t('deleteDevice')}
              </ActionButton>
            )}
            {canEndLease && (
              <ActionButton
                type="danger"
                size="large"
                icon="clock-circle"
                onClick={handleEndLease}
              >
                {t('lease.delete.action')}
              </ActionButton>
            )}
          </ActionButtonsContainer>
        </DeviceDetailsHeaderNavigation>
      </DeviceDetailsHeader>
      <div className="content-body">
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            render={() => <Redirect to={`${match.url}/screen`} />}
          />
          <ProtectedRouteContainer
            loginOnly
            path={`${match.path}/overview`}
            component={DeviceReport}
          />
          <ProtectedRouteContainer
            loginOnly
            path={`${match.path}/screen`}
            component={UniversalDeviceDetailsScreenContainer}
          />
          {hasParentIothubDevice && (
            <ProtectedRouteContainer
              loginOnly
              path={`${match.path}/settings`}
              render={(routeProps) => {
                return (
                  <UniversalDeviceDetailsSettingsContainer
                    {...routeProps}
                    azureDeviceUuid={
                      (device.parentDevice && device.parentDevice.id) as any
                    }
                  />
                );
              }}
            />
          )}

          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.devices.manageModules,
            )}
            path={`${match.path}/modules`}
            render={(routeProps) => {
              return (
                <UniversalDeviceDetailsModulesContainer
                  {...routeProps}
                  azureDeviceUuid={(device.parentDevice && device.parentDevice.id) as any}
                />
              );
            }}
          />
        </Switch>
      </div>
    </>
  );
};

export default DeviceDetails;

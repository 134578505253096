import { useQuery } from 'react-query';
import phyhubDevicesQueryKeys from './phyhub-devices-query-keys';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';
import { PhyhubDeviceListParamsFilters } from '../../../services/phyhub/types/phyhub-device-list-params.interface';

export interface PhyhubDevicesGetFiltersQueryParams {
  tenantId: string;
  filterParams?: PhyhubDeviceListParamsFilters;
}

const usePhyhubDevicesGetFilters = (
  phyhubDevicesGetFiltersQueryParams: PhyhubDevicesGetFiltersQueryParams,
) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubDevicesQueryKeys.getPhyhubDevicesGetFiltersKeys(
      phyhubDevicesGetFiltersQueryParams,
    ),
    () =>
      phyhubService.getDeviceFilters({
        tenantId: phyhubDevicesGetFiltersQueryParams.tenantId,
        filterParams: phyhubDevicesGetFiltersQueryParams.filterParams,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubDevicesGetFilters;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PhyhubDevicesContainer from '../../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import { PhyhubDeviceListParamsFiltersOperatorEnum } from '../../../../../services/phyhub/types/phyhub-device-list-params.interface';
import useEnvironments from '../../../../common/use-environments/use-environments';
import useInstallations from '../../../../common/use-installation';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import usePhyhubDevicesDefaultMassActions from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-mass-actions';
import usePhyhubDevicesSearchAndFilters from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-search-and-filters';
import PhyhubDevicesOverviewContainer from '../../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-summary/phyhub-devices-overview.container';
import usePhyhubDevicesDefaultDeviceActions from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-device-actions';
import usePhyhubDevicesDefaultTableColumns from '../../../../common/phyhub-devices/hooks/use-phyhub-devices-default-table-columns';
import PhyhubDevicesWelcomeAlert from '../../../../common/phyhub-devices/phyhub-devices-welcome-alert/phyhub-devices-welcome-alert.component';

interface SpacePhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string; spaceId: string }> {}

const SpacePhyhubDevicesContainer: FC<SpacePhyhubDevicesContainerProps> = (props) => {
  const {
    match: {
      params: { spaceId, organisationId: tenantId },
    },
  } = props;

  const { data: environmentsCollection = [] } = useEnvironments(tenantId);

  const { data: installationsCollection = [] } = useInstallations(tenantId);

  const presetFilters = useMemo(
    () => ({
      spaceIds: {
        op: PhyhubDeviceListParamsFiltersOperatorEnum.IN,
        val: [spaceId],
      },
    }),
    [spaceId],
  );

  const { filterOptions } = usePhyhubDeviceFilters({
    tenantId,
    filterParams: presetFilters,
    environments: environmentsCollection,
    installations: installationsCollection,
  });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const {
    searchPhrase,
    handleSetSearchPhrase,
    filters,
    handleFiltersChange,
  } = usePhyhubDevicesSearchAndFilters({ presetFilters });

  const [selectedDevices, setSelectedDevices] = useState<PhyhubDevice[]>([]);

  const handleSetSelectedDevices = useCallback((devices: PhyhubDevice[]) => {
    setSelectedDevices(devices);
  }, []);

  const handleResetSelectedDevices = useCallback(() => {
    setSelectedDevices([]);
  }, []);

  const { defaultDeviceActionItems } = usePhyhubDevicesDefaultDeviceActions({ tenantId });

  const {
    isMassActionsLoading,
    defaultDeviceMassActionItems,
  } = usePhyhubDevicesDefaultMassActions({
    tenantId,
    onResetSelectedDevices: handleResetSelectedDevices,
  });

  const {
    defaultTableColumns,
    getTableRowSelection,
  } = usePhyhubDevicesDefaultTableColumns({
    deviceActionItems: defaultDeviceActionItems,
    massActions: {
      selectedDevices,
      deviceMassActionItems: defaultDeviceMassActionItems,
      onSetSelectedDevices: handleSetSelectedDevices,
    },
  });

  return (
    <>
      <PhyhubDevicesWelcomeAlert
        legacyDevicesPath={`/organisations/${tenantId}/spaces/${spaceId}/details-v2/legacy-devices`}
      />
      <PhyhubDevicesContainer
        isTableLoading={isMassActionsLoading}
        searchPhrase={searchPhrase}
        tenantId={tenantId}
        filterOptions={filterOptions}
        filters={filters}
        getTableRowSelection={getTableRowSelection}
        overview={
          <PhyhubDevicesOverviewContainer
            searchPhrase={searchPhrase}
            tenantId={tenantId}
            filters={filters}
          />
        }
        sortCriteriaOptions={sortCriteriaOptions}
        tableColumns={defaultTableColumns}
        onFiltersChange={handleFiltersChange}
        onSearch={handleSetSearchPhrase}
      />
    </>
  );
};

export default SpacePhyhubDevicesContainer;

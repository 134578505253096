import React, { FC, memo, useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PhyhubDevice } from '../../../../../../services/phyhub/types/phyhub-device.interface';

export interface PhyhubDevicesMassAction {
  key: string;
  icon?: string;
  title: string;
  type?: 'default' | 'danger';
  onClick: (devices: PhyhubDevice[]) => void;
}

interface PhyhubDevicesTableMassActionsProps {
  devicesMassActionItems: PhyhubDevicesMassAction[];
  selectedDevices: PhyhubDevice[];
}

const PhyhubDevicesTableMassActions: FC<PhyhubDevicesTableMassActionsProps> = (props) => {
  const { devicesMassActionItems, selectedDevices } = props;

  const { t } = useTranslation();

  const getClickHandler = useCallback(
    (onClick: (devices: PhyhubDevice[]) => void) => () => {
      onClick(selectedDevices);
    },
    [selectedDevices],
  );

  return (
    <Wrapper>
      <Title>
        {t('phyhubDevices.label.withSelected', { count: selectedDevices.length })}
      </Title>
      {devicesMassActionItems.map((devicesMassActionItem) => (
        <Button
          key={devicesMassActionItem.key}
          icon={devicesMassActionItem.icon}
          type={devicesMassActionItem.type || 'default'}
          onClick={getClickHandler(devicesMassActionItem.onClick)}
        >
          {devicesMassActionItem.title}
        </Button>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: nowrap;
`;

export default memo(PhyhubDevicesTableMassActions);

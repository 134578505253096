import { AxiosError } from 'axios';

/**
 * Parses an unknown error and determines if it is an AxiosError.
 *
 * @param {unknown} error - The error object to be evaluated.
 *
 * @returns {AxiosError | null} Returns the error as an AxiosError if it meets
 * the criteria of an AxiosError, otherwise returns null.
 */
export const parseAxiosError = (error: unknown): AxiosError | null => {
  if (
    typeof error === 'object' &&
    error !== null &&
    Object.assign({ response: undefined }, error).response
  ) {
    // 'as' statement used because the current TS version does not support
    // `error is AxiosError` returning type
    return error as AxiosError;
  }

  return null;
};

import React, { FC, memo, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Empty, Tabs } from 'antd';
import { History } from 'history';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import usePhyhubDevicesGet from '../../../../store/hooks/phyhub-devices/use-phyhub-devices-get';
import { Spinner } from '../../../common/spinner';
import Overlay from '../../../common/overlay/overlay.component';
import ErrorView from '../../../common/error-view/error-view.component';
import PhyhubDeviceHeader from '../../../common/phyhub-devices/phyhub-device-header/phyhub-device-header.component';
import { useAnalyticsParams } from '../../../common/use-analytics-params';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import { StateWrapper } from '../../../common/phyhub-devices/phyhub-devices-common.components';
import useGoBack from '../../../common/use-go-back';
import PhyhubDeviceDetailsScreenContainer from './phyhub-device-details-screen/phyhub-device-details-screen.container';
import usePhyhubDeviceDeleteFlow from '../../../common/phyhub-devices/hooks/phyhub-device-actions/use-phyhub-device-delete-flow';
import usePhyhubDeviceRebootFlow from '../../../common/phyhub-devices/hooks/phyhub-device-actions/use-phyhub-device-reboot-flow';
import { PhyhubDeviceStatusEnum } from '../../../../services/phyhub/enums/phyhub-device-status.enum';
import PhyhubDeviceDetailsOverview from './phyhub-device-details-overview/phyhub-device-details-overview.component';

interface OrganisationPhyhubDeviceDetailsContainerProps {
  deviceId: string;
  matchPath: string;
  matchUrl: string;
  tenantId: string;
  history: History;
}

const OrganizationPhyhubDeviceDetailsContainer: FC<OrganisationPhyhubDeviceDetailsContainerProps> = (
  props,
) => {
  const { deviceId, matchPath, matchUrl, tenantId, history } = props;

  const { i18n, t } = useTranslation();

  const {
    isError: isPhyhubDevicesGetError,
    isFetching: isPhyhubDevicesGetFetching,
    isLoading: isPhyhubDevicesGetLoading,
    isSuccess: isPhyhubDevicesGetSuccess,
    data: phyhubDeviceItem,
  } = usePhyhubDevicesGet({ deviceId, tenantId });

  const { analyticsQueryParams } = useAnalyticsParams();

  const location = useLocation();

  const handleTabChange = useCallback(
    (key: string) => {
      history.replace(key);
    },
    [history],
  );

  const goBack = useGoBack();

  const handleDeviceDeleteSuccess = useCallback(() => {
    goBack(`/organisations/${tenantId}/operations/devices`);
  }, [goBack, tenantId]);

  const {
    isDeviceDeleteLoading: isPhyhubDeviceDeleteLoading,
    handleDeviceDelete,
  } = usePhyhubDeviceDeleteFlow({
    tenantId,
    onDeviceDeleteSuccess: handleDeviceDeleteSuccess,
  });

  const handleDeviceDeleteClick = useCallback(() => {
    if (!phyhubDeviceItem) {
      return;
    }

    handleDeviceDelete(phyhubDeviceItem);
  }, [phyhubDeviceItem, handleDeviceDelete]);

  const {
    isDeviceRebootLoading: isPhyhubDeviceRebootLoading,
    handleDeviceReboot,
  } = usePhyhubDeviceRebootFlow({ tenantId });

  const handleDeviceRebootClick = useCallback(() => {
    if (!phyhubDeviceItem) {
      return;
    }

    handleDeviceReboot(phyhubDeviceItem);
  }, [phyhubDeviceItem, handleDeviceReboot]);

  if (
    isPhyhubDevicesGetLoading ||
    isPhyhubDevicesGetFetching ||
    isPhyhubDeviceDeleteLoading
  ) {
    return (
      <StateWrapper>
        <Overlay>
          <Spinner />
        </Overlay>
      </StateWrapper>
    );
  }

  if (isPhyhubDevicesGetError) {
    return (
      <StateWrapper>
        <ErrorView
          title={t('phyhubDevices.details.error.title')}
          content={t('phyhubDevices.details.error.subtitle')}
        />
      </StateWrapper>
    );
  }

  if (!isPhyhubDevicesGetSuccess || !phyhubDeviceItem) {
    return (
      <StateWrapper>
        <Empty description={t('phyhubDevices.details.emptyState')} />
      </StateWrapper>
    );
  }

  return (
    <>
      <PhyhubDeviceHeader
        deviceDisplayName={phyhubDeviceItem.displayName}
        deviceStatusUpdatedAt={
          phyhubDeviceItem.statusUpdatedAt || phyhubDeviceItem.updatedAt
        }
        deviceStatus={phyhubDeviceItem.status}
      >
        <HeaderBar>
          <HeaderBarTabs>
            <TabBar
              onChange={handleTabChange}
              activeKey={`${location.pathname}${location.search}`}
              lang={i18n.language}
            >
              <Tabs.TabPane
                tab={t('overview')}
                key={`${matchUrl}/overview${analyticsQueryParams}`}
              />
              <Tabs.TabPane tab={t('settings')} key={`${matchUrl}/screen`} />
            </TabBar>
          </HeaderBarTabs>
          <HeaderBarActions>
            <Button
              disabled={
                !!phyhubDeviceItem &&
                phyhubDeviceItem.status !== PhyhubDeviceStatusEnum.ONLINE
              }
              loading={isPhyhubDeviceRebootLoading}
              icon="poweroff"
              size="large"
              onClick={handleDeviceRebootClick}
            >
              {t('rebootDevice')}
            </Button>
            <Button
              icon="delete"
              size="large"
              type="danger"
              onClick={handleDeviceDeleteClick}
            >
              {t('deleteDevice')}
            </Button>
          </HeaderBarActions>
        </HeaderBar>
      </PhyhubDeviceHeader>
      <Switch>
        <Route
          path={matchPath}
          exact
          render={() => <Redirect to={`${matchUrl}/screen`} />}
        />
        <ProtectedRouteContainer
          loginOnly
          path={`${matchPath}/overview`}
          component={PhyhubDeviceDetailsOverview}
        />
        <ProtectedRouteContainer
          loginOnly
          path={`${matchPath}/screen`}
          render={() => (
            <PhyhubDeviceDetailsScreenContainer deviceItem={phyhubDeviceItem} />
          )}
        />
      </Switch>
    </>
  );
};

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const HeaderBarTabs = styled.div`
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;

const HeaderBarActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export default memo(OrganizationPhyhubDeviceDetailsContainer);

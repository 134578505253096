import { PhyhubTwinsListQueryParams } from './use-phyhub-twins-list';

const phyhubTwinsQueryKeys = {
  queryPrefix: () => {
    return 'phyhub-twins';
  },
  tenantPrefixKeys: (tenantId: string) => [
    phyhubTwinsQueryKeys.queryPrefix(),
    'tenantId',
    tenantId,
  ],
  manyPrefixKeys: (tenantId: string) => [
    ...phyhubTwinsQueryKeys.tenantPrefixKeys(tenantId),
    'many',
  ],
  getPhyhubTwinsListKeys: (phyhubDevicesListQueryParams: PhyhubTwinsListQueryParams) => [
    ...phyhubTwinsQueryKeys.manyPrefixKeys(phyhubDevicesListQueryParams.tenantId),
    'list',
    'page',
    phyhubDevicesListQueryParams.page,
    'limit',
    phyhubDevicesListQueryParams.limit,
    'filters',
    JSON.stringify(phyhubDevicesListQueryParams.filters || {}),
  ],
};

export default phyhubTwinsQueryKeys;

import {
  Col,
  Row,
  Tabs,
  Typography,
  Tag,
  Dropdown,
  Menu,
  Icon,
  Button,
  message,
} from 'antd';
import get from 'lodash/get';
import styled from '@emotion/styled';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  matchPath,
} from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../common/app-layout/header/header.component';
import OrganisationApp from '../../../../store/types/organisation-app';
import ContentContainer from './content/content.container';
import TabBar from '../../../common/tab-bar/tab-bar.component';
import SettingsContainer from './settings/settings.container';
import WebSignalsSettingsContainer from '../web-signals-settings/web-signals-settings.container';
import ProtectedRouteContainer from '../../../common/protected-route/protected-route.container';
import BuildsV2Container from './builds-v2/builds-v2.container';
import OverviewContainer from './overview/overview.container';
import LegacyOverviewContainer from './legacy-overview/legacy-overview.container';
import SettingsV2Container from './settings-v2/settings-v2.container';
import SettingsConfiguration from './settings-v2/settings-configuration/settings-configuration.container';
import User from '../../../../store/types/user';
import PermissionRules from '../../../../store/types/permissions';
import DeviceTypeEnum from '../../../../store/types/device-type.enum';
import { getPermissionPath, permissionKeys } from '../../../../utils/auth/permissions';
import MobileEndpointsContainer from './mobile-endpoints/mobile-endpoints.container';
import usePermissions from '../../../../utils/auth/use-permissions';
import DevicesUniversalContainer from './devices-universal/devices-universal.container';
import IoTAppDevicesUniversalContainer from './devices-universal/iot-installation-universal.container';
import QueueEditContainer from '../queue-details/queue-setup/queue-edit.container';
import Queue from '../../../../store/types/queue';
import Gridapp from '../../../../store/types/gridapp';
import { NON_QUEUE_CLOUD_APP_PACKAGE_NAMES } from '../../../../utils/installations/installations-constants';
import BuildContainer from './builds-v2/build.container';
import Organisation from '../../../../store/types/organisation';
import RoleEnum from '../../../../store/types/role.enum';
import { useAnalyticsParams } from '../../../common/use-analytics-params';
import useDeleteModal from '../../../common/use-delete-modal';
import { ButtonType } from '../../../../types';
import useHasPermissions from '../../../../utils/auth/use-has-permissions';
import InstallationPhyhubDevicesContainer from './phyhub-devices/installation-phyhub-devices.container';

const { Text } = Typography;

interface AppDetailsProps
  extends RouteComponentProps<{
    appId: string;
    organisationId: string;
    buildId: string;
  }> {
  app: OrganisationApp | null;
  gridapp: Gridapp | null;
  loaded: boolean;
  permissions: PermissionRules<boolean>;
  user: User | null;
  organisations: {
    [id: string]: Organisation;
  };
  deleteApp: (app: OrganisationApp) => Promise<void>;
}

const AppDetailsHeader = styled(Header)`
  padding-bottom: 0;
`;

const QueueStatusRow = styled(Row)`
  .ant-col {
    margin-top: 12px;
  }
`;

const AppeDetailsHeaderNavigationTabs = styled.div`
  flex: 1 0 auto;
  flex-direction: row;
  display: flex;
  width: 100%;

  .ant-tabs {
    width: 100%;
  }

  /* alternative adjustment to standard Header padding */
  margin: 10px 0 0 0;
  .ant-tabs-nav-container-scrolling {
    margin-left: -10px;
    margin-right: -10px;
  }

  /* alternative to tabBarGutter */
  div[role='tab'] {
    margin-right: 10px;
  }
`;

const deviceTypeDisplayNames = {
  [DeviceTypeEnum.BALENA]: 'Balena',
  [DeviceTypeEnum.LINUX]: 'Linux',
  [DeviceTypeEnum.WINDOWS]: 'Windows',
  [DeviceTypeEnum.WPA]: 'WPA',
  [DeviceTypeEnum.MOBILE_WPA]: 'Mobile WPA',
  [DeviceTypeEnum.IOT]: 'IoT',
  [DeviceTypeEnum.CLOUD]: 'Cloud',
};

enum MenuKeysEnum {
  OVERVIEW = 'overview',
  LEGACY_OVERVIEW = 'legacy-overview',
  CONTENT = 'content',
  SETTINGS = 'settings',
  SETTINGS_V2 = 'settings-v2',
  BUILDS = 'builds',
  DEVICE_UNIVERSAL = 'legacy-devices',
  MOBILE_ENDPOINTS = 'mobile-endpoints',
  OTHER_CONFIGURATIONS = 'other-configurations',
  ENV_VARIABLES = 'env-variables',
  ENV_VARIABLES_V2 = 'v2/env-variables',
  PHYHUB_DEVICES = 'devices',
}

const MenuTabs = {
  [MenuKeysEnum.OVERVIEW]: {
    path: '/overview',
  },
  [MenuKeysEnum.LEGACY_OVERVIEW]: {
    path: '/legacy-overview',
  },
  [MenuKeysEnum.CONTENT]: {
    path: '/content',
  },
  [MenuKeysEnum.SETTINGS]: {
    path: '/settings',
  },
  [MenuKeysEnum.SETTINGS_V2]: {
    path: '/settings-v2',
  },
  [MenuKeysEnum.BUILDS]: {
    path: '/builds',
  },
  [MenuKeysEnum.DEVICE_UNIVERSAL]: {
    path: '/legacy-devices',
  },
  [MenuKeysEnum.MOBILE_ENDPOINTS]: {
    path: '/mobile-endpoints',
  },
  [MenuKeysEnum.OTHER_CONFIGURATIONS]: {
    path: '/other-configurations',
  },
  [MenuKeysEnum.ENV_VARIABLES]: {
    path: '/env-variables',
  },
  [MenuKeysEnum.ENV_VARIABLES_V2]: {
    path: '/v2/env-variables',
  },
  [MenuKeysEnum.PHYHUB_DEVICES]: {
    path: '/devices',
  },
};

const AppDetails = (props: AppDetailsProps) => {
  const {
    app,
    loaded,
    match,
    location,
    history,
    user,
    gridapp,
    organisations,
    deleteApp,
  } = props;
  const { t, i18n } = useTranslation();
  const { isAllowed } = usePermissions(match.params.organisationId);

  const [showDeleteModal] = useDeleteModal();

  const [tabKey, setTabKey] = useState<MenuKeysEnum>(MenuKeysEnum.OVERVIEW);

  const { organisationId } = match.params;

  const isDeleteAllowed = useHasPermissions(
    match.params.organisationId,
    permissionKeys.apps.remove,
  );

  const { analyticsQueryParams } = useAnalyticsParams();

  const {
    isIoT,
    isWebSignals,
    isQueue,
    isCloud,
    isScreen,
    isMobile,
    showOverviewTab,
    showSettingsV2Tab,
    showContentTab,
    showSettingsTab,
    showBuildsTab,
    showBuild,
    showMobileEndpointsTab,
    showDevicesTab,
    showOtherConfigurationsTab,
    hasSettingsPermission,
    canViewAppInfo,
  } = useMemo(() => {
    const isIoTApp = !!app && app.deviceType === DeviceTypeEnum.IOT;
    const isWebSignalsApp =
      !!app && app.deviceType === DeviceTypeEnum.CLOUD && app.provider === 'web-signals';
    const isQueueApp =
      !!app &&
      app.deviceType === DeviceTypeEnum.CLOUD &&
      !NON_QUEUE_CLOUD_APP_PACKAGE_NAMES.includes(app.provider);
    const isScreenApp = !!app && app.deviceType === DeviceTypeEnum.WPA;
    const isMobileApp = !!app && app.deviceType === DeviceTypeEnum.MOBILE_WPA;
    const isCloudApp = isWebSignalsApp || isQueueApp;

    const showSettingsV2Tab =
      app &&
      !isCloudApp &&
      app.enableLayoutV2 &&
      isAllowed(permissionKeys.apps.viewContents);

    return {
      isIoT: isIoTApp as boolean,
      isWebSignals: isWebSignalsApp as boolean,
      isQueue: isQueueApp as boolean,
      isCloud: isCloudApp as boolean,
      isMobile: isMobileApp as boolean,
      isScreen: isScreenApp as boolean,
      showOverviewTab: !isIoTApp && !isCloudApp,
      showSettingsV2Tab,
      showContentTab:
        !showSettingsV2Tab && !isCloudApp && isAllowed(permissionKeys.apps.viewContents),
      showSettingsTab: !showSettingsV2Tab && isAllowed(permissionKeys.apps.viewSettings),
      hasSettingsPermission: isAllowed(permissionKeys.apps.viewContents),
      showBuildsTab: !isCloudApp && isAllowed(permissionKeys.builds.viewAll),
      showBuild: !isCloudApp && isAllowed(permissionKeys.builds.viewSingle),
      showMobileEndpointsTab:
        !!app &&
        app.deviceType === DeviceTypeEnum.MOBILE_WPA &&
        isAllowed(permissionKeys.mobileEndpoints.viewAll),
      showDevicesTab:
        !!app &&
        [DeviceTypeEnum.IOT, DeviceTypeEnum.WPA].includes(app.deviceType) &&
        isAllowed(permissionKeys.devices.viewAll),
      showOtherConfigurationsTab: isCloudApp,
      canViewAppInfo: isAllowed(permissionKeys.gridApps.viewSingle),
    };
  }, [app, isAllowed, user]);

  useEffect(() => {
    const segment = location.pathname.replace(match.url, '').split('/')[1];

    setTabKey(segment as MenuKeysEnum);
  }, [match, location]);

  const handleInstallationDelete = useCallback(() => {
    if (app) {
      showDeleteModal(
        t('confirmDeleteInstallation'),
        t('areYouSureYouWantToDeleteInstallation', { installationName: app.displayName }),
        app.displayName,
        async () => {
          try {
            await deleteApp(app);
            message.success(t('successfullyDeletedInstallation'));
            history.replace(`/organisations/${app.organizationId}/apps/marketplace`);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
              message.error(error.response.data.message);
            } else {
              message.error(t('failedToDeleteInstallation'));
            }
          }
        },
      );
    }
  }, [app, showDeleteModal, t, deleteApp, history]);

  const handleTabChange = useCallback(
    (key: string) => {
      const menuKey = key as MenuKeysEnum;
      setTabKey(menuKey);

      let path = MenuTabs[menuKey].path;

      if (key === MenuKeysEnum.OVERVIEW) {
        path = `${path}/${analyticsQueryParams}`;
      }

      history.push(`${match.url}${path}`);
    },
    [history, match, analyticsQueryParams],
  );

  const header = useMemo(() => {
    if (!loaded || !app) return null;

    const CHEVRON_RIGHT = '\u203A';

    let providerNameElement;
    if (isIoT || isScreen || isMobile) {
      if (gridapp) {
        const providerNameText = `${gridapp.author} ${CHEVRON_RIGHT} ${gridapp.displayName}`;

        let url;
        if (organisations[gridapp.organizationId]) {
          const userRole =
            user &&
            user.organizationsWithRole.find(
              (org: { organizationId: string; roleId: string }) =>
                org.organizationId === gridapp.organizationId,
            );
          url =
            (userRole && userRole.roleId === RoleEnum.ADMIN) || (user && user.isSysadmin)
              ? `/organisations/${gridapp.organizationId}/apps/developer/apps/${gridapp.id}/info`
              : null;
        } else {
          url = `/organisations/${organisationId}/apps/marketplace/browse/apps-library/${gridapp.id}/info`;
        }

        providerNameElement =
          canViewAppInfo && url ? (
            <Link target="_blank" to={url}>
              {providerNameText}
            </Link>
          ) : (
            <>{providerNameText}</>
          );
      }
    } else {
      providerNameElement = '...';
    }

    let headerContents = (
      <Row gutter={24} type="flex">
        <Col>
          <Text>{app.appName}</Text>
        </Col>
        <Col>
          <Text>
            {deviceTypeDisplayNames[app.deviceType]} {app.architecture}
          </Text>
        </Col>
        {providerNameElement && <Col>{providerNameElement}</Col>}
      </Row>
    );

    if (isIoT) {
      headerContents = (
        <Row gutter={24} type="flex">
          <Col>
            <Text>{app.appName}</Text>
          </Col>
          <Col>
            <Text>{deviceTypeDisplayNames[app.deviceType]}</Text>
          </Col>
        </Row>
      );
    } else if (isQueue) {
      const queue = get(app, 'settings.provider.app.settings', undefined) as
        | Queue
        | undefined;

      if (queue) {
        let capacityColor =
          (queue.fulfilledPositionsCount || 0) < (queue.storeCapacity || 0)
            ? 'yellow'
            : 'red';

        capacityColor = !queue.fulfilledPositionsCount ? 'green' : capacityColor;
        const queueOpenCloseStatusColor = queue.open ? 'green' : 'red';

        headerContents = (
          <>
            <Row gutter={24} type="flex">
              <Col>
                <Text>{app.appName}</Text>
              </Col>
              <Col>
                <Text>{app.provider}</Text>
              </Col>
              <Col>
                <Text>{deviceTypeDisplayNames[app.deviceType]}</Text>
              </Col>
            </Row>
            <QueueStatusRow gutter={5} type="flex">
              <Col>
                <Tag color={capacityColor}>
                  {t('capacityCount', {
                    fulfilledPositionsCount: queue.fulfilledPositionsCount || 0,
                    storeCapacity: queue.storeCapacity || 0,
                  })}
                </Tag>
              </Col>
              <Col>
                <Tag color="gray">
                  {t('queueCount', {
                    queueCount: queue.pendingPositionsCount || 0,
                  })}
                </Tag>
              </Col>
              <Col>
                <Tag color="gray">
                  {t('totalCount', {
                    totalCount:
                      (queue.pendingPositionsCount || 0) +
                      (queue.fulfilledPositionsCount || 0),
                  })}
                </Tag>
              </Col>
              <Col>
                <Tag color={queueOpenCloseStatusColor}>
                  {t(queue.open ? 'queueIsOpen' : 'queueIsClosed')}
                </Tag>
              </Col>
            </QueueStatusRow>
          </>
        );
      }
    }

    return (
      <AppDetailsHeader title={app.displayName}>
        {headerContents}
        <AppeDetailsHeaderNavigationTabs>
          <TabBar
            onChange={handleTabChange}
            activeKey={tabKey}
            lang={i18n.language}
            refreshTrigger={app.id && app.deviceType}
          >
            {/* Allow tab display by default */}
            <Tabs.TabPane tab={t('overview')} key={MenuKeysEnum.OVERVIEW} />
            {showOverviewTab && (
              <Tabs.TabPane
                tab={t('legacyOverview')}
                key={MenuKeysEnum.LEGACY_OVERVIEW}
              />
            )}
            {showSettingsV2Tab && (
              <Tabs.TabPane
                tab={t('settingsOverriding')}
                key={MenuKeysEnum.SETTINGS_V2}
              />
            )}
            {showContentTab && (
              <Tabs.TabPane
                tab={isIoT ? t('deviceConfiguration') : t('content')}
                key={MenuKeysEnum.CONTENT}
              />
            )}
            {showSettingsTab && (
              <Tabs.TabPane tab={t('settings')} key={MenuKeysEnum.SETTINGS} />
            )}
            {showOtherConfigurationsTab && (
              <Tabs.TabPane
                tab={t('otherConfigurations')}
                key={MenuKeysEnum.OTHER_CONFIGURATIONS}
              />
            )}
            {showBuildsTab && (
              <Tabs.TabPane tab={t('builds.title')} key={MenuKeysEnum.BUILDS} />
            )}
            {showMobileEndpointsTab && (
              <Tabs.TabPane
                tab={t('mobileEndpoints')}
                key={MenuKeysEnum.MOBILE_ENDPOINTS}
              />
            )}
            {showDevicesTab && (
              <Tabs.TabPane tab={t('devices')} key={MenuKeysEnum.PHYHUB_DEVICES} />
            )}
            {showDevicesTab && (
              <Tabs.TabPane
                tab={t('universalDevices')}
                key={MenuKeysEnum.DEVICE_UNIVERSAL}
              />
            )}
          </TabBar>
          {isDeleteAllowed && (
            <ExtraActionsContainer>
              <EllipseIconContainer>
                <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <Menu.Item key={`${app.id}-remove`}>
                        <RemoveButton
                          onClick={handleInstallationDelete}
                          icon="delete"
                          size="small"
                        >
                          {t('delete')}
                        </RemoveButton>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <StyledIcon type="ellipsis" />
                </Dropdown>
              </EllipseIconContainer>
            </ExtraActionsContainer>
          )}
        </AppeDetailsHeaderNavigationTabs>
      </AppDetailsHeader>
    );
  }, [
    loaded,
    app,
    isIoT,
    isScreen,
    isMobile,
    isQueue,
    handleTabChange,
    i18n.language,
    t,
    showOverviewTab,
    showSettingsV2Tab,
    showContentTab,
    showSettingsTab,
    showOtherConfigurationsTab,
    showBuildsTab,
    showMobileEndpointsTab,
    showDevicesTab,
    gridapp,
    tabKey,
    organisationId,
    canViewAppInfo,
    organisations,
    user,
    handleInstallationDelete,
    isDeleteAllowed,
  ]);

  const renderDefaultRedirect = useCallback(() => {
    // KP: there is an odd case where these 2 are not in sync causing unexpected redirects
    if (app && match.params.appId !== app.id) {
      return null;
    }

    const defaultRoute = MenuKeysEnum.OVERVIEW;
    return (
      <Redirect
        to={{ pathname: `${match.url}/${defaultRoute}`, search: location.search }}
      />
    );
  }, [app, match, location]);

  const settingsComponent = useMemo(() => {
    if (!isCloud) {
      return SettingsContainer;
    }

    if (isQueue) {
      return QueueEditContainer;
    }

    if (isWebSignals) {
      return WebSignalsSettingsContainer;
    }

    return () => <div>Unsupported</div>;
  }, [isCloud, isQueue, isWebSignals]);

  if (!loaded || !app) {
    return null;
  }

  if (
    matchPath(
      location.pathname,
      `${match.path}/settings-configuration/:configType?/:id?/:deviceUuid?`,
    )
  ) {
    return (
      <ProtectedRouteContainer
        permissionPath={getPermissionPath(
          organisationId,
          permissionKeys.apps.viewSettings,
        )}
        path={`${match.path}/settings-configuration/:configType?/:id?/:deviceUuid?`}
        component={SettingsConfiguration}
      />
    );
  }

  return (
    <>
      {header}
      <Switch>
        <Route exact path={match.path} render={renderDefaultRedirect} />
        <ProtectedRouteContainer
          permissionPath={getPermissionPath(
            organisationId,
            permissionKeys.apps.viewAnalytics,
          )}
          path={`${match.path}/${MenuKeysEnum.OVERVIEW}`}
          component={OverviewContainer}
        />
        {showOverviewTab && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.apps.viewAnalytics,
            )}
            path={`${match.path}/${MenuKeysEnum.LEGACY_OVERVIEW}`}
            component={LegacyOverviewContainer}
          />
        )}
        {hasSettingsPermission && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.apps.viewSettings,
            )}
            path={`${match.path}/${MenuKeysEnum.SETTINGS_V2}`}
            component={SettingsV2Container}
          />
        )}
        {hasSettingsPermission && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.apps.viewContents,
            )}
            path={`${match.path}/${MenuKeysEnum.CONTENT}`}
            component={ContentContainer}
          />
        )}
        {hasSettingsPermission && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.apps.viewSettings,
            )}
            path={`${match.path}/${MenuKeysEnum.SETTINGS}`}
            component={settingsComponent}
          />
        )}
        {showOtherConfigurationsTab && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.builds.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.OTHER_CONFIGURATIONS}`}
            component={SettingsContainer}
          />
        )}
        {showBuildsTab && (
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.builds.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.BUILDS}`}
            component={BuildsV2Container}
          />
        )}
        {showBuild && (
          <ProtectedRouteContainer
            exact
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.builds.viewSingle,
            )}
            path={`${match.path}/${MenuKeysEnum.BUILDS}/:buildId`}
            component={BuildContainer}
          />
        )}
        {showDevicesTab && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.devices.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.PHYHUB_DEVICES}`}
            component={InstallationPhyhubDevicesContainer}
          />
        )}
        {showDevicesTab && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.devices.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.DEVICE_UNIVERSAL}`}
            component={
              app.deviceType === 'iot'
                ? IoTAppDevicesUniversalContainer
                : DevicesUniversalContainer
            }
          />
        )}
        {showMobileEndpointsTab && (
          <ProtectedRouteContainer
            permissionPath={getPermissionPath(
              organisationId,
              permissionKeys.mobileEndpoints.viewAll,
            )}
            path={`${match.path}/${MenuKeysEnum.MOBILE_ENDPOINTS}`}
            component={MobileEndpointsContainer}
          />
        )}
      </Switch>
    </>
  );
};

const ExtraActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: 32px;
`;

const EllipseIconContainer = styled.div`
  bottom: 8px;
  position: relative;
`;

const RemoveButton = styled(Button)`
  color: rgb(255, 85, 0);
  background: transparent;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: rgb(255, 85, 0);
    background: transparent;
    border: none;
  }
` as ButtonType;

const StyledIcon = styled(Icon)`
  font-size: 21px;
`;

export default AppDetails;

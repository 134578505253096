/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import {
  Link,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import {
  Avatar,
  Dropdown,
  Menu,
  Badge,
  Card,
  Tooltip,
  Modal,
  Button,
  message,
  Layout,
} from 'antd';
import { Icon } from '../../common/schema-form/common';
import copy from 'copy-to-clipboard';
import { get, omitBy } from 'lodash';
import styled from '@emotion/styled';
import { matchPath, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../common/app-layout/app-layout.component';
import OrganisationApp from '../../../store/types/organisation-app';
import Organisation from '../../../store/types/organisation';
import AppDetailsContainer from './app-details/app-details.container';
import ErrorView from '../../common/error-view/error-view.component';
import Overlay from '../../common/overlay/overlay.component';
import OrganisationOverviewContainer from './organisation-overview/organisation-overview.container';
import User from '../../../store/types/user';
import { getPermissionPath, permissionKeys } from '../../../utils/auth/permissions';
import usePermissions from '../../../utils/auth/use-permissions';
import ProtectedRouteContainer from '../../common/protected-route/protected-route.container';
import SidebarMenu from '../../common/app-layout/sidebar/sidebar-menu/sidebar-menu.component';
import EnvironmentsListContainer from './environments-list/environments-list.container';
import AppsListContainer from './apps-list/apps-list.container';
import OrganisationMediaLibraryContainer from './organisation-media-library/organisation-media-library.container';
import OrganisationSpaceContainer from './spaces-list/spaces-list.container';
import SpacesContainer from './spaces-list/spaces-router.container';
import SpaceFormAdd from './spaces-list/space-form/space-form-add.component';
import OrganisationChildrenListContainer from './organisations-list/organisation-list.container';
import OrganisationCustomShortUrlsContainer from './custom-short-url-list/custom-short-url-list.container';
import DevicesUniversalListContainer from './devices-universal-list/devices-universal-list.container';
import UniversalDeviceDetailsContainer from './device-details/universal-device-details/universal-device-details.container';
import InstallationGroup from '../../../store/types/installation-group';
import getInstallationAppIcon from '../../../utils/installations/get-installation-app-icon-url';
import getGridappTypeIcon from '../../../utils/gridapp-type-icons';
import DevelopersSection from './developers-section/developers-section.component';
import Marketplace from './marketplace/marketplace.component';
import Billing from './billing/billing.component';
import Plans from './plans/plans.component';
import Contacts from './contacts';
import GridProducts from './grid-products/grid-products.container';
import UserFormFields from './spaces-list/spaces-details/space-details-users/create-users-form.component';
import { InstallationGroupContainer } from './installation-group/installation-group.container';
import Space from '../../../store/types/organisation-space';
import Spinner from '../../common/spinner/spinner.component';
import useGroupAppsByInstallationGroups, {
  noInstallationGroupKey,
} from '../../../utils/group-apps-by-installation-group';
import { Tool, Bell } from 'react-feather';
import Branding from './branding/branding.component';
import Alerts from './alerts/alerts.component';
import DataExport from './data-export/data-export.component';
import Networking from './networking/networking.component';
import { useGroupedSpacesTreeProps } from '../../../utils/spaces';
import Tree from '../../common/tree/tree.component';
import { Settings } from './settings';
import { isProd } from '../../../utils/env';
import StoreAI from './store-ai';
import { useAnalyticsParams } from '../../common/use-analytics-params';
import UserManagementContainer from './user-management/user-management.container';
import ContentContainer from './content/content.container';
import useUserSpacePermissions from '../../../store/hooks/spaces/useUserSpacePermissions';
import OrganisationPhyhubDevicesContainer from './phyhub-devices/organisation-phyhub-devices.container';
import OrganizationPhyhubDeviceDetailsWrapperContainer from './phyhub-device-details/organization-phyhub-device-details-wrapper.container';
import Header from '../../common/app-layout/header/header.component';
import { InstallationsLayout } from './installation-group/installations-layout';

import gridLogoIcon from '../../../assets/images/phygrid_logo_fade_light_symbol.svg';

interface OrganisationDetailsProps
  extends RouteComponentProps<{ organisationId: string; spaceId?: string }> {
  organisation: Organisation | null;
  organisationsList: Organisation[];
  fetchApps: ({
    organizationId,
    silent,
  }: {
    organizationId: string;
    silent?: boolean;
  }) => void;
  appsAndGroupsLoaded: boolean;
  apps: OrganisationApp[];
  installationGroups: InstallationGroup[];
  user: User | null;
  spaces: Space[];
  tenantSettings?: any;
}

const { Sider } = Layout;

const organisationMenuKeys = [
  'billing',
  'branding',
  'settings',
  'contacts',
  'content',
  'operations',
  'managed-tenants',
  'plans',
  'spaces',
  'user-management',
];

const organisationPatternPaths = [
  '/analytics/data-export',
  '/analytics',

  '/apps/installations',
  '/apps/marketplace',
  '/apps/developer',
  '/apps/installations/:appId',
  '/apps',

  '/content/library/:selectedFolderId?',
  '/content/library',
  '/content/channels',
  '/content/tags',
  '/content/grid-products',
  '/content/custom-short-urls',
  '/content',

  '/operations/devices',
  '/operations/legacy-devices',
  '/operations/alerts',
  '/operations/environments',
  '/operations/networking',

  '/spaces/:spaceId',
  '/spaces/new',
  '/spaces',

  '/settings/billing',
  '/settings/branding',
  '/settings/managed-tenants',
  '/settings/plans',
  '/settings/users/new',
  '/settings/user-management',
  '/settings',

  '/contacts',

  '/store-ai',
];

const tabs = {
  installations: {
    key: 'installations',
    display: true,
  },
  spaces: {
    key: 'spaces',
    display: true,
  },
  menu: {
    key: 'menu',
    display: true,
  },
};

const getAdjustedTabWidth = (): number | undefined => {
  return Object.keys(omitBy(tabs, (value) => !value.display)).length > 2 ? 55 : undefined;
};

const AppIcon = styled.div<{ src: string }>`
  width: 28px;
  height: 28px;
  margin: auto 10px auto 0;
  background: url(${(p) => p.src});
  display: block;
  background-size: contain;
  align-self: center;
  background-repeat: no-repeat;
  background-position: center;
`;

const OrganisationDetails = (props: OrganisationDetailsProps) => {
  const {
    match,
    location,
    organisation,
    organisationsList,
    fetchApps,
    appsAndGroupsLoaded,
    apps,
    user,
    installationGroups,
    spaces,
    tenantSettings,
  } = props;

  // SETTINGS
  const [logoUrl, setLogoUrl] = useState<string>('');

  // Check for logo once tenantSettings is fully loaded
  useEffect(() => {
    if (tenantSettings && tenantSettings.settings && tenantSettings.settings.logos) {
      const { squareLogo = {}, defaultLogo = {} } = tenantSettings.settings.logos;
      setLogoUrl(squareLogo.url || defaultLogo.url || 'fallback-url');
    } else {
      // logo setting not met
    }
  }, [tenantSettings]);

  // Support modal
  const [showSupport, setShowSupport] = useState(false);

  // Collapse menu
  const basePath = match.url;
  const relativePath = location.pathname.replace(basePath, '');

  const [collapseMenu, setCollapseMenu] = useState(true);
  useEffect(() => {
    // The pages that have sub menu
    const pathsToCollapse = [
      '/analytics',
      '/apps',
      '/content',
      '/operations',
      '/spaces',
      '/settings',
    ];

    // Check if any path matches the start of the relative path
    const shouldCollapse = pathsToCollapse.some((path) => relativePath.startsWith(path));

    if (shouldCollapse) {
      // console.log('Collapsing menu');
      setCollapseMenu(true);
    } else {
      // console.log('Expanding menu');
      setCollapseMenu(false);
    }
  }, [location.pathname, match.url]);

  const { t } = useTranslation();

  const toggleSupport = useCallback(() => {
    setShowSupport((prevValue) => !prevValue);
  }, []);

  const handleCopyTenantId = useCallback(() => {
    if (organisation) {
      copy(organisation.id);
      message.success(t('copyTenantId'));
    }
  }, [organisation, t]);

  const handleCopyTenantSlug = useCallback(() => {
    if (organisation) {
      copy(organisation.organizationName);
      message.success(t('copyTenantSlug'));
    }
  }, [organisation, t]);

  const handleCopyTenantName = useCallback(() => {
    if (organisation) {
      copy(organisation.displayName);
      message.success(t('copyTenantName'));
    }
  }, [organisation, t]);

  const [tabKey, setTabKey] = useState(tabs.installations.key);
  const [openedInstallationGroupsMenu, setOpenedInstallationGroupsMenu] = useState<
    string[]
  >([]);
  const previousOrganisationMenuPath = useRef<(string | boolean)[]>(['', '', false]);
  const { isAllowed } = usePermissions(match.params.organisationId);
  const history = useHistory();
  const organizationId = organisation ? organisation.id : '';

  const userSpacePermissions = useUserSpacePermissions(organizationId);

  const showInstallationsOverview = useMemo(
    () =>
      isAllowed(permissionKeys.apps.viewAll) ||
      isAllowed(permissionKeys.queues.viewAll) ||
      isAllowed(permissionKeys.queues.viewAnalytics),
    [isAllowed],
  );

  // Im not sure if this is the best way to do this?
  // But this is the top level menu, and how we decided if it is selected
  // Probably we can do something clever with paths/urls
  const routeMetadata = [
    {
      path: '/analytics',
      menuKey: `${match.url}/analytics`,
    },
    {
      path: '/apps',
      menuKey: `${match.url}/apps`,
    },
    {
      path: '/contacts',
      menuKey: `${match.url}/contacts`,
    },
    {
      path: '/content',
      menuKey: `${match.url}/content`,
    },
    {
      path: '/operations',
      menuKey: `${match.url}/operations`,
    },
    {
      path: '/spaces',
      menuKey: `${match.url}/spaces`,
    },
    {
      path: '/settings',
      menuKey: `${match.url}/settings`,
    },
  ];

  // Utility function to match paths
  function getMenuKeyFromPath(path: string): string | null {
    // Normalize the path by removing the '/organisations/:id' prefix if it exists
    const normalizedPath = path.replace(/\/organisations\/[^/]+/, '');

    // Split the normalized path into segments
    const pathSegments = normalizedPath.split('/').filter(Boolean);

    for (let i = pathSegments.length; i > 0; i--) {
      const partialPath = `/${pathSegments.slice(0, i).join('/')}`;
      const matchedRoute = routeMetadata.find((item) => {
        // console.log(`Checking if '${partialPath}' starts with '${item.path}'`);
        return partialPath.startsWith(item.path);
      });

      if (matchedRoute) {
        return matchedRoute.menuKey;
      }
    }

    return null;
  }

  // MAIN MENU keys
  const selectedMainMenuKeys = useMemo(() => {
    const matchedMenuKey = getMenuKeyFromPath(location.pathname);

    // console.log('Current pathname:', location.pathname);
    // console.log('Matched menu key:', matchedMenuKey);

    return matchedMenuKey ? [matchedMenuKey] : [];
  }, [location.pathname]);

  const selectedMenuItemKeys = useMemo(() => {
    const routeMatch = organisationPatternPaths
      .map((path) =>
        matchPath(location.pathname, {
          path: `${match.path}${path}`,
        }),
      )
      .find((match) => !!match);

    let matchedPathUrl = routeMatch ? routeMatch.url : null;

    if (location.pathname.includes('/operations/devices/v3/')) {
      matchedPathUrl = `${match.url}/operations/legacy-devices`;
    }

    return matchedPathUrl ? [matchedPathUrl] : [];
  }, [location.pathname, match.path]);

  const activeSpaceId = useMemo(() => {
    const matchedUrl = matchPath<{
      spaceId: string;
    }>(location.pathname, {
      path: `${match.path}${'/spaces/:spaceId'}`,
    });

    if (matchedUrl) {
      return matchedUrl.params.spaceId || null;
    }

    return null;
  }, [location.pathname, match.path]);

  const organisationMenu = useMemo(() => {
    if (organisation) {
      let menu = null;
      const canSwitchOrg = organisationsList.length > 1;
      if (canSwitchOrg) {
        menu = (
          <OrganisationList>
            <Menu
              theme="dark"
              style={{
                maxHeight: 'calc(100vh - 58px)',
                overflowY: 'scroll',
                background: '#262626',
              }}
            >
              {organisationsList.map((item) => {
                return (
                  <MenuItemSwitchOrg key={item.id}>
                    <Tooltip
                      placement="left"
                      title={item.displayName}
                      overlayClassName="tooltipSidebar"
                    >
                      <Link to={`/organisations/${item.id}`}>
                        <LabelSwitchOrg>
                          <span>{item.displayName}</span>
                        </LabelSwitchOrg>
                      </Link>
                    </Tooltip>
                  </MenuItemSwitchOrg>
                );
              })}
            </Menu>
          </OrganisationList>
        );
      }

      return (
        <OrganisationMenu>
          <Avatar shape="square" src={gridLogoIcon} size={30} />
          <button type="button" disabled={!appsAndGroupsLoaded}>
            <Tooltip
              placement="bottom"
              title={
                <TenantTooltipInfoContainer>
                  <TenantTooltipInfo onClick={handleCopyTenantName}>
                    {`${t('name')}: ${organisation.displayName}`}
                    <CopyIcon type="copy" />
                  </TenantTooltipInfo>
                  <TenantTooltipInfo onClick={handleCopyTenantId}>
                    {`${t('id')}: ${organisation.id}`}
                    <CopyIcon type="copy" />
                  </TenantTooltipInfo>
                  <TenantTooltipInfo onClick={handleCopyTenantSlug}>
                    {`${t('slug')}: ${organisation.organizationName}`}
                    <CopyIcon type="copy" />
                  </TenantTooltipInfo>
                </TenantTooltipInfoContainer>
              }
              overlayStyle={{
                width: 'fit-content',
                maxWidth: 'fit-content',
              }}
            >
              <LabelDisplayName>
                <Link to={`${match.url}/settings/branding`}>
                  {logoUrl ? (
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: '#eaeaea',
                        padding: '4px',
                      }}
                      src={logoUrl}
                      size={24}
                    />
                  ) : (
                    <Badge dot>
                      <Avatar
                        shape="square"
                        style={{
                          backgroundColor: '#87d068',
                        }}
                        icon="user"
                        size={24}
                      />
                    </Badge>
                  )}
                </Link>
                <span className="tenant-name">{organisation.displayName}</span>
              </LabelDisplayName>
            </Tooltip>
            {canSwitchOrg && (
              <OrganisationDropdown
                placement="bottomLeft"
                trigger={['click']}
                disabled={!canSwitchOrg}
                overlay={menu}
                overlayClassName="organisationSwitchList"
              >
                <Icon type="swap" />
              </OrganisationDropdown>
            )}
          </button>
        </OrganisationMenu>
      );
    }

    return null;
  }, [
    appsAndGroupsLoaded,
    organisation,
    organisationsList,
    handleCopyTenantId,
    handleCopyTenantName,
    handleCopyTenantSlug,
    t,
  ]);

  const groupedApps = useGroupAppsByInstallationGroups({
    apps,
    installationGroups,
  });

  const groupedSpacesTreeProps = useGroupedSpacesTreeProps({ spaces });

  // Google analytics
  useEffect(() => {
    if (organisation) {
      document.cookie = `tenant=${encodeURIComponent(
        organisation.organizationName,
      )}; expires=0; path=/`;
    }
  }, [organisation]);

  useEffect(() => {
    fetchApps({
      organizationId,
      silent: false,
    });
  }, [fetchApps, organizationId]);

  useEffect(() => {
    const currentUrlPath = get(selectedMenuItemKeys, 0, '').split('/');
    const currentMenuPath = get(currentUrlPath, 3);
    const currentMenuItemId = get(currentUrlPath, 4);

    if (!appsAndGroupsLoaded) {
      previousOrganisationMenuPath.current = [currentMenuPath, currentMenuItemId, true];
      return;
    }

    const [
      prevMenuPath,
      prevMenuItemId,
      prevLoadedFlag,
    ] = previousOrganisationMenuPath.current;
    if (
      (appsAndGroupsLoaded && prevLoadedFlag) ||
      (currentMenuPath &&
        prevMenuPath !== currentMenuPath &&
        prevMenuItemId !== currentMenuItemId)
    ) {
      let selectedInstallationGroupIds: string[] = [];
      const isInstallationPath = currentMenuPath === 'apps' && !!currentMenuItemId;

      if (isInstallationPath) {
        const activeInstallation = apps.find((app) => app.id === get(currentUrlPath, 4));

        if (activeInstallation) {
          selectedInstallationGroupIds = [
            activeInstallation.installationGroupId || noInstallationGroupKey,
          ];
        }
      } else {
        selectedInstallationGroupIds = groupedApps[0] ? [groupedApps[0].groupId] : [];
      }

      if (selectedInstallationGroupIds.length) {
        setOpenedInstallationGroupsMenu((prevValue) => [
          ...(!prevLoadedFlag ? prevValue : []),
          ...selectedInstallationGroupIds,
        ]);
      }

      if (
        currentMenuPath &&
        (prevLoadedFlag || prevMenuPath !== currentMenuPath) &&
        organisationMenuKeys.includes(currentMenuPath) &&
        !isInstallationPath
      ) {
        setTabKey(tabs.menu.key);
      } else if (isInstallationPath || prevLoadedFlag) {
        const key = get(tabs, `${currentMenuPath}.display`)
          ? currentMenuPath
          : tabs.installations.key;

        setTabKey(key);
      }
    }

    previousOrganisationMenuPath.current = [currentMenuPath, currentMenuItemId, false];
  }, [apps, selectedMenuItemKeys, groupedApps, appsAndGroupsLoaded]);

  const renderAppRedirect = useCallback(() => {
    if (!appsAndGroupsLoaded) {
      // Wait until apps data has loaded
      return <Spinner />;
    }

    if (organisation && match.params.organisationId === organisation.id) {
      let defaultPath = 'apps/installations';
      // If no apps, go to marketplace to get some
      if (apps.length === 0) {
        defaultPath = 'apps/marketplace';
      }

      return <Redirect to={`${match.url}/${defaultPath}`} />;
    }

    return null;
  }, [apps, appsAndGroupsLoaded, match.params.organisationId, match.url, organisation]);

  const getOrganisationPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organizationId, permissionKey),
    [organizationId],
  );

  const handleInstallationGroupMenuChange = useCallback((openKeys: string[]) => {
    setOpenedInstallationGroupsMenu(openKeys);
  }, []);

  const { analyticsQueryParams } = useAnalyticsParams();

  const handleSpaceSelect = useCallback(
    (id: string) => {
      history.push(`${match.url}/spaces/${id}${analyticsQueryParams}`);
    },
    [history, match, analyticsQueryParams],
  );

  if (!organisation) {
    return <ErrorView />;
  }

  return (
    <>
      <AppLayout.Navbar title={organisation.displayName} />
      <AppLayout>
        <SidebarWrapper>
          <AppLayout.Sidebar header={organisationMenu}>
            {!appsAndGroupsLoaded ? (
              <Overlay>
                <Spinner />
              </Overlay>
            ) : (
              <>
                {/* TODO: figure out how to feed theme to styled components, it's blacklisted prop */}
                <SidebarMenus>
                  <Sider width={72} trigger={null}>
                    <SidebarMenu
                      selectedKeys={selectedMainMenuKeys}
                      className="toplevelnav"
                    >
                      {(organisation.customOverviewDashboard ||
                        organisation.dataResidency) &&
                        isAllowed(permissionKeys.organizations.viewAllAnalytics) &&
                        userSpacePermissions.hasAccessToAllSpaces && (
                          <Menu.Item key={`${match.url}/analytics`}>
                            <MenuLink
                              to={`${match.url}/analytics${analyticsQueryParams}`}
                            >
                              <MenuIcon>
                                <Icon type="dashboard" />
                              </MenuIcon>
                              <span>{t('dashboard')}</span>
                            </MenuLink>
                          </Menu.Item>
                        )}
                      {isAllowed(permissionKeys.gridApps.viewAll) && (
                        <Menu.Item key={`${match.url}/apps`}>
                          <MenuLink to={`${match.url}/apps/installations`}>
                            <MenuIcon>
                              <Icon type="appstore" />
                            </MenuIcon>
                            <span>{t('apps')}</span>
                          </MenuLink>
                        </Menu.Item>
                      )}
                      {/*

                        HIDE CONTACTS FOR NOW?

                      {isAllowed(permissionKeys.contacts.viewAll) && (
                        <Menu.Item key={`${match.url}/contacts`}>
                          <Link to={`${match.url}/contacts`}>
                            <Icon type="contacts" />
                            <span>{t('tenantBarOptionsContacts')}</span>
                          </Link>
                        </Menu.Item>
                      )} */}
                      <Menu.Item
                        key={`${match.url}/content`}
                        data-testid="organisation-details-content-tab"
                      >
                        <MenuLink to={`${match.url}/content`}>
                          <MenuIcon>
                            <Icon type="bars" />
                          </MenuIcon>
                          <span>{t('content')}</span>
                        </MenuLink>
                      </Menu.Item>
                      <Menu.Item
                        key={`${match.url}/operations`}
                        data-testid="organisation-details-content-tab"
                      >
                        <MenuLink to={`${match.url}/operations/devices`}>
                          <MenuIcon>
                            <Icon type="code-o" />
                          </MenuIcon>
                          <span>{t('operations')}</span>
                        </MenuLink>
                      </Menu.Item>

                      <Menu.Item
                        key={`${match.url}/spaces`}
                        data-testid="organisation-details-content-tab"
                      >
                        <MenuLink to={`${match.url}/spaces`}>
                          <MenuIcon>
                            <Icon type="environment" />
                          </MenuIcon>
                          <span>{t('spaces')}</span>
                        </MenuLink>
                      </Menu.Item>

                      {/* We should move to NavBar, maybe */}
                      <Menu.Item
                        key="support"
                        style={{
                          marginTop: 'auto',
                        }}
                      >
                        <SupportButton
                          role="button"
                          tabIndex={0}
                          onClick={toggleSupport}
                          aria-hidden="true"
                        >
                          <MenuIcon>
                            <Icon type="question-circle" />
                          </MenuIcon>
                          <span>{t('support')}</span>
                        </SupportButton>
                      </Menu.Item>

                      <Menu.Item
                        key={`${match.url}/settings`}
                        data-testid="organisation-settings-content-tab"
                      >
                        <MenuLink
                          to={
                            isAllowed(permissionKeys.plans.viewAll)
                              ? `${match.url}/settings/plans`
                              : `${match.url}/settings/branding`
                          }
                        >
                          <MenuIcon>
                            <Icon type="setting" />
                          </MenuIcon>
                          <span>{t('settings')}</span>
                        </MenuLink>
                      </Menu.Item>

                      {/* Removed StoreAI for now */}
                      {/* {(!isProd() || (user && user.isSysadmin)) &&
                  isAllowed(permissionKeys.storeAIAssistants.viewAll) && (
                    <Menu.Item key={`${match.url}/store-ai`}>
                      <Link to={`${match.url}/store-ai`}>
                        <Icon src={sparkleIcon} size={14} />
                        {t('storeAI.title')}
                      </Link>
                    </Menu.Item>
                  )} */}
                    </SidebarMenu>
                  </Sider>
                  {/* SUB MENUS */}
                  {collapseMenu && (
                    <SubMenu>
                      {/* ANALYTICS MENU */}
                      {relativePath.startsWith('/analytics') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="dashboard" size={12} /> {t('dashboard')}
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            {(organisation.customOverviewDashboard ||
                              organisation.dataResidency) &&
                              isAllowed(permissionKeys.organizations.viewAllAnalytics) &&
                              userSpacePermissions.hasAccessToAllSpaces && (
                                <Menu.Item key={`${match.url}/analytics`}>
                                  <Link
                                    to={`${match.url}/analytics${analyticsQueryParams}`}
                                  >
                                    <Icon type="dashboard" />
                                    <span>Overview</span>
                                  </Link>
                                </Menu.Item>
                              )}
                            {isAllowed(permissionKeys.analyticsExportJob.viewAll) && (
                              <Menu.Item key={`${match.url}/analytics/data-export`}>
                                <Link to={`${match.url}/analytics/data-export`}>
                                  <Icon type="download" />
                                  <span>{t('dataExport.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                          </Menu>
                        </>
                      )}

                      {/* APPS MENU */}
                      {relativePath.startsWith('/apps') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="appstore" size={12} /> Apps
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            <Menu.Item key={`${match.url}/apps/installations`}>
                              <Link to={`${match.url}/apps/installations`}>
                                <Icon type="appstore" />
                                <span>{t('installations')}</span>
                              </Link>
                            </Menu.Item>
                            {isAllowed(permissionKeys.gridApps.viewAll) && (
                              <Menu.Item key={`${match.url}/apps/marketplace`}>
                                <Link to={`${match.url}/apps/marketplace`}>
                                  <Icon type="shopping-cart" />
                                  <span>{t('marketplace')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.developerPAT.create) && (
                              <Menu.Item key={`${match.url}/apps/developer`}>
                                <Link to={`${match.url}/apps/developer`}>
                                  <Icon type="code" />
                                  <span>{t('developer')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                          </Menu>
                        </>
                      )}

                      {/* CONTENT MENU */}
                      {relativePath.startsWith('/content') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="picture" size={12} /> Content
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            <Menu.Item
                              key={`${match.url}/content/library`}
                              data-testid="organisation-details-content-tab"
                            >
                              <Link to={`${match.url}/content`}>
                                <Icon type="folder-open" />
                                <span>{t('library')}</span>
                              </Link>
                            </Menu.Item>
                            <Menu.Item
                              key={`${match.url}/content/channels`}
                              data-testid="organisation-details-channels-tab"
                            >
                              <Link to={`${match.url}/content/channels`}>
                                <Icon type="video-camera" />
                                <span>{t('contents.channels')}</span>
                              </Link>
                            </Menu.Item>
                            <Menu.Item
                              key={`${match.url}/content/tags`}
                              data-testid="organisation-details-tags-tab"
                            >
                              <Link to={`${match.url}/content/tags`}>
                                <Icon type="tags-o" />
                                <span>{t('tags')}</span>
                              </Link>
                            </Menu.Item>
                            {isAllowed(permissionKeys.gridProducts.viewAll) && (
                              <Menu.Item key={`${match.url}/content/grid-products`}>
                                <Link
                                  to={`${match.url}/content/grid-products`}
                                  data-testid="organisation-details-products-tab"
                                >
                                  <Icon type="shop" />
                                  <span>{t('gridProducts.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.customShortUrls.viewAll) && (
                              <Menu.Item key={`${match.url}/content/custom-short-urls`}>
                                <Link to={`${match.url}/content/custom-short-urls`}>
                                  <Icon type="qrcode" />
                                  <span>{t('customShortUrls')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                          </Menu>
                        </>
                      )}

                      {/* OPERATIONS MENU */}
                      {relativePath.startsWith('/operations') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="code-o" size={12} /> Operations
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            {isAllowed(permissionKeys.devices.viewAll) && (
                              <Menu.Item key={`${match.url}/operations/devices`}>
                                <Link to={`${match.url}/operations/devices`}>
                                  <Icon type="robot" />
                                  <span>{t('devices')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.devices.viewAll) && (
                              <Menu.Item key={`${match.url}/operations/legacy-devices`}>
                                <Link to={`${match.url}/operations/legacy-devices`}>
                                  <Icon type="warning" />
                                  <span>{t('universalDevices')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.environments.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/operations/environments`}
                                data-testid="organisation-details-environments-tab"
                              >
                                <Link to={`${match.url}/operations/environments`}>
                                  <Icon type="cloud" />
                                  <span>{t('environments')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.alerts.viewAll) && (
                              <Menu.Item key={`${match.url}/operations/alerts`}>
                                <Link
                                  to={`${match.url}/operations/alerts`}
                                  data-testid="organisation-details-alerts-tab"
                                >
                                  <Icon component={() => <Bell size={14} />} />
                                  <span>{t('alerts.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}

                            {isAllowed(permissionKeys.networkWhitelistRules.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/operations/networking`}
                                data-testid="organisation-details-networking-tab"
                              >
                                <Link to={`${match.url}/operations/networking`}>
                                  <Icon type="apartment" />
                                  <span>{t('networking.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                          </Menu>
                        </>
                      )}

                      {/* SPACES MENU */}
                      {relativePath.startsWith('/spaces') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="environment" size={12} /> Spaces
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            {isAllowed(permissionKeys.spaces.create) && (
                              <Link to={`${match.url}/spaces/new`}>
                                <AddLocationButton icon="plus">
                                  {t('createSpace')}
                                </AddLocationButton>
                              </Link>
                            )}
                            <TreeWrapper>
                              <Tree
                                tree={groupedSpacesTreeProps || []}
                                onSelect={handleSpaceSelect}
                                activeId={activeSpaceId}
                              />
                            </TreeWrapper>
                          </Menu>
                        </>
                      )}

                      {/* SETTINGS MENU */}
                      {relativePath.startsWith('/settings') && (
                        <>
                          <SubMenuTitle>
                            <Icon type="setting" size={12} /> Settings
                          </SubMenuTitle>
                          <Menu selectedKeys={selectedMenuItemKeys} theme="dark">
                            {isAllowed(permissionKeys.plans.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/settings/plans`}
                                data-testid="organisation-details-plan-tab"
                              >
                                <Link to={`${match.url}/settings/plans`}>
                                  <Icon type="file-text" />
                                  <span>{t('plans.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.subscriptions.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/settings/billing`}
                                data-testid="organisation-details-billing-tab"
                              >
                                <Link to={`${match.url}/settings/billing`}>
                                  <Icon type="credit-card" />
                                  <span>{t('billing.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                            {isAllowed(permissionKeys.organizations.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/settings/branding`}
                                data-testid="organisation-details-branding-tab"
                              >
                                <Link to={`${match.url}/settings/branding`}>
                                  <Icon component={() => <Tool size={14} />} />
                                  <span>{t('branding.title')}</span>
                                </Link>
                              </Menu.Item>
                            )}

                            {organisation.isPartnerOrganization &&
                              !organisation.parentOrganizationId &&
                              isAllowed(permissionKeys.organizations.viewAll) && (
                                <Menu.Item
                                  key={`${match.url}/settings/managed-tenants`}
                                  data-testid="organisation-details-managed-tenants-tab"
                                >
                                  <Link to={`${match.url}/settings/managed-tenants`}>
                                    <Icon type="solution" />
                                    <span>{t('managedTenants')}</span>
                                  </Link>
                                </Menu.Item>
                              )}

                            {isAllowed(permissionKeys.users.viewAll) && (
                              <Menu.Item
                                key={`${match.url}/settings/user-management`}
                                data-testid="organisation-details-user-management-tab"
                              >
                                <Link to={`${match.url}/settings/user-management`}>
                                  <Icon type="user" />
                                  <span>{t('userManagement.userManagementHeading')}</span>
                                </Link>
                              </Menu.Item>
                            )}
                          </Menu>
                        </>
                      )}
                    </SubMenu>
                  )}
                </SidebarMenus>
              </>
            )}
          </AppLayout.Sidebar>
        </SidebarWrapper>
        <AppLayout.Content>
          {appsAndGroupsLoaded && (
            <Switch>
              <Route exact path={match.path} render={renderAppRedirect} />

              {/* RENDER APPS */}
              <ProtectedRouteContainer
                path={`${match.path}/apps/installations/:appId`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewSingle,
                )}
                component={AppDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/operations/devices/phyhub-devices/:deviceUuid`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewSingle,
                )}
                component={OrganizationPhyhubDeviceDetailsWrapperContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/devices`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewAll,
                )}
                component={OrganisationPhyhubDevicesContainer}
              />
              <Route
                path={`${match.url}/apps/installations`}
                render={() => (
                  <>
                    <Header title={t('installations')} />
                    <div
                      style={{
                        display: 'block',
                        padding: '20px 40px',
                      }}
                    >
                      <InstallationsLayout>
                        {groupedApps.map((appsGroup: any) => (
                          <Card
                            key={appsGroup.groupId}
                            title={
                              <span>
                                {appsGroup.groupName} ({appsGroup.apps.length || '0'})
                              </span>
                            }
                            extra={
                              isAllowed(permissionKeys.apps.create) &&
                              appsGroup.groupId !== noInstallationGroupKey && (
                                <Link
                                  to={`${match.url}/apps/installation-group/${appsGroup.groupId}`}
                                  style={{
                                    marginLeft: '10px',
                                  }}
                                >
                                  <Icon type="edit" />
                                </Link>
                              )
                            }
                          >
                            {/* Apps in the group */}
                            <InstallationGroupList>
                              {appsGroup.apps.map((app: OrganisationApp) => (
                                <InstallationGroupListItem key={app.id} style={{}}>
                                  <Link
                                    to={`${match.url}/apps/installations/${app.id}${analyticsQueryParams}`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <AppIcon src={getInstallationAppIcon(app)} />
                                    <LabelInstallation>
                                      <span title={app.appName}>{app.displayName}</span>
                                    </LabelInstallation>
                                    <Icon type={getGridappTypeIcon(app.deviceType)} />
                                  </Link>
                                </InstallationGroupListItem>
                              ))}
                            </InstallationGroupList>
                          </Card>
                        ))}
                      </InstallationsLayout>
                    </div>
                  </>
                )}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.gridApps.create,
                )}
                path={`${match.path}/apps/developer`}
                component={DevelopersSection}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.marketplace.viewAll,
                )}
                path={`${match.path}/apps/marketplace`}
                component={Marketplace}
              />

              <ProtectedRouteContainer
                exact
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewAll,
                )}
                path={`${match.path}/apps`}
                component={AppsListContainer}
              />

              {/* RENDER ANALYTICS */}
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.analyticsExportJob.viewAll,
                )}
                path={`${match.path}/analytics/data-export`}
                component={DataExport}
              />
              {(organisation.customOverviewDashboard || organisation.dataResidency) && (
                <ProtectedRouteContainer
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.organizations.viewAllAnalytics,
                  )}
                  path={`${match.path}/analytics`}
                  component={OrganisationOverviewContainer}
                />
              )}

              {/* RENDER CONTENT */}
              <ProtectedRouteContainer
                // TO DO: Create a separate settings permission
                //  For now, we'll use integrations bec there are no other submenus under settings
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.integrations.viewAll,
                )}
                path={`${match.path}/content/grid-products/integrations`}
                component={Settings}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.gridProducts.viewAll,
                )}
                path={`${match.path}/content/grid-products`}
                component={GridProducts}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.customShortUrls.viewAll,
                )}
                path={`${match.path}/content/custom-short-urls`}
                component={OrganisationCustomShortUrlsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/content`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.media.viewAll,
                )}
                component={ContentContainer}
              />

              {/* RENDER DEVCES */}
              <ProtectedRouteContainer
                path={`${match.path}/operations/devices/v3/:deviceUuid`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewSingle,
                )}
                component={UniversalDeviceDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/operations/legacy-devices`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewAll,
                )}
                component={DevicesUniversalListContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/operations/devices`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewAll,
                )}
                component={OrganisationPhyhubDevicesContainer}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.create,
                )}
                path={`${match.path}/spaces/new`}
                component={SpaceFormAdd}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/spaces/:spaceId`}
                component={SpacesContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/users/new`}
                component={UserFormFields}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/spaces`}
                component={OrganisationSpaceContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewAll,
                )}
                path={`${match.path}/apps/installation-group/:groupId`}
                component={InstallationGroupContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/library/:selectedFolderId?`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.media.viewAll,
                )}
                component={OrganisationMediaLibraryContainer}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.environments.viewAll,
                )}
                path={`${match.path}/operations/environments`}
                component={EnvironmentsListContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.organizations.viewAll,
                )}
                path={`${match.path}/settings/managed-tenants`}
                component={OrganisationChildrenListContainer}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.userRoles.viewAll,
                )}
                path={`${match.path}/settings/user-management`}
                component={UserManagementContainer}
              />

              {(!isProd() || (user && user.isSysadmin)) && (
                <ProtectedRouteContainer
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.storeAIAssistants.viewAll,
                  )}
                  path={`${match.path}/store-ai`}
                  component={StoreAI}
                />
              )}
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.contacts.viewAll,
                )}
                path={`${match.path}/contacts`}
                component={Contacts}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.plans.viewAll,
                )}
                path={`${match.path}/settings/plans`}
                component={Plans}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.subscriptions.viewAll,
                )}
                path={`${match.path}/settings/billing`}
                component={Billing}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.organizations.viewAll,
                )}
                path={`${match.path}/settings/branding`}
                component={Branding}
              />

              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.alerts.viewAll,
                )}
                path={`${match.path}/operations/alerts`}
                component={Alerts}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.networkWhitelistRules.viewAll,
                )}
                path={`${match.path}/operations/networking`}
                component={Networking}
              />
            </Switch>
          )}
        </AppLayout.Content>
        <AppLayout.Footer />
      </AppLayout>
      <Modal
        title="Help & Support"
        visible={showSupport}
        onOk={toggleSupport}
        onCancel={toggleSupport}
        footer={null}
      >
        <SupportContent>
          <img
            src="https://media.giphy.com/media/5hgMcnAkBVcARGsGWu/giphy.gif"
            alt="Help gif"
          />
          <p>
            The fastest way to speak to someone who can help is on the{' '}
            <strong>Phygrid Slack</strong>
          </p>
          <Button
            size="large"
            type="primary"
            href="https://join.slack.com/t/slack-pgo5586/shared_invite/zt-s1ajca83-k8i1f2mqgCMD0vDfpCk4Bg"
            target="_blank"
          >
            Join our Slack community
          </Button>
          <p>
            Or{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://phygrid.com/contact"
            >
              Contact Phygrid
            </a>
          </p>
        </SupportContent>
      </Modal>
    </>
  );
};

const InstallationGroupList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const InstallationGroupListItem = styled.li`
  display: block;
  color: #222;

  .anticon {
    color: #888;
  }

  a {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 8px;

    &:hover {
      background: #eee;
    }
  }
`;

const TreeWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  display: block;
  overflow: hidden;
  border-top: 1px solid #000;
`;

const SubMenuTitle = styled.span`
  font-size: 10px;
  line-height: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  /* color: #8c8c8c; */
  color: #fff;
  padding: 8px 14px;
  background: #202020;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: -0.5px;
`;

const SubMenu = styled.div`
  flex: 1 100%;
  height: calc(100vh - 58px);
  background: #262626;
  overflow-y: auto;

  .ant-menu-dark {
    padding: 5px;
    background: #262626 !important;
  }
`;

const SidebarWrapper = styled.div`
  margin-top: -55px;
  /* @media (max-width: 990px) {
    display: none;
  } */
`;

const MenuIcon = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-bottom: 4px;
`;
const MenuLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  padding: 4px 0 16px 0;
`;

const SidebarMenus = styled.div`
  width: 100%;
  display: flex;

  .toplevelnav {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 12px 0 0 0 !important;
    border-right: 1px solid #000;

    .ant-menu-item[role='menuitem'] {
      padding: 0 !important;
      margin: 0;
    }

    .ant-menu-item {
      &-active &-hover,
      &:hover {
        background: none !important;

        ${MenuIcon} {
          background: rgba(255, 255, 255, 0.1);
        }
      }
      &-selected,
      &-selected &-active {
        background: none;

        ${MenuIcon} {
          background-color: #2364aa;
        }
      }

      width: 72px;
      height: auto;
      line-height: 1;

      ${MenuLink} {
        width: 72px;
        display: flex;
        flex-direction: column;

        svg,
        .anticon {
          margin: auto;
        }
      }
    }

    .ant-menu-item-selected.ant-menu-item-active {
      ${MenuIcon} {
        background-color: #2364aa;
      }
    }
  }
`;

const SupportButton = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 11px;
  padding-bottom: 16px;

  span {
    margin-left: auto;
    margin-right: auto;
    .anticon {
      margin: auto !important;
    }
  }
`;

const SupportContent = styled.div`
  display: block;
  text-align: center;

  img {
    max-width: 320px;
  }

  p {
    max-width: 320px;
    margin: 24px auto;
  }
`;

const OrganisationMenu = styled.div`
  width: 320px;
  display: flex;
  border-bottom: solid 1px #000;
  height: 55px;
  line-height: 52px;
  align-items: center;

  .ant-avatar {
    margin-left: 24px;
    margin-right: 4px;
  }
  > button {
    width: 100%;
    height: 55px;
    text-align: left;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #fff;
    background-color: #202020;
    margin-left: 5px;
    display: flex;
    align-items: center;

    > i {
      height: 40px;
      width: 40px;
      line-height: 45px;
    }
    > i:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
`;

const OrganisationList = styled.div`
  .ant-dropdown {
    top: 55px;
    left: 0px;
    width: 320px;
  }
`;

const OrganisationDropdown = styled(Dropdown)`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  height: 50px;
`;

const ClipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 195px;
  vertical-align: middle;
  height: 100%;
  padding: 11px 0;

  .tenant-name {
    overflow: hidden;
    line-height: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    -webkit-box-orient: vertical;
  }
`;

const LabelDisplayName = styled(ClipText)`
  padding: 4px 6px;
  margin-left: 10px;
  color: #fff;
  height: unset;
  display: flex;
  gap: 8px;
  border-radius: 6px;
  width: 100%;

  .ant-avatar {
    margin: auto;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  a {
    display: flex;
    color: #fff;
    align-items: center;
  }
`;

const TenantTooltipInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const TenantTooltipInfo = styled(ClipText)`
  color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  width: 100%;
`;

const CopyIcon = styled(Icon)`
  margin-left: 4px;
  cursor: pointer;
`;

const LabelInstallation = styled(ClipText)`
  width: 100%;
  color: #444;
`;

const MenuItemSwitchOrg = styled(Menu.Item)`
  white-space: normal;
  height: fit-content !important;
`;

const LabelSwitchOrg = styled(ClipText)`
  margin-left: 0px;
  width: 260px !important;
`;

const AddLocationButton = styled(Button)`
  background: transparent;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  color: #1890ff;
  align-items: center;
  border: none;

  :hover,
  :active,
  :focus {
    text-decoration: none;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }

  i {
    margin-top: 2px;
  }
` as any;

export default OrganisationDetails;

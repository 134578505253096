import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FilterItem, { FilterOption } from '../../../../../../store/types/filters';
import OrganisationSpace from '../../../../../../store/types/organisation-space';
import {
  PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP,
  PHYHUB_DEVICE_STATUS_FILTERS,
  PHYHUB_DEVICE_STATUS_TRANSLATION_MAP,
} from '../../../../../../store/types/phyhub-devices';
import Environment from '../../../../../../store/types/environment';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import usePhyhubDevicesGetFilters from '../../../../../../store/hooks/phyhub-devices/use-phyhub-devices-get-filters';
import { PhyhubDeviceDynamicFilterType } from '../../../../../../services/phyhub/types/phyhub-device-dynamic-filters.types';
import { PhyhubDeviceListParamsFilters } from '../../../../../../services/phyhub/types/phyhub-device-list-params.interface';

export const PHYHUB_DEVICE_FILTERS_MAP = {
  APP: 'apps',
  DEVICE_ARCHITECTURE: 'architectures',
  ENVIRONMENT: 'environments',
  INSTALLATION_ID: 'installationIds',
  SPACE_ID: 'spaceIds',
  STATUS: 'statuses',
};

const PHYHUB_DEVICE_DYNAMIC_FILTERS_MAP: Record<
  PhyhubDeviceDynamicFilterType,
  {
    labelKey: string;
    slug: string;
  }
> = {
  App: {
    labelKey: 'filterDevice.app',
    slug: PHYHUB_DEVICE_FILTERS_MAP.APP,
  },
};

interface UsePhyhubDeviceFiltersParams {
  tenantId: string;
  filterParams?: PhyhubDeviceListParamsFilters;
  environments?: Environment[];
  installations?: OrganisationApp[];
  spaces?: OrganisationSpace[];
}

interface UsePhyhubDeviceFiltersReturn {
  filterOptions: FilterItem[];
}

export default function usePhyhubDeviceFilters(
  params: UsePhyhubDeviceFiltersParams,
): UsePhyhubDeviceFiltersReturn {
  const { tenantId, filterParams } = params;

  const { environments = [], installations = [], spaces = [] } = params;

  const { t } = useTranslation();

  const { data: phyhubDeviceDynamicFilters = [] } = usePhyhubDevicesGetFilters({
    tenantId,
    filterParams,
  });

  const dynamicFilterItems = useMemo<FilterItem[]>(() => {
    const dynamicFilters: FilterItem[] = [];

    phyhubDeviceDynamicFilters.forEach((dynamicFilter) => {
      const filter = PHYHUB_DEVICE_DYNAMIC_FILTERS_MAP[dynamicFilter.type];

      if (filter) {
        dynamicFilters.push({
          id: filter.slug,
          label: t(filter.labelKey),
          options: dynamicFilter.values.map((filterValue) => ({
            id: filterValue,
            label: filterValue,
          })),
        });
      }
    });

    return dynamicFilters;
  }, [phyhubDeviceDynamicFilters, t]);

  const architectureOptions = useMemo<FilterOption[]>(
    () =>
      Object.entries(PHYHUB_DEVICE_ARCHITECTURE_NAMES_MAP).map(
        ([key, translationKey]) => ({
          id: key,
          label: t(translationKey),
        }),
      ),
    [t],
  );

  const environmentOptions = useMemo<FilterOption[]>(
    () =>
      environments.map((environment) => ({
        id: environment.environmentName,
        label: environment.displayName,
      })),
    [environments],
  );

  const installationOptions = useMemo<FilterOption[]>(
    () =>
      installations.map((installation) => ({
        id: installation.id,
        label: installation.displayName,
      })),
    [installations],
  );

  const spaceOptions = useMemo<FilterOption[]>(
    () =>
      spaces.map((space) => ({
        id: space.id,
        label: space.displayName,
      })),
    [spaces],
  );

  const statusOptions = useMemo<FilterOption[]>(
    () =>
      PHYHUB_DEVICE_STATUS_FILTERS.map((status) => ({
        id: status,
        label: t(PHYHUB_DEVICE_STATUS_TRANSLATION_MAP[status]),
      })),
    [t],
  );

  const filterOptions = useMemo<FilterItem[]>(() => {
    const items: FilterItem[] = [...dynamicFilterItems];

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.DEVICE_ARCHITECTURE,
      label: t('filterDevice.architecture'),
      options: architectureOptions,
    });

    items.push({
      id: PHYHUB_DEVICE_FILTERS_MAP.STATUS,
      label: t('filterDevice.status'),
      options: statusOptions,
    });

    if (environmentOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.ENVIRONMENT,
        label: t('filterDevice.environment'),
        options: environmentOptions,
      });
    }

    if (installationOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.INSTALLATION_ID,
        label: t('filterDevice.installation'),
        options: installationOptions,
      });
    }

    if (spaceOptions.length) {
      items.push({
        id: PHYHUB_DEVICE_FILTERS_MAP.SPACE_ID,
        label: t('filterDevice.space'),
        options: spaceOptions,
      });
    }

    return items;
  }, [
    architectureOptions,
    dynamicFilterItems,
    environmentOptions,
    installationOptions,
    spaceOptions,
    statusOptions,
    t,
  ]);

  return { filterOptions };
}

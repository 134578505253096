import React, { useMemo, ReactNode } from 'react';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';
import { DataResidencyEnum } from '../../../../store/types/organisation';
import {
  useTenantNpsByDay,
  useInstallationNpsByDay,
  useSpaceNpsByDay,
  useDeviceNpsByDay,
  useAppNpsByDay,
} from '../../use-analytics-report';
import ListChart from '../charts/list';
import CardContainer from './card-container';
import { GridStyles } from '@ombori/grid-reports';
import { getPrecedingPeriod } from './preceding-period-data';
import { Tooltip, Cell, PieChart, Pie } from 'recharts';
import numeral from 'numeral';
import LabeledValue from '../charts/labeled-value.component';
import styled from '@emotion/styled';

const title = 'NPS Responses';

const buildDataItem = (
  label: string,
  data: { label: string; value: number }[],
): [string, { label: string; value: number }[]] => [label, data];

const buildData = (score: number, replyCount: number) => {
  const result = [
    buildDataItem('NPS', [{ label: 'NPS', value: round(score, 2) }]),
    buildDataItem('Number of replies', [
      { label: 'Number of replies', value: replyCount },
    ]),
  ];

  return result;
};

const sort = ([a]: [string, number], [b]: [string, number]) => a.localeCompare(b);

interface NpsProps {
  tenantId: string;
  dateFrom: string;
  dateTo: string;
  dataResidency: DataResidencyEnum;
  gridStyles?: GridStyles;
  isVisibleWithoutData?: boolean;
}

// This code below builds the NPS gauge
//////////////////////////////////////

const RADIAN = Math.PI / 180;

interface DataEntry {
  name: string;
  value: number;
  color: string;
}

const data: DataEntry[] = [
  { name: 'Needs Improvement (-100 to 0)', value: 100, color: '#FF7875' }, // -100 to 0
  { name: 'Good (0 to 30)', value: 30, color: '#FFC069' }, // 0 to 30
  { name: 'Great (30 to 70)', value: 40, color: '#95DE64' }, // 30 to 70
  { name: 'Excellent (70 to 100)', value: 30, color: '#73D13D' }, // 70 to 100
];

const cx = 200; // Center x-coordinate
const cy = 200; // Center y-coordinate
const iR = 70; // Inner radius
const oR = 140; // Outer radius

const needle = (
  value: number,
  cx: number,
  cy: number,
  iR: number,
  oR: number,
  color: string,
): ReactNode[] => {
  const total = 200; // Full range (-100 to 100)

  // Map the value proportionally to the 180° to 0° range
  const needleAngle = 180 - (180 * (value + 105)) / total;

  // Calculate needle tip and base positions
  const length = (iR + oR) / 2; // Needle length
  const sin = Math.sin(-RADIAN * needleAngle); // X-component
  const cos = Math.cos(-RADIAN * needleAngle); // Y-component
  const r = 5; // Needle base radius

  const x0 = cx; // Center X
  const y0 = cy; // Center Y
  const xba = x0 + r * sin; // Needle base X1
  const yba = y0 - r * cos; // Needle base Y1
  const xbb = x0 - r * sin; // Needle base X2
  const ybb = y0 + r * cos; // Needle base Y2
  const xp = x0 + length * cos; // Needle tip X
  const yp = y0 + length * sin; // Needle tip Y

  return [
    <circle key="needle-base" cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      key="needle-path"
      d={`M${xba} ${yba} L${xbb} ${ybb} L${xp} ${yp} Z`}
      fill={color}
      stroke="none"
    />,
  ];
};

const getNpsMessage = (score: number): string => {
  let message = '';

  switch (true) {
    case score >= 70:
      message = `Excellent`;
      break;
    case score >= 30 && score < 70:
      message = `Great`;
      break;
    case score >= 0 && score < 30:
      message = `Good`;
      break;
    case score < 0:
      message = `Needs Improvement`;
      break;
    default:
      message = `Unknown Score`;
  }

  return message;
};

export const TenantNps: React.FC<NpsProps> = ({
  tenantId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useTenantNpsByDay({
    tenantId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useTenantNpsByDay({
    tenantId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  // THIS IS THE KIND OF DATA I WAS ORIGINALLY LOOKING FOR
  // Do we have a database of actual responses from customers?

  // const barData = [
  //   { emoji: '😡', value: 120, color: '#FF7875' },
  //   { emoji: '😟', value: 80, color: '#FF7875' },
  //   { emoji: '😕', value: 150, color: '#FFC069' },
  //   { emoji: '🙂', value: 200, color: '#FFC069' },
  //   { emoji: '🤩', value: 300, color: '#95DE64' },
  // ];
  // const pieData = [
  //   { category: 'Promoters', value: 300, color: '#81C784' },
  //   { category: 'Passives', value: 350, color: '#FFD54F' },
  //   { category: 'Detractors', value: 200, color: '#E57373' },
  // ];

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <Parameter>
          <LabeledValue
            size="md"
            label=""
            value={numeral(npsFetchingState.data.replyCount).format('0.0a')}
          />
        </Parameter>
      )}

      {/* <ResponsiveContainer width="100%" height={300}>
        <BarChart data={barData} margin={{ top: -20, right: 0, left: 10, bottom: 0 }}>
          <XAxis dataKey="emoji" stroke="#ccc" />
          <YAxis
            width={40}
            orientation="right"
            tickFormatter={(value) => numeral(value).format('0a')}
            allowDecimals={false}
            tick={{ fontSize: 12, fill: '#666' }}
            stroke="#ccc"
          />
          <Tooltip
            cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
            contentStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderRadius: 4,
              border: '1px solid #ccc',
            }}
          />
          <Bar dataKey="value" fill="#82ca9d" radius={[4, 4, 0, 0]}>
            {barData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer> */}

      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <>
          <ChartWrapper>
            <PieChart width={400} height={200}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(
                  value: string | number | (string | number)[],
                  name: string,
                ) => {
                  const displayValue = Array.isArray(value) ? value.join(', ') : value;
                  return [name];
                }}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 4,
                  border: '1px solid #ccc',
                }}
              />
              {needle(npsFetchingState.data.score, cx, cy, iR, oR, 'rgba(0,0,0,0.8)')}{' '}
            </PieChart>
            <ScoreWrapper>
              <LabeledValue
                size="md"
                label="NPS Score"
                value={`${numeral(npsFetchingState.data.score).format(
                  '0a',
                )} - ${getNpsMessage(npsFetchingState.data.score)}`}
              />
            </ScoreWrapper>
          </ChartWrapper>
          <NpsDescription>
            Net Promoter Score (NPS) is the gold standard of customer experience metrics.
            The global average NPS is 32.
          </NpsDescription>
        </>
      )}

      {/* <PieChart width={400} height={400}>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend verticalAlign="bottom" height={36} />
      </PieChart> */}

      {/* {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )} */}
    </CardContainer>
  );
};

interface InstallationNpsProps extends NpsProps {
  installationId: string;
}

export const InstallationNps: React.FC<InstallationNpsProps> = ({
  tenantId,
  installationId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useInstallationNpsByDay({
    tenantId,
    installationId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useInstallationNpsByDay({
    tenantId,
    installationId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <>
          <ChartWrapper>
            <PieChart width={400} height={200}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(
                  value: string | number | (string | number)[],
                  name: string,
                ) => {
                  const displayValue = Array.isArray(value) ? value.join(', ') : value;
                  return [name];
                }}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 4,
                  border: '1px solid #ccc',
                }}
              />
              {needle(npsFetchingState.data.score, cx, cy, iR, oR, 'rgba(0,0,0,0.8)')}{' '}
            </PieChart>
            <ScoreWrapper>
              <LabeledValue
                size="md"
                label="NPS Score"
                value={`${numeral(npsFetchingState.data.score).format(
                  '0a',
                )} - ${getNpsMessage(npsFetchingState.data.score)}`}
              />
            </ScoreWrapper>
          </ChartWrapper>
          <NpsDescription>
            Net Promoter Score (NPS) is the gold standard of customer experience metrics.
            The global average NPS is 32.
          </NpsDescription>
        </>
      )}
    </CardContainer>
  );
};

interface SpaceNpsProps extends NpsProps {
  spaceId: string;
}

export const SpaceNps: React.FC<SpaceNpsProps> = ({
  tenantId,
  spaceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useSpaceNpsByDay({
    tenantId,
    spaceId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useSpaceNpsByDay({
    tenantId,
    spaceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <>
          <ChartWrapper>
            <PieChart width={400} height={200}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(
                  value: string | number | (string | number)[],
                  name: string,
                ) => {
                  const displayValue = Array.isArray(value) ? value.join(', ') : value;
                  return [name];
                }}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 4,
                  border: '1px solid #ccc',
                }}
              />
              {needle(npsFetchingState.data.score, cx, cy, iR, oR, 'rgba(0,0,0,0.8)')}{' '}
            </PieChart>
            <ScoreWrapper>
              <LabeledValue
                size="md"
                label="NPS Score"
                value={`${numeral(npsFetchingState.data.score).format(
                  '0a',
                )} - ${getNpsMessage(npsFetchingState.data.score)}`}
              />
            </ScoreWrapper>
          </ChartWrapper>
          <NpsDescription>
            Net Promoter Score (NPS) is the gold standard of customer experience metrics.
            The global average NPS is 32.
          </NpsDescription>
        </>
      )}
    </CardContainer>
  );
};

interface DeviceNpsProps extends NpsProps {
  deviceId: string;
}

export const DeviceNps: React.FC<DeviceNpsProps> = ({
  tenantId,
  deviceId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useDeviceNpsByDay({
    tenantId,
    deviceId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useDeviceNpsByDay({
    tenantId,
    deviceId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <>
          <ChartWrapper>
            <PieChart width={400} height={200}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                formatter={(
                  value: string | number | (string | number)[],
                  name: string,
                ) => {
                  const displayValue = Array.isArray(value) ? value.join(', ') : value;
                  return [name];
                }}
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 4,
                  border: '1px solid #ccc',
                }}
              />
              {needle(npsFetchingState.data.score, cx, cy, iR, oR, 'rgba(0,0,0,0.8)')}{' '}
            </PieChart>
            <ScoreWrapper>
              <LabeledValue
                size="md"
                label="NPS Score"
                value={`${numeral(npsFetchingState.data.score).format(
                  '0a',
                )} - ${getNpsMessage(npsFetchingState.data.score)}`}
              />
            </ScoreWrapper>
          </ChartWrapper>
          <NpsDescription>
            Net Promoter Score (NPS) is the gold standard of customer experience metrics.
            The global average NPS is 32.
          </NpsDescription>
        </>
      )}
    </CardContainer>
  );
};

interface AppNpsProps extends NpsProps {
  appId: string;
}

export const AppNps: React.FC<AppNpsProps> = ({
  tenantId,
  appId,
  dateFrom,
  dateTo,
  dataResidency,
  gridStyles,
  isVisibleWithoutData,
}) => {
  const { t } = useTranslation();

  const { dateFrom: precedingDateFrom, dateTo: precedingDateTo } = getPrecedingPeriod(
    dateFrom,
    dateTo,
  );

  const precedingNpsFetchingState = useAppNpsByDay({
    tenantId,
    appId,
    dateFrom: precedingDateFrom,
    dateTo: precedingDateTo,
    dataResidency,
  });

  const npsFetchingState = useAppNpsByDay({
    tenantId,
    appId,
    dateFrom,
    dateTo,
    dataResidency,
  });

  const isLoading = precedingNpsFetchingState.isLoading || npsFetchingState.isLoading;
  const isSuccess = precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess;
  const isError = precedingNpsFetchingState.isError || npsFetchingState.isError;

  const hasData = useMemo(() => {
    const hasCurrentPeriodData =
      npsFetchingState.isSuccess && npsFetchingState.data.replyCount > 0;
    const hasPrecedingPeriodData =
      precedingNpsFetchingState.isSuccess &&
      precedingNpsFetchingState.data.replyCount > 0;

    const result = hasCurrentPeriodData || hasPrecedingPeriodData;

    return result;
  }, [npsFetchingState, precedingNpsFetchingState]);

  return (
    <CardContainer
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      hasData={hasData}
      title={title}
      gridStyles={gridStyles}
      isVisibleWithoutData={isVisibleWithoutData}
    >
      {precedingNpsFetchingState.isSuccess && npsFetchingState.isSuccess && (
        <ListChart
          data={buildData(npsFetchingState.data.score, npsFetchingState.data.replyCount)}
          precedingPeriodData={buildData(
            precedingNpsFetchingState.data.score,
            precedingNpsFetchingState.data.replyCount,
          )}
          hidePercentage={true}
          header={t('currentPeriod')}
          sort={sort}
        />
      )}
    </CardContainer>
  );
};

const NpsDescription = styled.p`
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);

  @media (min-width: 1600px) {
    padding: 20px;
  }
`;

const Parameter = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
  margin-top: -20px;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  @media (min-width: 1600px) {
    flex-direction: row;
  }
`;

const ScoreWrapper = styled.div`
  background: #f5f5f5;
  display: flex;
  padding: 20px 10px;
  flex: 1 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 20px;

  @media (min-width: 1600px) {
    justify-self: center;
    align-self: flex-end;
    flex-direction: row;
    margin-top: 0;
  }
`;

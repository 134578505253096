import React, { useMemo } from 'react';
import { Row, Col, Select } from 'antd';
import { FieldProps } from 'react-jsonschema-form';
import usePhyhubTwinsList from '../../../../../store/hooks/phyhub-twins/use-phyhub-twins-list';
import { InputWrap, Option } from '../../../react-final-form';
import { extractFieldError } from '../../error-messages';
import {
  PhyhubTwinsListParamsFilters,
  PhyhubTwinsListParamsFiltersOperatorEnum,
} from '../../../../../services/phyhub/types/phyhub-twin-list-params.interface';

interface TwinPickerFormData {
  id: string;
  ref: 'twin';
}

interface TwinPickerProps extends FieldProps {}

const TwinPicker = (props: TwinPickerProps) => {
  const {
    onChange: updateFormField,
    errorSchema,
    schema,
    uiSchema,
    formData,
    formContext,
  } = props;

  const options = (uiSchema['ui:options'] || {}) as { twinTypes?: string[] };
  const twinTypes: string[] = options.twinTypes || [];
  let filters: PhyhubTwinsListParamsFilters = {};

  if (twinTypes.length > 0) {
    filters.type = {
      op: PhyhubTwinsListParamsFiltersOperatorEnum.IN,
      val: twinTypes,
    };
  }

  const { id } = (formData || {}) as TwinPickerFormData;

  const { organisationId: tenantId } = formContext;

  const { data: twinsList, status } = usePhyhubTwinsList({ tenantId, filters });

  const twinOptions = useMemo<Option[]>(() => {
    if (!twinsList) {
      return [];
    }

    return twinsList.docs.map((twin) => {
      return { label: twin.displayName, value: twin.id };
    });
  }, [twinsList]);

  return (
    <>
      <label className="control-label">{schema.title}</label>

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <InputWrap error={extractFieldError(errorSchema, 'id')}>
            <Select
              value={id}
              allowClear={true}
              showSearch={true}
              optionFilterProp="children"
              loading={status === 'loading'}
              onChange={(value) => {
                const data: TwinPickerFormData = {
                  id: value,
                  ref: 'twin',
                };
                updateFormField(data);
              }}
            >
              {twinOptions.map((twinOption) => {
                return (
                  <Select.Option value={twinOption.value} key={twinOption.value}>
                    {twinOption.label}
                  </Select.Option>
                );
              })}
            </Select>
          </InputWrap>
        </Col>
      </Row>
    </>
  );
};

export default TwinPicker;

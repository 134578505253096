import React from 'react';
import styled from '@emotion/styled';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import { SpaceReport } from '../../../../common/overview-report';
import {
  AnalyticsSchema,
  CardType,
  DataMatrixColumnDataType,
  DataMatrixDataSource,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';

const Container = styled(ContentWrap)`
  padding: 0;
`;

const SpacesReportContainer = () => {
  const reportSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
          {
            type: CardType.DataMatrix,
            title: 'Performance by device',
            dataSource: DataMatrixDataSource.Device,
            columns: [
              {
                title: 'Total sessions',
                dataType: {
                  type: DataMatrixColumnDataType.Sessions,
                },
              },
              {
                title: 'Total events',
                dataType: {
                  type: DataMatrixColumnDataType.Events,
                },
              },
            ],
          },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  return (
    <Container className="content-body">
      <SpaceReport schema={reportSchema} />
    </Container>
  );
};

export default SpacesReportContainer;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Icon, Typography, Row, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import UniversalDevice from '../../../../store/types/universal-device';
import { ApiError } from '../../../../services/api/api-error';
import isMonitored from '../../../../utils/is-monitored';
import OrganisationApp from '../../../../store/types/organisation-app';
import DevicesUniversalListItem from '../devices-universal-list-item/devices-universal-list-item.component';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import sortUniversalDevices from '../../../../utils/sort-universal-devices';
import ErrorView from '../../error-view/error-view.component';
import UniversalDeviceType from '../../../../store/types/universal-device-type.enum';
import Pagination from '../../pagination';
import {
  PaginationProps,
  PaginationSearchParam,
} from '../../../../store/types/pagination';
import usePagination from '../../pagination/hook/use-pagination';
import UniversalDevicesListViewTable from '../universal-devices-listview/universal-devices-listview.container';
import LegacyWindowsDevice from '../../../../store/types/legacy-windows-device';
import { LayoutViewType } from '../../layout-view/layout-view.component';

const { Title } = Typography;

interface DevicesListProps extends PaginationProps {
  app?: OrganisationApp | null;
  apps?: OrganisationApp[];
  organizationId?: string;
  devices: UniversalDevice[];
  disableDevices?: string[];
  loaded: boolean;
  fetchDevices?: ({
    silent,
    searchParam,
    page,
    pageSize,
  }: {
    silent: boolean;
    searchParam?: PaginationSearchParam;
    page: number;
    pageSize: number;
  }) => Promise<void>;
  lastUpdated?: Date;
  error?: ApiError | null;
  isPolling: boolean;
  defaultShowAll: boolean;
  devicesGridStyle?: string;
  customQuery?: string;
  onDeviceListItemClick?: (device: UniversalDevice | LegacyWindowsDevice) => void;
  renderExtraActions?: (deviceId: string) => JSX.Element;
  showLegacyWindowsOption?: boolean;
  defaultShowLegacyWindowsDevice?: boolean;
  layout?: LayoutViewType;
  customPageSize?: number;
  customPageSizeOption?: number[];
}

const DevicesUniversalList = (props: DevicesListProps) => {
  const {
    app,
    apps = [],
    organizationId,
    devices,
    disableDevices = [],
    loaded,
    fetchDevices,
    lastUpdated,
    error,
    devicesGridStyle,
    customQuery,
    onDeviceListItemClick,
    renderExtraActions,
    showLegacyWindowsOption = false,
    defaultShowLegacyWindowsDevice = false,
    isPaginationLoading,
    pagination,
    layout = 'list',
    customPageSize,
    customPageSizeOption,
  } = props;

  const paginationContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [query, setQuery] = useState<string>(customQuery || '');
  const [filteredDevices, setFilteredDevices] = useState<UniversalDevice[]>([]);
  const [showAll] = useState<boolean>(true);
  const [includeLegacyWindows, setIncludeLegacyWindows] = useState<boolean>(false);
  const [isLegacyWindowsAutoScroll, setIsLegacyWindowsAutoScroll] = useState<boolean>(
    false,
  );

  const { t } = useTranslation();

  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    defaultPage,
    defaultPageSize,
    defaultPageSizeOption,
  } = usePagination({
    defaultPageSize: customPageSize,
    defaultPageSizeOption: customPageSizeOption,
  });

  useEffect(() => {
    if (customQuery !== undefined && !pagination) {
      setQuery(customQuery);
    }
  }, [customQuery, pagination]);

  useEffect(() => {
    const currentDevices = showAll ? devices : devices.filter(isMonitored);

    if (query.length > 1) {
      setFilteredDevices(filterDevices(currentDevices, query));
    } else {
      setFilteredDevices(currentDevices);
    }
  }, [devices, query, setFilteredDevices, showAll]);

  useEffect(() => {
    if (!fetchDevices) {
      return;
    }

    fetchDevices({
      silent: false,
      page: defaultPage,
      pageSize: defaultPageSize,
    });
  }, [fetchDevices, defaultShowLegacyWindowsDevice, defaultPageSize, defaultPage]);

  useEffect(() => {
    if (loaded && defaultShowLegacyWindowsDevice) {
      setIncludeLegacyWindows(defaultShowLegacyWindowsDevice);
    }
  }, [defaultShowLegacyWindowsDevice, loaded]);

  const handlePaginationSizeChange = useCallback(
    async (pageNum: number, pageLimit: number): Promise<void> => {
      if (!fetchDevices) {
        return;
      }

      await fetchDevices({
        silent: true,
        searchParam: pagination ? pagination.param : undefined,
        page: pageNum,
        pageSize: pageLimit,
      });

      setPageSize(pageLimit);
    },
    [fetchDevices, pagination, setPageSize],
  );

  const handlePageChange = useCallback(
    async (pageNum: number, pageLimit?: number): Promise<void> => {
      setPage(pageNum);

      if (!fetchDevices) {
        return;
      }

      await fetchDevices({
        silent: true,
        searchParam: pagination ? pagination.param : undefined,
        page: pageNum,
        pageSize: pageLimit || defaultPageSize,
      });
    },
    [defaultPageSize, fetchDevices, pagination, setPage],
  );

  const refreshCurrentDevicesPage = useCallback(async () => {
    if (!fetchDevices) {
      return;
    }

    await fetchDevices({
      silent: true,
      searchParam: pagination ? pagination.param : undefined,
      page,
      pageSize,
    });
  }, [fetchDevices, page, pageSize, pagination]);

  const handleIncludeLegacyWindowDevices = useCallback(
    (isWindowsDeviceIncluded: boolean): void => {
      setIncludeLegacyWindows(isWindowsDeviceIncluded);
      setIsLegacyWindowsAutoScroll(isWindowsDeviceIncluded);
    },
    [],
  );

  const renderDeviceListItem = useCallback(
    (device: UniversalDevice, isDisabled: boolean): JSX.Element => {
      let link;

      if (!onDeviceListItemClick && device.organizationId) {
        if (device.type === UniversalDeviceType.SCREEN) {
          link = `/organisations/${device.organizationId}/operations/devices/v3/${device.id}`;
        } else if (device.type === UniversalDeviceType.IOTHUB) {
          link = `/organisations/${device.organizationId}/operations/devices/v2/${device.uuid}`;
        } else if (device.type === UniversalDeviceType.LEGACY_BALENA) {
          link = `/organisations/${device.organizationId}/operations/devices/${device.uuid}`;
        }
      }

      if (link) {
        return (
          <Link key={device.id} to={link}>
            <DevicesUniversalListItem
              renderExtraActions={renderExtraActions}
              lastUpdated={lastUpdated}
              device={device}
              isDisabled={isDisabled}
            />
          </Link>
        );
      }

      return (
        // eslint-disable-next-line
        <div
          key={device.id}
          onClick={() => {
            return !isDisabled && onDeviceListItemClick && onDeviceListItemClick(device);
          }}
        >
          <DevicesUniversalListItem
            renderExtraActions={renderExtraActions}
            lastUpdated={lastUpdated}
            device={device}
            isDisabled={isDisabled}
          />
        </div>
      );
    },
    [renderExtraActions, lastUpdated, onDeviceListItemClick],
  );

  const sortedDevices = useMemo((): JSX.Element[] => {
    return sortUniversalDevices(filteredDevices).map((device) => {
      const isDisabled = disableDevices.includes(device.id);
      return renderDeviceListItem(device, isDisabled);
    });
  }, [disableDevices, filteredDevices, renderDeviceListItem]);

  const { currentPage, currentPageSize, currentPageTotal } = useMemo(() => {
    return {
      currentPage: pagination ? pagination.page : defaultPage,
      currentPageSize: pagination ? pagination.limit : pageSize,
      currentPageTotal: pagination ? pagination.total : devices.length,
    };
  }, [defaultPage, devices.length, pageSize, pagination]);

  return (
    <>
      <Container>
        {!loaded && !error && (
          <OverlayRelative>
            <Spinner />
          </OverlayRelative>
        )}

        {!error && loaded && showLegacyWindowsOption && (
          <FiltersRow type="flex">
            <IncludeLegacyWindowsDevices
              style={{ marginLeft: 0 }}
              checked={includeLegacyWindows}
              onChange={(e) => handleIncludeLegacyWindowDevices(e.target.checked)}
            >
              {t('includeLegacyWindowsDevices')}
            </IncludeLegacyWindowsDevices>
          </FiltersRow>
        )}
        <div ref={paginationContainerRef}>
          {error && <ErrorView position="relative" />}
          {!error && loaded && (
            <>
              {layout === 'list' ? (
                <UniversalDevicesListViewTable
                  apps={apps}
                  devices={filteredDevices}
                  refreshCurrentDevicesPage={refreshCurrentDevicesPage}
                  isSysAdmin={false}
                />
              ) : (
                <DevicesGrid css={css(devicesGridStyle || '')}>
                  {sortedDevices}
                </DevicesGrid>
              )}
              <Pagination
                parentContainerRef={paginationContainerRef}
                isLoading={isPaginationLoading}
                align="right"
                showSizeChanger
                onShowSizeChange={handlePaginationSizeChange}
                current={currentPage}
                pageSize={currentPageSize}
                total={currentPageTotal}
                onChange={handlePageChange}
                pageSizes={defaultPageSizeOption}
                noData={
                  layout === 'grid' && (
                    <OverlayRelative>
                      <Title level={4}>
                        {t('noDevicesFound')} <Icon type="frown" />
                      </Title>
                    </OverlayRelative>
                  )
                }
              />
            </>
          )}
        </div>
      </Container>
    </>
  );
};

DevicesUniversalList.defaultProps = {
  isPolling: true,
  defaultShowAll: false,
};

const filterDevices = (devices: UniversalDevice[], query: string) => {
  const lowerCasedQuery = query.toLowerCase();

  return devices.filter((device) => {
    const hostName = get(device, 'properties.reported.hostname', '') as string;
    const supervisorRelease = get(
      device,
      'properties.reported.supervisorRelease',
      '',
    ) as string;

    const hasDeviceName = device.deviceName.toLowerCase().includes(lowerCasedQuery);
    const hasHostName = hostName.toLowerCase().includes(lowerCasedQuery);
    const hasDeviceSerial =
      device.deviceSerial && device.deviceSerial.toLowerCase().includes(lowerCasedQuery);
    const hasSupervisorRelease = supervisorRelease
      .toLowerCase()
      .includes(lowerCasedQuery);

    return hasDeviceName || hasHostName || hasDeviceSerial || hasSupervisorRelease;
  });
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
`;

const IncludeLegacyWindowsDevices = styled(Checkbox)`
  margin-top: 10px;

  @media (max-width: 530px) {
    display: flex;
    align-items: center;
    min-height: 44px;
  }
`;

const DevicesGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, 310px);
  justify-content: center;
  padding-bottom: 10px;
`;

const FiltersRow = styled(Row)`
  padding: 0 0 16px;
  align-items: center;
`;

const OverlayRelative = styled(Overlay)`
  position: relative;
  flex-direction: column;
  margin-top: 40px;
`;

export default DevicesUniversalList;

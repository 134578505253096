import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Modal } from 'antd';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import { useModalsManager } from '../../../modals-manager-provider/modals-manager.provider';
import PhyhubDevicesUpdateEnvironmentModal from '../../phyhub-devices-modals/phyhub-devices-update-environment-modal/phyhub-devices-update-environment-modal.component';
import useEnvironments from '../../../use-environments/use-environments';
import usePhyhubDevicesBulkUpdate from '../../../../../store/hooks/phyhub-devices/use-phyhub-devices-bulk-update';
import { getPhyhubApiErrorMessage } from '../../../../../services/phyhub/utils/get-phyhub-api-error-message.util';

const MAX_DEVICES_FOR_UPDATE_ENVIRONMENT = 50;

const DEFAULT_ENVIRONMENT_NAME = 'prod';

const MODAL_KEY = 'phyhubDevicesUpdateEnvironment';

interface UsePhyhubDevicesUpdateEnvironmentFlowParams {
  tenantId: string;
  onMassActionSuccess: () => void;
}

interface UsePhyhubDevicesUpdateEnvironmentFlowReturn {
  isPhyhubDeviceUpdateEnvironmentLoading: boolean;
  handleUpdateEnvironment: (selectedDevices: PhyhubDevice[]) => void;
}

const usePhyhubDevicesUpdateEnvironmentFlow = (
  params: UsePhyhubDevicesUpdateEnvironmentFlowParams,
): UsePhyhubDevicesUpdateEnvironmentFlowReturn => {
  const { tenantId, onMassActionSuccess } = params;

  const { t } = useTranslation();

  const { data: environmentsCollection } = useEnvironments(tenantId);

  const {
    isLoading: isPhyhubDeviceBulkUpdateLoading,
    mutateAsync: phyhubDeviceBulkUpdate,
  } = usePhyhubDevicesBulkUpdate();

  const [devicesForUpdateEnvironment, setDevicesForUpdateEnvironment] = useState<
    PhyhubDevice[]
  >([]);

  const handleUpdateEnvironmentConfirm = useCallback(
    async (deviceIds: string[], targetEnvironmentName: string) => {
      try {
        await phyhubDeviceBulkUpdate({
          tenantId,
          payload: { deviceIds, payload: { env: targetEnvironmentName } },
        });

        message.success(t('phyhubDevices.modal.updateEnvironment.success'));

        setDevicesForUpdateEnvironment([]);

        onMassActionSuccess();
      } catch (error) {
        message.error(
          getPhyhubApiErrorMessage(
            error,
            t('phyhubDevices.modal.updateEnvironment.error'),
          ),
        );
      }
    },
    [tenantId, phyhubDeviceBulkUpdate, t, onMassActionSuccess],
  );

  const handleUpdateEnvironment = useCallback(
    (selectedDevices: PhyhubDevice[]) => {
      if (selectedDevices.length > MAX_DEVICES_FOR_UPDATE_ENVIRONMENT) {
        Modal.error({
          title: t('phyhubDevices.modal.updateEnvironmentTooManyDevices.title'),
          content: t('phyhubDevices.modal.updateEnvironmentTooManyDevices.content', {
            count: MAX_DEVICES_FOR_UPDATE_ENVIRONMENT,
          }),
          okText: t('phyhubDevices.button.done'),
        });

        return;
      }

      setDevicesForUpdateEnvironment(selectedDevices);
    },
    [t],
  );

  const handleUpdateEnvironmentReset = useCallback(() => {
    setDevicesForUpdateEnvironment([]);
  }, []);

  const modalNode = useMemo(
    () => (
      <PhyhubDevicesUpdateEnvironmentModal
        isConfirmLoading={isPhyhubDeviceBulkUpdateLoading}
        isVisible={!!devicesForUpdateEnvironment.length}
        defaultTargetEnvironmentName={DEFAULT_ENVIRONMENT_NAME}
        devices={devicesForUpdateEnvironment}
        environments={environmentsCollection || []}
        onCancel={handleUpdateEnvironmentReset}
        onUpdateEnvironmentConfirm={handleUpdateEnvironmentConfirm}
      />
    ),
    [
      isPhyhubDeviceBulkUpdateLoading,
      devicesForUpdateEnvironment,
      environmentsCollection,
      handleUpdateEnvironmentReset,
      handleUpdateEnvironmentConfirm,
    ],
  );

  const { addModal, removeModal } = useModalsManager();

  useEffect(() => {
    addModal({
      key: MODAL_KEY,
      node: modalNode,
    });

    return () => {
      removeModal(MODAL_KEY);
    };
  }, [addModal, modalNode, removeModal]);

  return {
    handleUpdateEnvironment,
    isPhyhubDeviceUpdateEnvironmentLoading: isPhyhubDeviceBulkUpdateLoading,
  };
};

export default usePhyhubDevicesUpdateEnvironmentFlow;

import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import PhyhubDevicesInstallationItem, {
  PhyhubDevicesInstallationItemSize,
} from '../../../phyhub-devices-installation-item/phyhub-devices-installation-item.component';

interface PhyhubDevicesTableInstallationCellProps {
  maxVisibleInstallationsCount: number;
  tenantId: string;
  deviceInstallations: OrganisationApp[];
}

const PhyhubDevicesTableInstallationCell: FC<PhyhubDevicesTableInstallationCellProps> = (
  props,
) => {
  const { tenantId, deviceInstallations, maxVisibleInstallationsCount } = props;

  const visibleInstallationsCount = useMemo<OrganisationApp[]>(
    () => deviceInstallations.slice(0, maxVisibleInstallationsCount),
    [maxVisibleInstallationsCount, deviceInstallations],
  );

  const remainingInstallationsCount = useMemo<number>(
    () => deviceInstallations.length - maxVisibleInstallationsCount,
    [maxVisibleInstallationsCount, deviceInstallations.length],
  );

  return (
    <Wrapper>
      {visibleInstallationsCount.map((installation) => (
        <PhyhubDevicesInstallationItem
          key={installation.id}
          size={PhyhubDevicesInstallationItemSize.SMALL}
          tenantId={tenantId}
          installation={installation}
        />
      ))}
      {remainingInstallationsCount > 0 && (
        <MoreCount>+{remainingInstallationsCount}</MoreCount>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const MoreCount = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #888;
`;

export default PhyhubDevicesTableInstallationCell;

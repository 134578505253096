import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Tabs } from 'antd';
import { AnalyticsSchema, Card, CardType } from '@ombori/grid-reports';
import { useStoreState } from '../../../../store/initialize-store';
import ErrorView from '../../error-view/error-view.component';
import Organisation from '../../../../store/types/organisation';
import { SpaceEventsList } from '../cards/events-list';
import { SpaceSessions } from '../cards/sessions';
import { SpaceEventsFlow } from '../cards/events-flow';
import { SpaceNps } from '../cards/nps';
import { SpaceProductsEventCount } from '../cards/products-event-count';
import { SpaceCategoriesEventCount } from '../cards/categories-event-count';
import { SpaceEventsFunnel } from '../cards/events-funnel';
import { SpaceWeekHeatmap } from '../cards/week-heatmap';
import { SpaceEventsCount } from '../cards/events-count';
import { SpaceAverageSales } from '../cards/average-sales';
import { SpaceAverageTimeBetweenTransactions } from '../cards/average-time-between-transactions';
import { SpaceAverageNumberOfPurchasedProducts } from '../cards/average-number-of-purchased-products';
import { SpacePurchasedProductsCategoriesEventCount } from '../cards/purchased-products-categories-event-count';
import { SpacePurchasedProductsEventCount } from '../cards/purchased-products-event-count';
import { SpaceQrCodesEventCount } from '../cards/qr-codes-event-count';
import { SpaceMedia } from '../cards/media';
import AnalyticsReport from '../analytics-report';
import Controls from '../controls';
import RangePicker from '../../range-picker/range-picker.component';
import useDateRange from '../../use-date-range';
import ErrorBoundary from '../../error-boundary/error-boundary.component';
import DownloadReport from '../download-report.component';
import DataMatrix from '../cards/data-matrix';
import useSpaceSchema from '../../use-space-schema/use-space-schema';
import Overlay from '../../overlay/overlay.component';
import Spinner from '../../spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import validateSchema from '../validate-schema';
import { SpaceEventsSuccessAndFailureRate } from '../cards/events-succes-failure-rate';
import { ReportContext } from '../../use-analytics-report';
import { useAnalyticsParams } from '../../use-analytics-params';
import { SpaceImageCardContainer } from '../cards/image';

const { TabPane } = Tabs;

const Container = styled.div``;

const WrapperContainer = styled.div``;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

interface CheckSchemaValidResult {
  isValid: true;
  analyticsSchema: AnalyticsSchema;
}

interface CheckSchemaInvalidResult {
  isValid: false;
  analyticsSchema: undefined;
}

type CheckSchemaResult = CheckSchemaValidResult | CheckSchemaInvalidResult;

const checkSchema = (analyticsSchema: unknown): CheckSchemaResult => {
  try {
    const validatedSchema = validateSchema(analyticsSchema);

    return { isValid: true, analyticsSchema: validatedSchema };
  } catch (error) {
    return { isValid: false, analyticsSchema: undefined };
  }
};
interface ReportProps {
  schema: AnalyticsSchema;
  tenant: Organisation;
  spaceId: string;
}

const Report: React.FC<ReportProps> = ({ schema: { groups }, tenant, spaceId }) => {
  const { dateFrom, dateTo, onChangeRange } = useDateRange();

  const { updateAnalyticsParams } = useAnalyticsParams();

  useEffect(() => {
    updateAnalyticsParams({ fromDate: dateFrom, toDate: dateTo });
  }, [dateFrom, dateTo, updateAnalyticsParams]);

  const getComponent = useCallback(
    (reportCard: Card): React.ReactNode => {
      switch (reportCard.type) {
        case CardType.EventsList:
          return (
            <SpaceEventsList
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsCount:
          return (
            <SpaceEventsCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              eventType={reportCard.eventType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Sessions:
          return (
            <SpaceSessions
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFlow:
          return (
            <SpaceEventsFlow
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Nps:
          return (
            <SpaceNps
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.ProductsEventCount:
          return (
            <SpaceProductsEventCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              eventType={reportCard.eventType}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.CategoriesEventCount:
          return (
            <SpaceCategoriesEventCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsFunnel:
          return (
            <SpaceEventsFunnel
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              events={reportCard.events}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.WeekHeatmap:
          return (
            <SpaceWeekHeatmap
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.DataMatrix:
          return (
            <DataMatrix
              tenantId={tenant.id}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              dataSource={reportCard.dataSource}
              columns={reportCard.columns}
              downloadFileColumns={reportCard.downloadFileColumns}
              tableColumns={reportCard.tableColumns}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.EventsSuccessFailureRate:
          return (
            <SpaceEventsSuccessAndFailureRate
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              interactionType={reportCard.interactionType}
              gridStyles={reportCard.gridStyles}
              title={reportCard.title}
              inputType={reportCard.inputType}
              additionalData={reportCard.additionalData}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              dataSource={reportCard.dataSource}
              dataSourceType={reportCard.dataSourceType}
              reportContext={ReportContext.Space}
            />
          );

        case CardType.AverageSales:
          return (
            <SpaceAverageSales
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageTimeBetweenTransactions:
          return (
            <SpaceAverageTimeBetweenTransactions
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.AverageNumberOfPurchasedProducts:
          return (
            <SpaceAverageNumberOfPurchasedProducts
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsEventCount:
          return (
            <SpacePurchasedProductsEventCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.PurchasedProductsCategoriesEventCount:
          return (
            <SpacePurchasedProductsCategoriesEventCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.QrCodesCount:
          return (
            <SpaceQrCodesEventCount
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
            />
          );

        case CardType.Media:
          return (
            <SpaceMedia
              tenantId={tenant.id}
              spaceId={spaceId}
              dateFrom={dateFrom}
              dateTo={dateTo}
              dataResidency={tenant.dataResidency}
              title={reportCard.title}
              gridStyles={reportCard.gridStyles}
              isVisibleWithoutData={reportCard.isVisibleWithoutData}
              primaryKey={reportCard.primaryKey}
            />
          );

        case CardType.ModulesStatus:
          return <></>;

        case CardType.Image:
          return (
            <SpaceImageCardContainer
              title={reportCard.title}
              imageProperty={reportCard.imageProperty}
              isVisibleWithoutData={false}
              tenantId={tenant.id}
              spaceId={spaceId}
            />
          );

        default:
          // @ts-ignore
          throw new Error(`Unknown schema card type ${reportCard.type}`);
      }
    },
    [tenant, spaceId, dateFrom, dateTo],
  );

  return (
    <WrapperContainer>
      <Controls>
        <RangePicker dateFrom={dateFrom} dateTo={dateTo} onChangeRange={onChangeRange} />
        <DownloadReport
          tenantId={tenant.id}
          dataResidency={tenant.dataResidency}
          spaceId={spaceId}
          filters={{
            dateFrom,
            dateTo,
          }}
        />
      </Controls>

      <Tabs defaultActiveKey="1" animated={false}>
        {groups.map((group, index) => {
          const components = group.cards.map(getComponent);

          return (
            <TabPane tab={group.name} key={`${index + 1}`}>
              <AnalyticsReport
                cards={components}
                columnsCount={group.columnsCount}
                gridStyles={group.gridStyles}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </WrapperContainer>
  );
};

interface WrapperProps extends ReportProps {
  analyticsSchema: AnalyticsSchema;
}

const Wrapper: React.FC<WrapperProps> = ({
  schema,
  tenant,
  analyticsSchema,
  spaceId,
}) => {
  const checkSchemaResult = useMemo(() => checkSchema(analyticsSchema), [
    analyticsSchema,
  ]);

  if (checkSchemaResult.isValid) {
    return (
      <Report
        tenant={tenant}
        schema={checkSchemaResult.analyticsSchema}
        spaceId={spaceId}
      />
    );
  }

  return (
    <Container>
      <ErrorBoundary>
        <Report tenant={tenant} schema={schema} spaceId={spaceId} />
      </ErrorBoundary>
    </Container>
  );
};
interface SpaceReportProps {
  schema: AnalyticsSchema;
}

const SpaceReport: React.FC<SpaceReportProps> = ({ schema }) => {
  const { organisationId: tenantId, spaceId } = useParams<{
    organisationId: string;
    spaceId: string;
  }>();

  const { t } = useTranslation();

  const { tenant } = useStoreState(
    (state) => ({
      tenant: state.organisations.data && state.organisations.data[tenantId],
    }),
    [tenantId],
  );

  const spaceSchemaState = useSpaceSchema({ organizationId: tenantId, spaceId });

  if (!tenant) {
    return <ErrorView />;
  }

  return (
    <Container>
      <ErrorBoundary>
        {spaceSchemaState.isLoading && (
          <Overlay>
            <Spinner />
          </Overlay>
        )}

        {spaceSchemaState.isSuccess && (
          <Wrapper
            tenant={tenant}
            schema={schema}
            analyticsSchema={spaceSchemaState.data}
            spaceId={spaceId}
          />
        )}

        {spaceSchemaState.isError && (
          <ErrorMessage>{t('errorDuringFetchingSchema')}</ErrorMessage>
        )}
      </ErrorBoundary>
    </Container>
  );
};

export default SpaceReport;

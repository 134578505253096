import React, { useMemo } from 'react';
import { Empty } from 'antd';
import { toPairs, get, set } from 'lodash';
import styled from '@emotion/styled';
import { ApiError } from '../../../services/api/api-error';
import ErrorView from '../error-view/error-view.component';
import Overlay from '../overlay/overlay.component';
import Spinner from '../spinner/spinner.component';
import ErrorBoundary from '../error-boundary/error-boundary.component';
import { Day, Event, sumDays } from './utils';
import SessionsCard from './charts/sessions-card.component';
import AreaCard from './charts/area-card.component';
import PieCard from './charts/pie-card.component';
import ListCard from './charts/list-card.component';
import TableCard from './charts/table-card.component';
import LabeledValue from './charts/labeled-value.component';

interface OverviewProps {
  fetchReport: any;
  report: any | null;
  loading: boolean;
  error: ApiError | null;
}

const mapCompartmentCode = (compartmentCode: string) => {
  switch (compartmentCode) {
    // Discount economy
    case 'G':
    case 'K':
    case 'L':
    case 'Q':
    case 'V':
    case 'U':
    case 'T':
    case 'X':
    case 'N':
    case 'O':
    case 'S':
    // Full fare economy
    // eslint-disable-next-line no-fallthrough
    case 'Y':
    case 'B':
    case 'M':
    case 'H': {
      return 'Economy';
    }
    case 'W':
    case 'E': {
      return 'Premium';
    }
    case 'A':
    case 'F':
    case 'P': {
      return 'First class';
    }
    // Discounted business
    case 'D':
    case 'I':
    case 'Z':
    // Full fare business
    // eslint-disable-next-line no-fallthrough
    case 'C':
    case 'J': {
      return 'Business';
    }
    default: {
      return 'Other';
    }
  }
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const OverviewReport = (props: OverviewProps) => {
  const { loading, report, error } = props;

  const cards = useMemo(() => {
    const result = [];

    if (report) {
      if (report.SESSION_START && report.SESSION_DURATION) {
        result.push(
          <SessionsCard
            key="SESSIONS"
            data={report.SESSION_START}
            duration={report.SESSION_DURATION}
          />,
        );
      }
      if (report.SESSION_START) {
        result.push(
          <PieCard
            key="DEVICE_USAGE"
            title="Sessions per device"
            data={report.SESSION_START}
            showTotal={false}
          />,
        );
      }
      if (report.INTERACTION && report.FACE) {
        const data = (report.INTERACTION as Day[]).map(
          ([interactionDate, interactionEvents], i) => {
            const [faceDate, faceEvents]: Day = report.FACE[i] || [];
            const mergedEvents = interactionEvents.map((ev: any) => ({
              ...ev,
              label: 'Initiated with tap',
            }));

            if (interactionDate === faceDate && faceEvents.length > 0) {
              faceEvents.forEach((ev: any) => {
                mergedEvents.push({
                  ...ev,
                  label: 'Initiated with face detection',
                });
              });
            }

            return [interactionDate, mergedEvents] as Day;
          },
        );

        result.push(
          <AreaCard
            key="SESSIONS"
            title="Sessions"
            info="All sessions started via tap or face detection"
            data={data}
            stacked
          />,
        );
      }
      // if (report.TIMEOUT) {
      //   result.push(
      //     <AreaCard
      //       key="TIMEOUT"
      //       title="Sessions (measured with TIMEOUT)"
      //       data={report.TIMEOUT}
      //     />,
      //   );
      // }
      if (report.SWATCH) {
        result.push(<PieCard key="SWATCH" title="Swatch" info="" data={report.SWATCH} />);
      }
      if (report.CATEGORY) {
        result.push(
          <PieCard
            key="CATEGORY"
            title="Category views"
            info="All category views from any point in the user flow (not just the start page)"
            data={report.CATEGORY}
          />,
        );
      }
      if (report.BANNER) {
        result.push(
          <PieCard
            key="BANNER"
            title="Banner Clicks"
            info="All banner clicks"
            data={report.BANNER}
          />,
        );
      }
      if (report.SELECT_CATEGORY) {
        result.push(
          <AreaCard
            key="SELECT_CATEGORY"
            title="Category views"
            data={report.SELECT_CATEGORY}
          />,
        );
      }
      if (report.CAMPAIGN) {
        result.push(
          <AreaCard
            key="CAMPAIGN"
            title="Campaign views"
            info="All campaign views"
            data={report.CAMPAIGN}
            stacked
          />,
        );
      }
      if (report.FLIGHT) {
        result.push(
          <PieCard
            key="FLIGHT"
            title="Flight views"
            info="All flight views from both boarding pass scans and the departures feature"
            data={report.FLIGHT}
          />,
        );
      }
      if (report.PRODUCT) {
        result.push(
          <AreaCard key="PRODUCT" title="Product views" data={report.PRODUCT} />,
        );

        const brands: Day[] = (report.PRODUCT as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                const match = /^(.+) \/ .+$/.exec(ev.label);
                label = match && match[1] ? match[1] : 'Unknown';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="PRODUCT_BRANDS"
            title="Most popular brands"
            info="Based on which products have been viewed"
            data={brands}
          />,
        );

        const products: Day[] = (report.PRODUCT as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                label = ev.label.replace(/^(.+) \/ (.+)$/, '$1 $2');
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="PRODUCT_NAMES"
            title="Most popular products"
            info=""
            data={products}
          />,
        );
      }

      if (report.ADD_BAG_PRODUCT) {
        result.push(
          <AreaCard
            key="PRODUCT_BAG"
            title="Items added to shopping bag"
            data={report.ADD_BAG_PRODUCT}
          >
            {report.SESSION_START && (
              <div style={{ flex: 1 }}>
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Total items added to shopping bag"
                  value={sumDays(report.ADD_BAG_PRODUCT)}
                />
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Average number of items added to shopping bag per session"
                  value={Math.ceil(
                    sumDays(report.ADD_BAG_PRODUCT) / sumDays(report.SESSION_START),
                  )}
                />
              </div>
            )}
          </AreaCard>,
        );

        // Insert added to bag product logic here
        const products: Day[] = (report.ADD_BAG_PRODUCT as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                const splitLabel = ev.label.split('/');
                label = splitLabel.length > 0 ? splitLabel[0] : '';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        const categories: Day[] = (report.ADD_BAG_PRODUCT as Day[]).map(
          ([day, events]) => [
            day,
            toPairs(
              events.reduce((acc: any, ev) => {
                let label = '';

                if (ev.label) {
                  const splitLabel = ev.label.split('/');
                  label = splitLabel.length > 1 ? splitLabel[1] : '';
                }

                if (label) {
                  acc[label] = (acc[label] || 0) + ev.value;
                }

                return acc;
              }, {}),
            ).map(([label, value]): Event => ({ label, value: value as number })),
          ],
        );

        result.push(
          <ListCard
            key="BAG_PRODUCT_NAMES"
            title="Most popular products added to shopping bag"
            info=""
            data={products}
          />,
        );

        result.push(
          <ListCard
            key="BAG_CATEGORY_NAMES"
            title="Most popular categories added to shopping bag"
            info=""
            data={categories}
          />,
        );
      }

      if (report.SELECT_PRODUCT) {
        result.push(
          <AreaCard
            key="SELECT_PRODUCT"
            title="Product views"
            data={report.SELECT_PRODUCT}
          />,
        );
      }
      if (report.CHECKOUT) {
        result.push(
          <AreaCard key="CHECKOUT" title="Checkout" data={report.CHECKOUT}>
            <div style={{ flex: 1 }}>
              <LabeledValue
                style={{ marginBottom: 16 }}
                size="md"
                label="Total checkouts"
                value={sumDays(report.CHECKOUT)}
              />
              {report.CHECKOUT_VALUE && (
                <>
                  <LabeledValue
                    style={{ marginBottom: 16 }}
                    size="md"
                    label="Total order value on checkout"
                    value={`$${report.CHECKOUT_VALUE.toFixed(2)}`}
                  />
                  <LabeledValue
                    style={{ marginBottom: 16 }}
                    size="md"
                    label="Average order value on checkout"
                    value={`$${(report.CHECKOUT_VALUE / sumDays(report.CHECKOUT)).toFixed(
                      2,
                    )}`}
                  />
                </>
              )}
            </div>
          </AreaCard>,
        );
      }
      if (report.SEARCH) {
        result.push(
          <AreaCard key="SEARCH" title="Searches performed" data={report.SEARCH} />,
        );

        const searches: Day[] = (report.SEARCH as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                label = ev.label.replace(/^(.+)$/, '$1');
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="SEARCH_KEYWORDS"
            title="Most popular searches"
            info=""
            data={searches}
          />,
        );
      }
      if (report.SHOPPING_GUIDE) {
        result.push(
          <AreaCard
            key="SHOPPING_GUIDE"
            title="Shopping guide views"
            data={report.SHOPPING_GUIDE}
            stacked
          />,
        );
      }

      if (report.SCAN) {
        const scans: Day[] = (report.SCAN as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label && ev.label.startsWith('BOARDING_PASS')) {
                label = 'BOARDING_PASS';
              } else if (ev.label && ev.label.startsWith('PRODUCT')) {
                label = 'PRODUCT';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(<AreaCard key="SCAN" title="Scans" data={scans} stacked />);

        const boardingPasses: Day[] = (report.SCAN as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label && ev.label.startsWith('BOARDING_PASS')) {
                const match = /^BOARDING_PASS \/ (\w+)/.exec(ev.label);
                label = match && match[1] ? mapCompartmentCode(match[1]) : 'Other';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <PieCard
            key="BOARDING_PASS"
            title="Boarding passes"
            info="Based on the fare basis code which is not standardized in any way"
            data={boardingPasses}
          />,
        );

        const productBarcodes: Day[] = (report.SCAN as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label && ev.label.startsWith('PRODUCT')) {
                const match = /^PRODUCT \/ __none__ \/ (\w+)/.exec(ev.label);
                label = match && match[1] ? match[1] : 'Unknown';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="PRODUCT_BARCODE"
            title="Product barcodes"
            data={productBarcodes}
          />,
        );
      }
      if (report.BARCODE) {
        result.push(
          <AreaCard key="BARCODE" title="Barcodes scanned" data={report.BARCODE} />,
        );
      }
      if (report.PHOTO_TAKEN) {
        result.push(
          <AreaCard key="PHOTO_TAKEN" title="Selfies taken" data={report.PHOTO_TAKEN} />,
        );
      }
      if (report.PRINT_SUCCESS) {
        result.push(
          <AreaCard
            key="SELFIES_PRINTED"
            title="Selfies printed"
            data={report.PRINT_SUCCESS}
          />,
        );
      }
      // if (report.VOUCHER_DISPENSED) {
      //     result.push(
      //       <AreaCard
      //         key="VOUCHER_DISPENSED"
      //         label="Vouchers dispensed"
      //         data={report.VOUCHER_DISPENSED}
      //       />,
      //     );
      // }
      if (report.ITEM_SHOWN) {
        result.push(
          <AreaCard key="ITEM_SHOWN" title="Items played" data={report.ITEM_SHOWN} />,
        );

        const tableData = toPairs(
          (report.ITEM_SHOWN as Day[]).reduce((acc, [, events]) => {
            events.forEach((ev) => {
              const label = ev.label ? ev.label.split(' / ') : null;
              const type = label ? label[0] : null;
              const name = label ? label[1] : null;

              if (type && name) {
                set(acc, [type, name], get(acc, [type, name], 0) + ev.value);
              }
            });

            return acc;
          }, {}),
        )
          .reduce(
            (acc, [type, values]: any) =>
              acc.concat(
                // @ts-ignore
                toPairs(values).map(([name, value]) => ({
                  key: name,
                  type,
                  name,
                  value,
                })),
              ),
            [],
          )
          .sort((a: any, b: any) => b.value - a.value);

        result.push(
          <TableCard key="ITEM_SHOWN_TABLE" label="Times played" data={tableData} />,
        );

        const tagsData: Day[] = toPairs<Event[]>(
          (report.ITEM_SHOWN as Day[]).reduce((acc, [day, events]) => {
            events.forEach((ev) => {
              const label = ev.label ? ev.label.split(' / ') : null;
              const tags: string[] = label && label[2] ? JSON.parse(label[2]) : [];

              tags.forEach((tag) => {
                set(acc, [day, tag], get(acc, [day, tag], 0) + ev.value);
              });
            });

            return acc;
          }, {}),
        ).map(([day, events]) => [
          day,
          toPairs(events).map(
            ([label, value]) => (({ label, value } as unknown) as Event),
          ),
        ]);

        result.push(<PieCard key="TAGS" title="Tagged items played" data={tagsData} />);
      }
      if (report.VIDEO_START) {
        result.push(
          <AreaCard
            key="VIDEO_START"
            label="Total Video Plays"
            title="Video Plays"
            data={report.VIDEO_START}
            stacked
          />,
        );
      }
      if (report.LANGUAGE_SWITCH) {
        result.push(
          <AreaCard
            key="LANGUAGE_SWITCH"
            label="Total Language Switch"
            title="Language Switch"
            data={report.LANGUAGE_SWITCH}
            stacked
          />,
        );
      }
      if (report.CLICK_START) {
        result.push(
          <AreaCard
            key="CLICK_START"
            label="Total Start Clicks"
            title="Start Clicks"
            data={report.CLICK_START}
          />,
        );
      }
      if (report.QUESTION) {
        result.push(
          <AreaCard
            key="QUESTION"
            label=""
            title="Questions"
            data={report.QUESTION}
            stacked
          />,
        );
      }
      if (report.IMAGE_CLICK) {
        result.push(
          <ListCard
            key="IMAGE_CLICK"
            title="Most Image Clicks"
            info=""
            data={report.IMAGE_CLICK}
          />,
        );
      }
      if (report.IMAGE_SWIPE) {
        result.push(
          <ListCard
            key="IMAGE_SWIPE"
            title="Most Image Swipes"
            info=""
            data={report.IMAGE_SWIPE}
          />,
        );
      }
      if (report.CONTENT_ITEM) {
        result.push(
          <PieCard
            key="CONTENT_ITEM"
            title="Content Clicks"
            info="Content item categories"
            data={report.CONTENT_ITEM}
          />,
        );
      }
      if (report.QUIZ_RESULTS) {
        result.push(
          <PieCard
            key="QUIZ_RESULTS"
            title="Quiz Results"
            info=""
            data={report.QUIZ_RESULTS}
          />,
        );
      }
      if (report.RESTART) {
        result.push(
          <AreaCard
            key="RESTART"
            label="Total Restarts"
            title="Restarts"
            data={report.RESTART}
          />,
        );
      }
      if (report.DROPOUT) {
        result.push(
          <PieCard key="DROPOUT" title="Dropout" info="" data={report.DROPOUT} />,
        );
      }
      if (report.QR_HANDOFF) {
        result.push(
          <AreaCard
            key="QR_HANDOFF"
            label="Total QR code scans"
            title="QR code scans"
            data={report.QR_HANDOFF}
          />,
        );
      }
      if (report.PAGE_VIEW) {
        result.push(
          <ListCard
            key="PAGE_VIEW"
            title="Most Number of Page Views"
            info=""
            data={report.PAGE_VIEW}
          />,
        );
      }

      if (report.SHOW_LOOK) {
        result.push(
          <ListCard
            key="SHOW_LOOK"
            title="Most shown looks"
            info=""
            data={report.SHOW_LOOK}
          />,
        );
      }

      // Grid Signals Events
      if (report.APP_START) {
        result.push(
          <PieCard
            key="APP_START"
            title="App starts per device"
            data={report.APP_START}
            showTotal={false}
          />,
        );
      }

      if (report.CONTENT_VIEW) {
        result.push(
          <PieCard key="CONTENT_VIEW" title="Content views" data={report.CONTENT_VIEW} />,
        );
      }

      if (report.CATEGORY_VIEW) {
        result.push(
          <PieCard
            key="CATEGORY_VIEW"
            title="Category views"
            data={report.CATEGORY_VIEW}
          />,
        );
      }

      if (report.PRODUCT_VIEW) {
        result.push(
          <PieCard key="PRODUCT_VIEW" title="Product views" data={report.PRODUCT_VIEW} />,
        );
      }

      if (report.PRODUCT_VIEW) {
        result.push(
          <PieCard key="PRODUCT_VIEW" title="Product views" data={report.PRODUCT_VIEW} />,
        );
      }

      if (report.BARCODE_SCAN) {
        result.push(
          <PieCard
            key="BARCODE_SCAN"
            title="Product barcode scans"
            data={report.BARCODE_SCAN}
          />,
        );
      }

      if (report.RFID_SCAN) {
        result.push(
          <PieCard key="RFID_SCAN" title="Product rfid scans" data={report.RFID_SCAN} />,
        );
      }

      if (report.CART_ADD) {
        result.push(
          <AreaCard
            key="CART_ADD"
            title="Items added to shopping bag"
            data={report.CART_ADD}
          >
            {report.SESSION_START && (
              <div style={{ flex: 1 }}>
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Total items added to shopping bag"
                  value={sumDays(report.CART_ADD)}
                />
                <LabeledValue
                  style={{ marginBottom: 16 }}
                  size="md"
                  label="Average number of items added to shopping bag per session"
                  value={Math.ceil(
                    sumDays(report.CART_ADD) / sumDays(report.SESSION_START),
                  )}
                />
              </div>
            )}
          </AreaCard>,
        );

        // Insert added to bag product logic here
        const products: Day[] = (report.CART_ADD as Day[]).map(([day, events]) => [
          day,
          toPairs(
            events.reduce((acc: any, ev) => {
              let label = '';

              if (ev.label) {
                const splitLabel = ev.label.split('/');
                label = splitLabel.length > 0 ? splitLabel[0] : '';
              }

              if (label) {
                acc[label] = (acc[label] || 0) + ev.value;
              }

              return acc;
            }, {}),
          ).map(([label, value]): Event => ({ label, value: value as number })),
        ]);

        result.push(
          <ListCard
            key="CART_ADD_PRODUCTS"
            title="Most popular products added to shopping bag"
            info=""
            data={products}
          />,
        );
      }

      if (report.LOOK_VIEW) {
        result.push(
          <AreaCard key="LOOK_VIEW" title="Look views" data={report.LOOK_VIEW} />,
        );
      }

      if (report.CART_VIEW) {
        result.push(
          <AreaCard key="CART_VIEW" title="Cart views" data={report.CART_VIEW} />,
        );
      }

      if (report.CART_REMOVE) {
        result.push(
          <AreaCard key="CART_REMOVE" title="Cart removes" data={report.CART_REMOVE} />,
        );
      }

      if (report.CART_CLEAR) {
        result.push(
          <AreaCard key="CART_CLEAR" title="Cart clears" data={report.CART_CLEAR} />,
        );
      }

      if (report.PURCHASE) {
        result.push(
          <AreaCard key="PURCHASE" title="Purchases count" data={report.PURCHASE} />,
        );
      }

      if (report.QR_RUN) {
        result.push(<AreaCard key="QR_RUN" title="Qr run counts" data={report.QR_RUN} />);
      }

      if (report.CONTACT_IDENTIFY) {
        result.push(
          <AreaCard
            key="CONTACT_IDENTIFY"
            title="Contact identification counts"
            data={report.CONTACT_IDENTIFY}
          />,
        );
      }

      if (report.DETECT_MOOD) {
        result.push(
          <PieCard
            key="DETECT_MOOD"
            title="Mood"
            data={report.DETECT_MOOD}
            showTotal={false}
          />,
        );
      }

      if (report.DETECT_AGE) {
        result.push(
          <PieCard
            key="DETECT_AGE"
            title="Age"
            data={report.DETECT_AGE}
            showTotal={false}
          />,
        );
      }

      if (report.DETECT_GENDER) {
        result.push(
          <PieCard
            key="DETECT_GENDER"
            title="Gender"
            data={report.DETECT_GENDER}
            showTotal={false}
          />,
        );
      }

      if (report.RATING) {
        result.push(
          <PieCard key="RATING" title="Rating" data={report.RATING} showTotal={false} />,
        );
      }

      if (report.FEEDBACK) {
        result.push(
          <AreaCard key="FEEDBACK" title="Feedback counts" data={report.FEEDBACK} />,
        );
      }
    }

    return result;
  }, [report]);

  return (
    <ErrorBoundary>
      {error && <ErrorView />}
      {loading && !error && (
        <Overlay
          style={{ backgroundColor: 'rgba(251, 251, 253, 0.5)', zIndex: 1, top: 80 }}
        >
          <Spinner />
        </Overlay>
      )}
      {report && !error && (
        <Container>
          {cards.length > 0 ? (
            cards
          ) : (
            <Empty description="No analytics data" css={{ gridColumn: '1/-1' }} />
          )}
        </Container>
      )}
    </ErrorBoundary>
  );
};

export default OverviewReport;

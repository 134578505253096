import React from 'react';
import { ResponsiveContainer, BarChart, Bar, Tooltip, XAxis, YAxis } from 'recharts';

export default function VerticalBar({
  title,
  data,
}: {
  title: string;
  data: { label: string; value: number }[];
}) {
  const HEIGHT_PER_ITEM = 40; // The vertical space each bar + gap takes up
  const ITEM_COUNT = data.length;
  const CHART_HEIGHT = HEIGHT_PER_ITEM * ITEM_COUNT + HEIGHT_PER_ITEM;

  return (
    <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
      <BarChart
        data={data}
        height={CHART_HEIGHT}
        layout="vertical"
        margin={{ top: 0, right: 30, left: 5, bottom: 20 }}
        barCategoryGap={0}
        barGap={0}
      >
        <XAxis type="number" tick={{ fontSize: 12, fill: '#666' }} stroke="#ccc" />
        <YAxis
          type="category"
          dataKey="label"
          // padding={{ top: chartPadding, bottom: 0 }}
          tick={{
            fontSize: 12,
            fill: '#666',
            textAnchor: 'start',
            dx: -140,
          }}
          tickFormatter={(value) =>
            value.length > 19 ? `${value.substring(0, 17)}...` : value
          }
          width={144}
          stroke="#ccc"
        />
        <Tooltip
          formatter={(value, name, props) => {
            const total = data.reduce((sum, item) => sum + item.value, 0); // Total of all values
            const numericValue =
              typeof value === 'number' ? value : parseFloat(value as string); // Ensure value is a number
            const percentage = ((numericValue / total) * 100).toFixed(2); // Calculate percentage
            return [`${numericValue} (${percentage}%)`, name]; // Return value with percentage
          }}
          cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} // Highlight the hovered bar
          contentStyle={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 4,
            border: '1px solid #ccc',
          }}
        />
        <Bar dataKey="value" fill="#69C0FF" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
}

import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import Header from '../../../common/app-layout/header/header.component';
import { ApiError } from '../../../../services/api/api-error';
import DevicesUniversalList from '../../../common/universal-devices/devices-universal-list/devices-universal-list.component';
import UniversalDevice from '../../../../store/types/universal-device';
import {
  PaginationProps,
  PaginationSearchParam,
} from '../../../../store/types/pagination';
import DevicesReport from '../../../common/devices-report/devices-report';
import OrganisationApp from '../../../../store/types/organisation-app';
import OrganisationSpace from '../../../../store/types/organisation-space';
import SearchBar, {
  SearchBarWrap,
} from '../../../common/search-bar/search-bar.component';
import { SortCriteria, SortOrder } from '../../../../store/types/sort';
import { ActiveFilter } from '../../../../store/types/filters';
import usePagination from '../../../common/pagination/hook/use-pagination';
import useLayoutView from '../../../common/layout-view/use-layout-view';
import useFilterOptions from '../../../common/use-filter-options/use-filter-options';
import UniversalDeviceType from '../../../../store/types/universal-device-type.enum';

interface DevicesListProps
  extends RouteComponentProps<{ organisationId: string }>,
    PaginationProps {
  apps: OrganisationApp[];
  devices: UniversalDevice[];
  loaded: boolean;
  isLoading?: boolean;
  fetchDevices: ({
    organizationId,
    silent,
    searchParam,
    page,
    pageSize,
    deviceType,
  }: {
    organizationId: string;
    silent?: boolean;
    searchParam?: PaginationSearchParam;
    page: number;
    pageSize: number;
    deviceType?: string[];
  }) => Promise<void>;
  lastUpdated?: Date;
  error: ApiError | null;
  spaces: OrganisationSpace[];
}

const OrganisationDevicesList = (props: DevicesListProps) => {
  const {
    match: {
      params: { organisationId: organizationId },
    },
  } = props;
  const {
    apps,
    devices,
    loaded,
    isPaginationLoading,
    fetchDevices,
    lastUpdated,
    error,
    pagination,
    spaces,
  } = props;

  const { t } = useTranslation();
  const { pageSize: pageSizeNum, defaultPage } = usePagination();
  const { layoutView, setLayoutView } = useLayoutView('list');
  const { filterOptions, sortCriteriaOptions } = useFilterOptions({ apps, spaces });

  const fetchOrganisationDevices = useCallback(
    async ({
      silent,
      searchParam,
      page,
      pageSize,
    }: {
      silent: boolean;
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    }) => {
      await fetchDevices({
        organizationId,
        silent,
        searchParam,
        page,
        pageSize,
        deviceType: [
          UniversalDeviceType.IOTHUB,
          UniversalDeviceType.BROWSER,
          UniversalDeviceType.TIZEN,
        ],
      });
    },
    [organizationId, fetchDevices],
  );

  const handleSearch = useCallback(
    (value: string): void => {
      const val = value.trim();
      const searchParam = {
        deviceName: val,
        deviceSerial: val,
      };

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchOrganisationDevices({
        silent: true,
        searchParam: pagination ? { ...pagination.param, ...searchParam } : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchOrganisationDevices, pageSizeNum, pagination],
  );

  const handleFilterChange = useCallback(
    (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null): void => {
      const filterTypeIds = groupBy(filters, 'typeId');
      const mappedFilterParams = map(filterTypeIds, (value, id) => {
        return { [id]: Object.keys(keyBy(value, 'valueId')) };
      });

      if (
        lastRemovedFilter &&
        !Object.keys(filterTypeIds).includes(lastRemovedFilter.typeId)
      ) {
        const id = lastRemovedFilter.typeId;
        mappedFilterParams.push({ [id]: [] });
      }

      fetchOrganisationDevices({
        silent: true,
        searchParam: pagination
          ? Object.assign(pagination.param || {}, ...mappedFilterParams)
          : undefined,
        page: defaultPage,
        pageSize: pageSizeNum,
      });
    },
    [defaultPage, fetchOrganisationDevices, pageSizeNum, pagination],
  );

  const handleSortChange = useCallback(
    (criteria: SortCriteria, order: SortOrder) => {
      const orderPrefix = order.id === 'asc' ? '+' : '-';
      const sortBy = `${orderPrefix}${criteria.id}`;

      fetchOrganisationDevices({
        silent: true,
        searchParam: pagination ? { ...pagination.param, sortBy } : undefined,
        page: defaultPage,
        pageSize: pageSizeNum,
      });
    },
    [defaultPage, fetchOrganisationDevices, pageSizeNum, pagination],
  );

  return (
    <>
      <Header title="Legacy devices library" />
      {loaded && (
        <SearchBarWrap>
          <SearchBar
            searchInputProps={{
              placeholder: t('searchDevicePlaceholder'),
              onSearch: handleSearch,
            }}
            searchInputFilterProps={{
              filterOptions,
              onChange: handleFilterChange,
            }}
            sortProps={{
              sortCriteria: sortCriteriaOptions,
              defaultCriteria: 'displayName',
              onChange: handleSortChange,
            }}
            layoutViewProps={{
              onChange: setLayoutView,
            }}
          />
        </SearchBarWrap>
      )}
      <div className="content-body">
        <div>
          <DevicesReport tenantId={organizationId} />
        </div>
        <DevicesUniversalList
          apps={apps}
          organizationId={organizationId}
          error={error}
          lastUpdated={lastUpdated}
          devices={devices}
          loaded={loaded}
          fetchDevices={fetchOrganisationDevices}
          showLegacyWindowsOption
          isPaginationLoading={isPaginationLoading}
          pagination={pagination}
          layout={layoutView}
        />
      </div>
    </>
  );
};

export default OrganisationDevicesList;

import { useQuery } from 'react-query';
import phyhubTwinsQueryKeys from './phyhub-twins-query-keys';
import { PhyhubTwinsListParamsFilters } from '../../../services/phyhub/types/phyhub-twin-list-params.interface';
import { usePhyhubService } from '../../../app/common/phyhub-service-provider/phyhub-service.provider';

export interface PhyhubTwinsListQueryParams {
  tenantId: string;
  page?: number;
  limit?: number;
  filters?: PhyhubTwinsListParamsFilters;
}

const usePhyhubTwinsList = (phyhubTwinsListParams: PhyhubTwinsListQueryParams) => {
  const phyhubService = usePhyhubService();

  return useQuery(
    phyhubTwinsQueryKeys.getPhyhubTwinsListKeys(phyhubTwinsListParams),
    () =>
      phyhubService.listTwins({
        tenantId: phyhubTwinsListParams.tenantId,
        page: phyhubTwinsListParams.page,
        limit: phyhubTwinsListParams.limit,
        filters: phyhubTwinsListParams.filters,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    },
  );
};

export default usePhyhubTwinsList;

import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Col, Row } from 'antd';
import { PhyhubDevice } from '../../../../../services/phyhub/types/phyhub-device.interface';
import PhyhubDeviceDetailsFormFields from './phyhub-device-details-screen-form/phyhub-device-details-form-fields/phyhub-device-details-form-fields.component';
import Environment from '../../../../../store/types/environment';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import PhyhubDeviceInfo from '../../../../common/phyhub-devices/phyhub-device-card/phyhub-device-card.component';
import PhyhubDeviceDetailsScreenForm, {
  PhyhubDeviceDetailsFormValues,
  PhyhubDeviceDetailsScreenPropertiesFormValues,
} from './phyhub-device-details-screen-form/phyhub-device-details-screen-form.component';
import { BlockContainer } from '../../../../common/phyhub-devices/phyhub-devices-common.components';
import FormBlockCard, {
  FormBlockCardHeaderTheme,
} from '../../../../common/form-block-card/form-block-card.component';
import PhyhubDeviceDetailsScreenSettingsFormFields from './phyhub-device-details-screen-form/phyhub-device-details-screen-settings-form-fields/phyhub-device-details-screen-settings-form-fields.component';
import { ContentTagType } from '../../../../../store/common/content-tags/content-tag-type';

interface PhyhubDeviceDetailsScreenProps {
  deviceItem: PhyhubDevice;
  environments: Environment[];
  spaces: OrganisationSpace[];
  onFormSubmit: (values: PhyhubDeviceDetailsFormValues) => Promise<void>;
}

const PhyhubDeviceDetailsScreen: FC<PhyhubDeviceDetailsScreenProps> = (props) => {
  const { deviceItem, environments, spaces } = props;

  const { t } = useTranslation();

  const {
    id,
    deviceSerial,
    displayName,
    env,
    spaceId,
    externalId,
    timezone,
    dailyRebootTime,
    notes,
    screenshotUrl,
    status,
    tenantId,
    screen,
    tags,
  } = deviceItem;

  const initialFormValues = useMemo<PhyhubDeviceDetailsFormValues>(() => {
    let screenProperties: PhyhubDeviceDetailsScreenPropertiesFormValues | null = null;

    if (screen) {
      screenProperties = {
        scale: screen.scale,
        screenResolution: screen.screenResolution,
        screenOrientation: screen.screenOrientation,
        soundInput: screen.soundInput,
        soundOutput: screen.soundOutput,
      };
    }

    return {
      deviceSerial,
      displayName,
      env,
      spaceId,
      externalId,
      timezone,
      dailyRebootTime,
      notes,
      deviceId: id,
      screen: screenProperties,
      tags: tags.map((tag) => ({
        tagId: tag.tagId,
        tagType: ContentTagType.CONTENT_TAG,
        values: tag.values,
      })),
    };
  }, [
    id,
    deviceSerial,
    displayName,
    env,
    spaceId,
    externalId,
    timezone,
    dailyRebootTime,
    notes,
    screen,
    tags,
  ]);

  return (
    <PhyhubDeviceDetailsScreenForm
      initialFormValues={initialFormValues}
      onFormSubmit={props.onFormSubmit}
    >
      {({ isSubmitting, updateFormValues }) => (
        <Wrapper>
          <Row gutter={{ md: 20, xl: 40 }}>
            <Col xs={24} xl={15}>
              <Row gutter={{ md: 10, xl: 20 }}>
                <Col xs={24}>
                  <FormBlockCard
                    header={{
                      theme: FormBlockCardHeaderTheme.PRIMARY,
                      title: t('phyhubDevices.title.deviceSettings'),
                    }}
                  >
                    <PhyhubDeviceDetailsFormFields
                      tenantId={tenantId}
                      environments={environments}
                      spaces={spaces}
                      updateFormValues={updateFormValues}
                    />
                  </FormBlockCard>
                </Col>
                {!!initialFormValues.screen && (
                  <Col xs={24}>
                    <FormBlockCard
                      header={{
                        theme: FormBlockCardHeaderTheme.SECONDARY,
                        title: t('phyhubDevices.title.screen'),
                      }}
                    >
                      <PhyhubDeviceDetailsScreenSettingsFormFields />
                    </FormBlockCard>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={24} xl={9}>
              <FormBlockCard>
                <Sidebar>
                  <PhyhubDeviceInfo
                    deviceDisplayName={displayName}
                    deviceScreenshotUrl={screenshotUrl}
                    deviceStatus={status}
                  />
                  <Button
                    block
                    loading={isSubmitting}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    {t('saveAllChanges')}
                  </Button>
                </Sidebar>
              </FormBlockCard>
            </Col>
          </Row>
        </Wrapper>
      )}
    </PhyhubDeviceDetailsScreenForm>
  );
};

const Wrapper = styled(BlockContainer)`
  margin: 0 0 20px;

  @media screen and (min-width: 992px) {
    margin: 0 0 40px;
  }
`;

const Sidebar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default memo(PhyhubDeviceDetailsScreen);

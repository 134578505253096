import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Icon, Menu, message, Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import OrganisationApp from '../../../../../store/types/organisation-app';
import UniversalDevice from '../../../../../store/types/universal-device';
import DevicesUniversalList from '../../../../common/universal-devices/devices-universal-list/devices-universal-list.component';
import EmptyDeviceView from './empty-device-universal-view/empty-device-universal-view.component';
import ConnectSetupDeviceUniversalModal from './connect-setup-device-universal-modal/connect-setup-device-universal-modal.component';
import Environment from '../../../../../store/types/environment';
import { DeviceCreateParams } from '../../../../../store/models/app-devices/app-devices.model';
import { UniversalDeviceConnectParams } from '../../../../../store/models/app-devices-universal/app-devices-universal.model';
import DeviceTypeEnum from '../../../../../store/types/device-type.enum';
import {
  PaginationProps,
  PaginationSearchParam,
} from '../../../../../store/types/pagination';
import DevicesReport from '../../../../common/devices-report/devices-report';
import OrganisationSpace from '../../../../../store/types/organisation-space';
import SearchBar, {
  SearchBarWrap,
} from '../../../../common/search-bar/search-bar.component';
import useLayoutView from '../../../../common/layout-view/use-layout-view';
import usePagination from '../../../../common/pagination/hook/use-pagination';
import { ActiveFilter } from '../../../../../store/types/filters';
import { SortCriteria, SortOrder } from '../../../../../store/types/sort';
import useFilterOptions from '../../../../common/use-filter-options/use-filter-options';
import ContentWrap from '../../../../common/app-layout/content/content-wrap.component';
import UniversalDeviceType from '../../../../../store/types/universal-device-type.enum';

interface DevicesProps
  extends RouteComponentProps<{ appId: string; organisationId: string }>,
    PaginationProps {
  app: OrganisationApp | null;
  apps?: OrganisationApp[];
  devices: UniversalDevice[];
  loaded: boolean;
  fetchDevices: (params: {
    appName: string;
    silent?: boolean;
    searchParam?: PaginationSearchParam;
    page: number;
    pageSize: number;
    deviceType?: string[];
  }) => Promise<void>;
  lastUpdated?: Date;
  canCreate: boolean;
  createDevice: (params: DeviceCreateParams) => Promise<void>;
  fetchEnvironments: (params: { organizationId: string }) => void;
  environments: Environment[];
  disconnectDevice: (params: UniversalDeviceConnectParams) => Promise<void>;
  includeLegacyWindows?: boolean;
  spaces?: OrganisationSpace[];
}

const DevicesUniversal = (props: DevicesProps) => {
  const {
    apps,
    devices,
    loaded,
    isPaginationLoading,
    fetchDevices,
    lastUpdated,
    app,
    canCreate,
    fetchEnvironments,
    match: {
      params: { organisationId, appId },
    },
    disconnectDevice,
    pagination,
    spaces = [],
  } = props;
  const [isConnectOrSetupModalVisible, setIsConnectOrSetupModalVisible] = useState(false);
  const [hasDevice, setHasDevice] = useState<boolean>(false);

  const { t } = useTranslation();
  const { pageSize: pageSizeNum, defaultPage } = usePagination();
  const { layoutView, setLayoutView } = useLayoutView('list');
  const { filterOptions, sortCriteriaOptions } = useFilterOptions({ apps, spaces });

  const handleConnectOrSetupModalOpen = useCallback(() => {
    setIsConnectOrSetupModalVisible(true);
  }, []);

  const handleConnectOrSetupModalClose = useCallback(() => {
    setIsConnectOrSetupModalVisible(false);
  }, []);

  const fetchAppDevices = useCallback(
    async ({
      silent,
      searchParam,
      page,
      pageSize,
    }: {
      silent: boolean;
      searchParam?: PaginationSearchParam;
      page: number;
      pageSize: number;
    }) => {
      if (app) {
        await fetchDevices({
          appName: app.appName,
          silent,
          searchParam,
          page,
          pageSize,
          deviceType: [
            UniversalDeviceType.IOTHUB,
            UniversalDeviceType.BROWSER,
            UniversalDeviceType.TIZEN,
          ],
        });
      }
    },
    [app, fetchDevices],
  );

  useEffect(() => {
    fetchEnvironments({ organizationId: organisationId });
  }, [fetchEnvironments, organisationId]);

  useEffect(() => {
    if (devices.length) {
      setHasDevice(true);
    }
  }, [devices]);

  const handleDeviceDisconnectClick = useCallback(
    async (deviceId: string) => {
      const device = devices.find((dev) => deviceId === dev.id);

      if (app && device) {
        Modal.confirm({
          title: t('confirmAction'),
          content: (
            <Trans
              i18nKey="areYouSureYouWantToDisconnectDevice"
              values={{
                deviceName: device.deviceName,
                appName: app.appName,
              }}
              components={{ strong: <strong /> }}
            />
          ),
          width: '40%',
          onOk: async () => {
            try {
              await disconnectDevice({
                appId: app.id,
                deviceId,
                deviceType: 'iot',
              });
              message.success(t('successfullyDisconnectedDevice'));
            } catch (err) {
              message.error(t('failedToDisconnectDevice'));
            }
          },
          onCancel: () => {},
        });
      }
    },
    [app, devices, disconnectDevice, t],
  );

  const renderIotExtraActions = useCallback(
    (deviceId: string) => (
      <Menu>
        <Menu.Item
          onClick={({ domEvent }) => {
            domEvent.preventDefault();
            return handleDeviceDisconnectClick(deviceId);
          }}
          key="disconnect"
        >
          <span>
            <Icon type="disconnect" />
            <span>{t('disconnectDevice')}</span>
          </span>
        </Menu.Item>
      </Menu>
    ),
    [handleDeviceDisconnectClick, t],
  );

  const handleSearch = useCallback(
    (value: string): void => {
      const val = value.trim();
      const searchParam = {
        deviceName: val,
        deviceSerial: val,
      };

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchAppDevices({
        silent: true,
        searchParam: pagination ? { ...pagination.param, ...searchParam } : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchAppDevices, pageSizeNum, pagination],
  );

  const handleFilterChange = useCallback(
    (filters: ActiveFilter[], lastRemovedFilter?: ActiveFilter | null): void => {
      const filterTypeIds = groupBy(filters, 'typeId');
      const mappedFilterParams = map(filterTypeIds, (value, id) => {
        return { [id]: Object.keys(keyBy(value, 'valueId')) };
      });

      if (
        lastRemovedFilter &&
        !Object.keys(filterTypeIds).includes(lastRemovedFilter.typeId)
      ) {
        const id = lastRemovedFilter.typeId;
        mappedFilterParams.push({ [id]: [] });
      }

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchAppDevices({
        silent: true,
        searchParam: pagination
          ? Object.assign(pagination.param || {}, ...mappedFilterParams)
          : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchAppDevices, pageSizeNum, pagination],
  );

  const handleSortChange = useCallback(
    (criteria: SortCriteria, order: SortOrder) => {
      const orderPrefix = order.id === 'asc' ? '+' : '-';
      const sortBy = `${orderPrefix}${criteria.id}`;

      const pageLimit = (pagination && pagination.limit) || pageSizeNum;

      fetchAppDevices({
        silent: true,
        searchParam: pagination ? { ...pagination.param, sortBy } : undefined,
        page: defaultPage,
        pageSize: pageLimit,
      });
    },
    [defaultPage, fetchAppDevices, pageSizeNum, pagination],
  );

  if (!app) {
    return null;
  }

  const renderExtraActions =
    app.deviceType === DeviceTypeEnum.IOT ? renderIotExtraActions : undefined;

  return (
    <>
      {loaded && hasDevice && (
        <SearchBarWrap>
          <SearchBar
            searchInputProps={{
              placeholder: t('searchDevicePlaceholder'),
              onSearch: handleSearch,
            }}
            searchInputFilterProps={{
              filterOptions,
              onChange: handleFilterChange,
            }}
            sortProps={{
              sortCriteria: sortCriteriaOptions,
              defaultCriteria: 'displayName',
              onChange: handleSortChange,
            }}
            layoutViewProps={{
              onChange: setLayoutView,
            }}
            extra={
              canCreate && (
                <ConnectDeviceButton
                  size="large"
                  icon="api"
                  onClick={handleConnectOrSetupModalOpen}
                  data-testid="devices-universal-connect-device-button"
                >
                  {t('connectDevice')}
                </ConnectDeviceButton>
              )
            }
          />
        </SearchBarWrap>
      )}
      <ContentWrap>
        <div>
          <DevicesReport tenantId={organisationId} installationId={appId} />
        </div>
        {loaded && !hasDevice && (
          <EmptyDeviceView
            app={app}
            onConnectDevice={handleConnectOrSetupModalOpen}
            canCreate={canCreate}
          />
        )}
        {(!loaded || hasDevice) && (
          <DevicesUniversalList
            apps={apps}
            organizationId={organisationId}
            error={undefined}
            isPolling={!isConnectOrSetupModalVisible}
            lastUpdated={lastUpdated}
            devices={devices}
            loaded={loaded}
            fetchDevices={fetchAppDevices}
            defaultShowAll
            defaultShowLegacyWindowsDevice={app.deviceType === DeviceTypeEnum.IOT}
            renderExtraActions={renderExtraActions}
            isPaginationLoading={isPaginationLoading}
            pagination={pagination}
            layout={layoutView}
          />
        )}
        <ConnectSetupDeviceUniversalModal
          {...props}
          isVisible={isConnectOrSetupModalVisible}
          onClose={handleConnectOrSetupModalClose}
          app={app}
        />
      </ContentWrap>
    </>
  );
};

const ConnectDeviceButton = styled(Button)`
  height: inherit;
` as any;

export default DevicesUniversal;

import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { Button, message } from 'antd';
import styled from '@emotion/styled';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AjvError, FieldProps, FormValidation } from 'react-jsonschema-form';
import SchemaForm, {
  SchemaFormRef,
} from '../../../../../../common/schema-form/schema-form.component';
import Environment from '../../../../../../../store/types/environment';
import OrganisationApp from '../../../../../../../store/types/organisation-app';
import DeviceTypeEnum from '../../../../../../../store/types/device-type.enum';
import OrganisationSpace from '../../../../../../../store/types/organisation-space';
import DeviceNameInputValidation, {
  DeviceNameInputValidationRef,
} from '../../../../../../common/schema-form/internal-widgets/device-name-input-validation/device-name-input-validation.component';
import User from '../../../../../../../store/types/user';
import { getPhyhubApiErrorMessage } from '../../../../../../../services/phyhub/utils/get-phyhub-api-error-message.util';

export interface SetupPhyhubDeviceFormValues {
  type: string;
  env: string;
  spaceId: string;
  deviceSerial?: string; // Optional since it's not present in every object
  displayName: string;
  code?: string; // Optional since it's not present in every object
}

interface SetupPhyhubDeviceFormProps
  extends RouteComponentProps<{ organisationId: string; appId: string }> {
  onSelectedDeviceTypeChange: (type: DeviceOrPlatformTypeEnum) => void;
  createDevice: (params: SetupPhyhubDeviceFormValues) => Promise<void>;
  fetchEnvironments: () => void;
  fetchSpaces: () => void;
  spaces: OrganisationSpace[];
  environments: Environment[];
  onClose: () => void;
  app: OrganisationApp | null;
  user: User | null;
}

export enum DeviceOrPlatformTypeEnum {
  INTEL = 'intel',
  GIADA_DN74 = 'giada_dn74',
  TIZEN = 'tizen',
  BROWSER = 'browser',
}

const enumNames = {
  [DeviceOrPlatformTypeEnum.INTEL]: 'PhyOS x86-64',
  [DeviceOrPlatformTypeEnum.GIADA_DN74]: 'PhyOS Giada DN74',
  [DeviceOrPlatformTypeEnum.BROWSER]: 'Generic browser',
  [DeviceOrPlatformTypeEnum.TIZEN]: 'Samsung Tizen TV',
};

const downloadLinks: { [type: string]: string } = {
  [DeviceOrPlatformTypeEnum.INTEL]: 'https://qr.run/phyos-bundle-amd64-latest',
  [DeviceOrPlatformTypeEnum.GIADA_DN74]: 'https://qr.run/phyos-bundle-arm64-latest',
};

const defaultDeviceType = DeviceOrPlatformTypeEnum.INTEL;
const iotAppSupportedDeviceTypes = [
  DeviceOrPlatformTypeEnum.INTEL,
  DeviceOrPlatformTypeEnum.GIADA_DN74,
];

// const screenAppSupportedDeviceTypes = Object.values(DeviceOrPlatformTypeEnum);
const screenAppSupportedDeviceTypes = [
  DeviceOrPlatformTypeEnum.INTEL,
  DeviceOrPlatformTypeEnum.GIADA_DN74,
];

export default function SetupPhyhubDeviceForm({
  onSelectedDeviceTypeChange,
  createDevice,
  fetchEnvironments,
  environments,
  onClose,
  app,
  spaces,
  match: {
    params: { organisationId },
  },
}: SetupPhyhubDeviceFormProps) {
  const [isConnectButtonLoading, setIsConnectButtonLoading] = useState(false);
  const { t } = useTranslation();
  const formRef = useRef<SchemaFormRef>(null);
  const [formData, setFormData] = useState<
    Partial<SetupPhyhubDeviceFormValues> | undefined
  >(undefined);
  const type = useMemo(() => (formData ? formData.type : ''), [formData]);

  const refInputValidation = useRef() as React.RefObject<DeviceNameInputValidationRef>;

  useEffect(() => {
    fetchEnvironments();
    setFormData({ type: defaultDeviceType });
    onSelectedDeviceTypeChange(defaultDeviceType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormDataChange = useCallback(
    (data: Partial<SetupPhyhubDeviceFormValues>) => {
      setFormData((prevData) => {
        if (data && prevData && prevData.type !== data.type) {
          onSelectedDeviceTypeChange(data.type as DeviceOrPlatformTypeEnum);
        }

        return data;
      });
    },
    [onSelectedDeviceTypeChange],
  );

  const handleFormSubmit = useCallback(
    async (data: SetupPhyhubDeviceFormValues) => {
      setIsConnectButtonLoading(true);
      try {
        await createDevice(data);
        onClose();
        message.success(t('successfullyConnectedDevice'));
      } catch (error) {
        setIsConnectButtonLoading(false);

        message.error(getPhyhubApiErrorMessage(error, t('failedToConnectDevice')));
      }
    },
    [createDevice, onClose, t],
  );

  const onConnect = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  const uiSchema = useMemo(() => {
    return {
      displayName: {
        'ui:widget': (widgetProps: FieldProps) => {
          return <DeviceNameInputValidation ref={refInputValidation} {...widgetProps} />;
        },
      },
    };
  }, [refInputValidation]);

  const schema = useMemo(() => {
    const supportedDeviceTypes =
      app && app.deviceType === DeviceTypeEnum.IOT
        ? iotAppSupportedDeviceTypes
        : screenAppSupportedDeviceTypes;

    const parentSpaces = spaces.filter((space) => !space.parentSpaceId);

    return {
      definitions: {
        displayName: {
          type: 'string',
          minLength: 1,
          title: t('name'),
          pattern: '^([a-z][a-z0-9]*)(-[a-z0-9]+)*$',
        },
        spaceId: {
          type: 'string',
          title: t('space'),
          enum: parentSpaces.map((space) => space.id),
          enumNames: parentSpaces.map((space) => space.displayName),
          ...(parentSpaces && parentSpaces[0] ? { default: parentSpaces[0].id } : {}),
        },
        env: {
          type: 'string',
          minLength: 1,
          title: t('environment'),
          enum: environments.map((env) => env.environmentName),
          enumNames: environments.map((env) => env.displayName),
          default: 'prod',
        },
      },
      type: 'object',
      properties: {
        type: {
          type: 'string',
          minLength: 1,
          title: t('deviceType'),
          enum: supportedDeviceTypes,
          enumNames: supportedDeviceTypes.map(
            (deviceType: DeviceOrPlatformTypeEnum) => enumNames[deviceType] || 'UNKNOWN',
          ),
          default: defaultDeviceType,
        },
      },
      dependencies: {
        type: {
          oneOf: [
            {
              properties: {
                type: {
                  enum: [
                    DeviceOrPlatformTypeEnum.INTEL,
                    DeviceOrPlatformTypeEnum.GIADA_DN74,
                    DeviceOrPlatformTypeEnum.TIZEN,
                  ],
                },
                env: { $ref: '#/definitions/env' },
                spaceId: { $ref: '#/definitions/spaceId' },
                displayName: { $ref: '#/definitions/displayName' },
                deviceSerial: {
                  type: 'string',
                  minLength: 1,
                  title: t('deviceSerialOrCode'),
                },
              },
              required: ['env', 'displayName', 'deviceSerial', 'spaceId'],
            },
            {
              properties: {
                type: {
                  enum: [DeviceOrPlatformTypeEnum.BROWSER],
                },
                env: { $ref: '#/definitions/env' },
                spaceId: { $ref: '#/definitions/spaceId' },
                displayName: { $ref: '#/definitions/displayName' },
                code: {
                  type: 'string',
                  minLength: 4,
                  maxLength: 4,
                  title: t('fourLetterCode'),
                },
              },
              required: ['env', 'displayName', 'code', 'spaceId'],
            },
          ],
        },
      },
      required: ['type'],
    };
  }, [app, t, spaces, environments]);

  const { downloadButtonLabel, downloadLink } = useMemo(() => {
    let label = '';

    if (type) {
      switch (type) {
        case DeviceOrPlatformTypeEnum.INTEL:
          label = t('downloadPhyOSForIntel');
          break;
        case DeviceOrPlatformTypeEnum.GIADA_DN74:
          label = t('downloadPhyOSForGiadaDN74');
          break;
        case DeviceOrPlatformTypeEnum.TIZEN:
        case DeviceOrPlatformTypeEnum.BROWSER:
          break;
        default:
          label = '';
      }
    }
    return {
      downloadButtonLabel: label,
      downloadLink: type ? downloadLinks[type] || '' : '',
    };
  }, [t, type]);

  const handleModalFormValidation = useCallback(
    (value: any, formError: FormValidation) => {
      const elem = refInputValidation.current && refInputValidation.current.input;

      const validationMessage = elem && (elem.props as any)['data-error-message'];
      const isValidatingDeviceName = elem && (elem.props as any)['data-validating'];

      if (validationMessage) {
        formError.displayName.addError(validationMessage);
      }

      if (isValidatingDeviceName) {
        formError.displayName.addError(t('validating'));
      }

      return formError;
    },
    [refInputValidation, t],
  );

  const transformErrors = useCallback(
    (errors: AjvError[]) => {
      return errors.map((error) => {
        if (error.property === '.displayName' && error.name === 'pattern') {
          error.message = t('mustBeInSmallLettersAndKebabCase');
          error.params = { ...error.params, isCustomMessage: true };
        }

        return error;
      });
    },
    [t],
  );

  if (!app) {
    return null;
  }

  return (
    <>
      <SchemaForm
        ref={formRef}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={handleFormSubmit}
        onChange={handleFormDataChange}
        data={formData}
        customFormValidation={handleModalFormValidation}
        transformErrors={transformErrors}
        formContext={{ organisationId }}
      />
      <ActionsWrapper>
        {downloadButtonLabel && (
          <DownloadButton
            href={downloadLink}
            type="secondary"
            size="large"
            icon="download"
          >
            {downloadButtonLabel}
          </DownloadButton>
        )}
        <ConnectButton
          loading={isConnectButtonLoading}
          onClick={onConnect}
          type="primary"
          size="large"
          icon="api"
        >
          {t('deviceConnect')}
        </ConnectButton>
      </ActionsWrapper>
    </>
  );
}

const ActionsWrapper = styled.div`
  margin-top: 24px;
`;

const ConnectButton = styled(Button)`
  width: 100%;
` as any;

const DownloadButton = styled(ConnectButton)`
  margin-bottom: 12px;
` as any;

import React, { FC, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import PhyhubDevicesContainer from '../../../common/phyhub-devices/phyhub-devices.container';
import usePhyhubDeviceFilters from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-filters';
import usePhyhubDeviceSortOptions from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-search-and-sort/hooks/use-phyhub-device-sort-options';
import useEnvironments from '../../../common/use-environments/use-environments';
import { useSpaces } from '../../../common/use-spaces';
import useInstallations from '../../../common/use-installation';
import { PhyhubDevice } from '../../../../services/phyhub/types/phyhub-device.interface';
import usePhyhubDevicesDefaultMassActions from '../../../common/phyhub-devices/hooks/use-phyhub-devices-default-mass-actions';
import PhyhubDevicesOverviewContainer from '../../../common/phyhub-devices/phyhub-devices-list/phyhub-devices-summary/phyhub-devices-overview.container';
import Header from '../../../common/app-layout/header/header.component';
import usePhyhubDevicesSearchAndFilters from '../../../common/phyhub-devices/hooks/use-phyhub-devices-search-and-filters';
import usePhyhubDevicesDefaultTableColumns from '../../../common/phyhub-devices/hooks/use-phyhub-devices-default-table-columns';
import usePhyhubDevicesDefaultDeviceActions from '../../../common/phyhub-devices/hooks/use-phyhub-devices-default-device-actions';
import PhyhubDevicesWelcomeAlert from '../../../common/phyhub-devices/phyhub-devices-welcome-alert/phyhub-devices-welcome-alert.component';

interface OrganisationPhyhubDevicesContainerProps
  extends RouteComponentProps<{ organisationId: string }> {}

const OrganisationPhyhubDevicesContainer: FC<OrganisationPhyhubDevicesContainerProps> = (
  props,
) => {
  const {
    match: {
      // TODO: Rename organisationId to organizationId to match the API
      params: { organisationId: tenantId },
    },
  } = props;

  const { t } = useTranslation();

  const { data: environmentsCollection = [] } = useEnvironments(tenantId);

  const { data: installationsCollection = [] } = useInstallations(tenantId);

  const { data: spacesResponse } = useSpaces({ organisationId: tenantId });

  const { filterOptions } = usePhyhubDeviceFilters({
    tenantId,
    environments: environmentsCollection,
    installations: installationsCollection,
    spaces: spacesResponse ? spacesResponse.spaces : [],
  });

  const { sortCriteriaOptions } = usePhyhubDeviceSortOptions();

  const {
    searchPhrase,
    handleSetSearchPhrase,
    filters,
    handleFiltersChange,
  } = usePhyhubDevicesSearchAndFilters();

  const [selectedDevices, setSelectedDevices] = useState<PhyhubDevice[]>([]);

  const handleSetSelectedDevices = useCallback((devices: PhyhubDevice[]) => {
    setSelectedDevices(devices);
  }, []);

  const handleResetSelectedDevices = useCallback(() => {
    setSelectedDevices([]);
  }, []);

  const { defaultDeviceActionItems } = usePhyhubDevicesDefaultDeviceActions({ tenantId });

  const {
    isMassActionsLoading,
    defaultDeviceMassActionItems,
  } = usePhyhubDevicesDefaultMassActions({
    tenantId,
    onResetSelectedDevices: handleResetSelectedDevices,
  });

  const {
    defaultTableColumns,
    getTableRowSelection,
  } = usePhyhubDevicesDefaultTableColumns({
    deviceActionItems: defaultDeviceActionItems,
    massActions: {
      selectedDevices,
      deviceMassActionItems: defaultDeviceMassActionItems,
      onSetSelectedDevices: handleSetSelectedDevices,
    },
  });

  return (
    <>
      <PhyhubDevicesWelcomeAlert
        legacyDevicesPath={`/organisations/${tenantId}/operations/legacy-devices`}
      />
      <PhyhubDevicesContainer
        isTableLoading={isMassActionsLoading}
        searchPhrase={searchPhrase}
        tenantId={tenantId}
        filterOptions={filterOptions}
        filters={filters}
        getTableRowSelection={getTableRowSelection}
        header={<Header title={t('devices')} />}
        overview={
          <PhyhubDevicesOverviewContainer
            searchPhrase={searchPhrase}
            tenantId={tenantId}
            filters={filters}
          />
        }
        sortCriteriaOptions={sortCriteriaOptions}
        tableColumns={defaultTableColumns}
        onSearch={handleSetSearchPhrase}
        onFiltersChange={handleFiltersChange}
      />
    </>
  );
};

export default OrganisationPhyhubDevicesContainer;

import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Empty } from 'antd';
import { GridStyles } from '@ombori/grid-reports';
import { processGridStyles } from './utils';
import Masonry from 'react-masonry-css';

const Container = styled.div`
  position: relative;
  min-height: 130px;
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  background: #f5f5f5;

  .masonry-grid {
    display: flex;
    margin-left: -20px;
  }
  .masonry-column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style individual children */
  .masonry-column > div {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

interface AnalyticsReportProps {
  cards: React.ReactNode[];
  columnsCount?: number;
  gridStyles?: GridStyles;
}

const AnalyticsReport: React.FC<AnalyticsReportProps> = ({ cards }) => {
  const breakpointColumns = {
    default: 2,
    940: 1,
  };

  return (
    <Container>
      <Header>
        <Empty description="No analytics data" css={{ gridColumn: '1/-1' }} />
      </Header>

      <Content>
        {cards.length > 1 ? (
          <Masonry
            breakpointCols={breakpointColumns}
            className="masonry-grid"
            columnClassName="masonry-column"
          >
            {cards.map((cards, index) => (
              <React.Fragment key={index}>{cards}</React.Fragment>
            ))}
          </Masonry>
        ) : (
          <div style={{ width: '100%' }}>
            {cards.map((cards, index) => (
              <React.Fragment key={index}>{cards}</React.Fragment>
            ))}
          </div>
        )}
      </Content>
    </Container>
  );
};

export default AnalyticsReport;

import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import UsersListContainer from './users-list/users-list.container';
import OrganisationsListContainer from './organisations-list/organisations-list.container';
import ActionLogsListContainer from './action-logs-list/action-logs-list.container';
import FederatedDomainListContainer from './federated-domain-list/federated-domain-list.container';
import AnalyticsContainer from './analytics/analytics.container';
import ProtectedRouteContainer from '../common/protected-route/protected-route.container';
import DeviceMonitoring from './device-monitoring/device-monitoring';
import WithAdminMenu from './with-admin-menu.component';
import GlobalConfiguration from './global-configuration/global-configuration.component';
import Billing from './billing/billing.component';

const Grid = (props: RouteComponentProps) => {
  const { match } = props;
  return (
    <WithAdminMenu {...props}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => <Redirect to={`${match.path}/organisations`} />}
        />
        <ProtectedRouteContainer
          path={`${match.path}/users`}
          component={UsersListContainer}
        />
        <ProtectedRouteContainer
          path={`${match.path}/organisations`}
          component={OrganisationsListContainer}
        />
        <ProtectedRouteContainer
          path={`${match.path}/action-logs`}
          component={ActionLogsListContainer}
        />
        <ProtectedRouteContainer
          path={`${match.path}/federated-domains`}
          component={FederatedDomainListContainer}
        />
        <ProtectedRouteContainer
          path={`${match.path}/analytics`}
          component={AnalyticsContainer}
        />
        <ProtectedRouteContainer
          path={`${match.path}/monitoring`}
          component={DeviceMonitoring}
        />
        <ProtectedRouteContainer
          path={`${match.path}/global-configuration`}
          component={GlobalConfiguration}
        />
        <ProtectedRouteContainer path={`${match.path}/billing`} component={Billing} />
      </Switch>
    </WithAdminMenu>
  );
};

export default Grid;

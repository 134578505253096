export enum PhyhubTwinsListParamsFiltersOperatorEnum {
  IN = 'in',
  NOT_IN = 'nin',
}

export interface PhyhubTwinsListParamsFilters {
  [key: string]: {
    op: PhyhubTwinsListParamsFiltersOperatorEnum;
    val: string[];
  };
}

export type PhyhubTwinsListParamsSort = Partial<
  Record<'displayName' | 'createdAt' | 'updatedAt', 'asc' | 'desc'>
>;

export interface PhyhubTwinsListParams {
  tenantId: string;
  limit?: number;
  page?: number;
  search?: string;
  filters?: PhyhubTwinsListParamsFilters;
  sort?: PhyhubTwinsListParamsSort;
}

enum UniversalDeviceType {
  IOTHUB = 'iothub',
  SCREEN = 'screen',
  UNIVERSAL = 'universal',
  LEGACY_BALENA = 'balena',
  ANDROID = 'android',
  ANDROID_UNMANAGED = 'android_unmanaged',
  TIZEN = 'tizen',
  WINDOWS = 'windows',
  MACOS = 'macos',
  BROWSER = 'browser',
  PHYHUB = 'phyhub',
}

export default UniversalDeviceType;

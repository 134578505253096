import React from 'react';
import {
  AnalyticsSchema,
  CardType,
  InteractionType,
  SessionInteractionType,
  WeekHeatmapDataSourceType,
} from '@ombori/grid-reports';
import ErrorBoundary from '../../../../common/error-boundary/error-boundary.component';
import DeviceReport from '../../../../common/overview-report/reports/device-report';
import { BlockContainer } from '../../../../common/phyhub-devices/phyhub-devices-common.components';
import styled from '@emotion/styled';

const PhyhubDeviceDetailsOverview = () => {
  const reportSchema: AnalyticsSchema = {
    groups: [
      {
        name: 'Overview',
        cards: [
          {
            type: CardType.Sessions,
            interactionType: SessionInteractionType.Interactive,
          },
          { type: CardType.Nps },
          { type: CardType.EventsList, interactionType: InteractionType.Interactive },
          {
            type: CardType.EventsFunnel,
            title: 'Sales conversions funnel',
            events: ['CATEGORY_VIEW', 'PRODUCT_VIEW', 'CART_ADD', 'CHECKOUT', 'PURCHASE'],
          },
          {
            type: CardType.ProductsEventCount,
            eventType: 'PRODUCT_VIEW',
            title: 'Product views',
          },
          { type: CardType.CategoriesEventCount },
        ],
      },
      {
        name: 'Events flow',
        cards: [{ type: CardType.EventsFlow }],
        columnsCount: 1,
      },
      {
        name: 'Sessions week heatmap',
        cards: [
          {
            type: CardType.WeekHeatmap,
            title: 'Sessions week heatmap',
            dataSource: {
              type: WeekHeatmapDataSourceType.Sessions,
              interactionType: SessionInteractionType.Interactive,
            },
          },
        ],
        columnsCount: 1,
      },
    ],
  };

  return (
    <Wrapper>
      <ErrorBoundary>
        <DeviceReport schema={reportSchema} />
      </ErrorBoundary>
    </Wrapper>
  );
};

const Wrapper = styled(BlockContainer)`
  margin: 16px 0 0;

  @media screen and (min-width: 992px) {
    margin: 30px 0 0;
  }
`;

export default PhyhubDeviceDetailsOverview;

import Gridapp from '../../store/types/gridapp';
import GridappTypeEnum from '../../store/types/gridapp-type.enum';

export const getDefaultAppIcon = (type: GridappTypeEnum) => {
  switch (type) {
    case GridappTypeEnum.SCREEN:
      return 'desktop';
    case GridappTypeEnum.MOBILE:
      return 'mobile';
    case GridappTypeEnum.IOT_MODULE:
      return 'hdd';
    case GridappTypeEnum.CLOUD:
      return 'cloud';
    default:
      return 'desktop';
  }
};

export const sortGridAppByType = (gridapps: Gridapp[]) => {
  const ungroupSortedApps = gridapps.sort((a, b) => a.displayName.localeCompare(b.displayName))

  const groups: Record<string, Gridapp[]> = {
    [GridappTypeEnum.CLOUD]: [] as Gridapp[],
    [GridappTypeEnum.IOT_MODULE]: [] as Gridapp[],
    [GridappTypeEnum.SCREEN]: [] as Gridapp[],
    [GridappTypeEnum.MOBILE]: [] as Gridapp[],
    'unknown': [] as Gridapp[],
  };

  ungroupSortedApps.forEach(app => {
    if (app.type === GridappTypeEnum.CLOUD || app.type === GridappTypeEnum.SCREEN || app.type === GridappTypeEnum.MOBILE || app.type === GridappTypeEnum.IOT_MODULE) {
      groups[app.type].push(app);
    } else {
      groups['unknown'].push(app);
    }
  });

  return [
    ...groups[GridappTypeEnum.CLOUD],
    ...groups[GridappTypeEnum.SCREEN],
    ...groups[GridappTypeEnum.MOBILE],
    ...groups[GridappTypeEnum.IOT_MODULE],
  ];
}

export default {
  getDefaultAppIcon,
};

import { ReactNode } from 'react';
import { parseAxiosError } from './is-axios-error.util';

/**
 * Retrieves an error message from the PhyHub API response or returns a fallback message.
 */
export const getPhyhubApiErrorMessage = (
  error: unknown,
  fallbackMessage: ReactNode,
): ReactNode => {
  const parsedError = parseAxiosError(error);

  if (parsedError && parsedError.response && parsedError.response.data.message) {
    return parsedError.response.data.message;
  }

  return fallbackMessage;
};

import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PhyhubDeviceBreakdownReport } from '../../../../../services/phyhub/types/phyhub-device-breakdown-report.interface';
import PhyhubDeviceStatusesBar, {
  ProgressSize,
} from '../../phyhub-device-statuses-bar/phyhub-device-statuses-bar.component';
import PhyhubDevicesOverviewTableContent from './phyhub-devices-overview-table-content/phyhub-devices-overview-table-content.component';
import PhyhubDevicesOverviewTableHeader from './phyhub-devices-overview-table-header/phyhub-devices-overview-table-header.component';

interface PhyhubDevicesOverviewProps {
  report: PhyhubDeviceBreakdownReport;
}

const PhyhubDevicesOverview: FC<PhyhubDevicesOverviewProps> = (props) => {
  const { report } = props;

  const [isDeviceListVisible, setDeviceListVisible] = useState<boolean>(false);

  const isToggleVisible = useMemo<boolean>(() => report.summary.total > 0, [
    report.summary.total,
  ]);

  const handleDeviceListToggle = useCallback(() => {
    setDeviceListVisible((prevIsDeviceListVisible) => !prevIsDeviceListVisible);
  }, []);

  return (
    <Wrapper>
      <Content>
        <PhyhubDevicesOverviewTableHeader
          isToggleOpen={isDeviceListVisible}
          isToggleVisible={isToggleVisible}
          summaryBreakdown={report.summary}
          onToggleClick={handleDeviceListToggle}
        />
        {isDeviceListVisible ? (
          <PhyhubDevicesOverviewTableContent typeStatusBreakdowns={report.byType} />
        ) : (
          <PhyhubDeviceStatusesBar
            size={ProgressSize.DEFAULT}
            statusesBreakdown={report.summary}
          />
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  overflow-x: auto;
`;

const Content = styled.div`
  padding: 10px 40px;
  min-width: 850px;
`;

export default PhyhubDevicesOverview;
